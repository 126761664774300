.profile{
    &-box{
        padding:16px;
        background-color: var(--text-white);
        min-height:100%;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;    
     }
     &-btn{
         vertical-align: middle;
         display:flex;
         justify-content: center;
         align-items: center;
         min-height:40px;
         border-radius:8px;
         padding:8px;
         border:none;
         outline:none;
         font-size: 2rem;
         color:var(--black);
         background-color: var(--gray-light);

         & > img{
            display:inline-block;
            height:24px;
            width:24px;
        } 
     }
     &-main-content{    
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     &-image{
        width: 12rem;
        height: 12rem;
        background-size:cover !important;
        background-position: center center !important;
        border:2px solid var(--gray-light);
        background: var(--text-white);
        border-radius: 50%;
        position: relative;
        overflow:hidden;
        &:hover &-cover{
        height:100%;
        visibility: visible;
        }
        &-cover{
            position:absolute;
            width:100%;
            height:0;
            transition:all .5s ease-out;
            bottom:0;
            background-color: rgba(0,0,0,.5);
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
        }
     }
  }

  &-upload-btn{
      display: inline-block;
      transition: all .8 ease-out;
      cursor: pointer;
      &:hover{
          transform: scale(1.5);
      }
  }

  &-content-box{
      position: relative;
      margin-top: 15px;
      background-color: var(--secondary-light);
      min-height: 100px;
      &:before{
          content:'';
          position:absolute;
          top:0;
          right:0;
          width:150px;
          height:3px;
          background: var(--primary);
      }
      &-control{
        margin-top: 10px;  
        width:100%;  
        display: flex;
        flex-flow: row wrap;
        gap: 15px;
        &-col {
        flex-grow: 1;
        }
    }
  }

  &-btn-group-inline{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap:15px;
    &>*{
     max-height: 40px;   
    }
  }
}
.bg-white-shadow{
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    padding: 16px !important;
}