//use in images to make them take the full space
.object-full {
  width: 100%;
  height: 100%;
}

.object-max-full {
  max-width: 100% !important;
  max-height: 100% !important;
}


.w {
  $percent : '%';

  @each $size in (10, 20, 30, 40, 50, 60, 70, 80, 90, 100) {
    &-#{$size} {
      width: unquote(($size) + $percent);
    }

    &-sm{

      &-#{$size} {
  
        @media (max-width: $mediaXS) {
  
          width: unquote(($size) + $percent);
  
        }
      }
  
    }


  }



}

.max-w {
  $percent : '%';

  @each $size in (10, 20, 30, 40, 50, 60, 70, 80, 90, 100) {
    &-#{$size} {
      max-width: unquote(($size) + $percent);
    }
  }
}

.h {
  $percent : '%';

  @each $size in (10, 20, 30, 40, 50, 60, 70, 80, 90, 100) {
    &-#{$size} {
      height: unquote(($size) + $percent);
    }
  }
}




.h-rem {
  $percent : 'rem';

  @each $size in (1, 2, 3, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100) {
    &-#{$size} {
      height: unquote(($size) + $percent);
    }
  }

}

.border-bottom-l-r {
  $percent : 'rem';

  @each $size in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
    &-#{$size} {
      border-bottom-left-radius: unquote(($size) + $percent) !important;
      border-bottom-right-radius: unquote(($size) + $percent) !important;
    }
  }

}

// change the color of a white icon into green
.filter {
  &-white {
    filter: brightness(0) invert(1);
  }

  &-primary {
    filter: invert(60%) sepia(76%) saturate(3207%) hue-rotate(122deg) brightness(125%) contrast(80%);

    &-hover:hover {
      filter: invert(60%) sepia(76%) saturate(3207%) hue-rotate(122deg) brightness(75%) contrast(80%);
    }
  }

  &-red {
    filter: brightness(0) saturate(100%) invert(32%) sepia(77%) saturate(5505%) hue-rotate(343deg) brightness(98%) contrast(86%);
  }

}

.box-shadow {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.10);

  &-large {
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
  }

  &-sm {
    box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.1);
  }
}


.border-radius {
  border-radius: 1rem;

  &-0 {
    border-radius: 0 !important;
  }

  &-sm {
    border-radius: .5rem;
  }

  &-full {
    border-radius: 50rem;
  }
}

.border {
  &-none {
    border: none !important;
  }

  &-primary {

    border: 0.5rem solid var(--primary);
  }
}

$boxSizes: (
  12: 1.3rem,
  14: 1.5rem,
  16: 1.7rem,
  20: 2.1rem,
  24: 2.5rem,
  26:2.7rem,
  30: 3.1rem,
  40:4.1rem,
  45:4.6rem
);

@each $header, $size in $boxSizes {
  .size-#{$header} {
    height: $size;
    width: $size;
  }
}


$spacing: 14.5rem;
$spacingXS: 3.5rem;

.container {
  margin-left: $spacing;
  margin-right: $spacing;

  &-p {
    padding-left: $spacing;
    padding-right: $spacing;
  }

  &-left {
    margin-left: $spacing;
  }

  &-right {
    margin-right: $spacing;
  }

  @media (max-width: $mediaXS) {

    margin-left: $spacingXS;
    margin-right: $spacingXS;

    &-p {
      padding-left: $spacingXS;
      padding-right: $spacingXS;
    }

    &-left {
      margin-left: $spacingXS;
    }

    &-right {
      margin-right: $spacingXS;
    }
  }
}

.width {
  &-1\/4 {
    width: calc(((100vw - (14.5rem * 2)) / 4) - 3rem);

    //width: 30rem;
    &-full {
      width: calc(((100vw - (14.5rem * 2)) / 4));

    }
  }
}


.grid- {
  @each $size, $gap in (6:1, 5:2, 4:3, 3:3, 2:2) {
    &#{$size}-cols {
      display: grid;
      gap: ($gap)+rem;
      grid-template-columns: repeat($size, 1fr);
    }
  }


  @media (max-width: $mediaXS) {
    @each $size, $gap in (6:3, 5:2, 4:2, 3:2, 2:1) {
      &#{$size}-cols {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat($gap, 1fr);
      }
    }

  }

  @each $size, $gap in (6:6, 5:5, 4:4, 3:3, 2:2, 1:1) {
    &#{$size}-cols-importent {
      display: grid;
      gap: 1.5rem;
      grid-template-columns: repeat($gap, 1fr);
    }
  }
}


.grid-column-full {

  grid-column: inherit;
}


.gap {
  @each $size in (1, 2, 3, 4, 5, 10, 20) {
    &-#{$size} {
      gap: ($size)+rem;
    }
  }
}

.link {
  &-btn {
    &-white {
      @apply inline-block box-shadow border-radius-full bg-white py-4 px-10 text-16;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        @apply box-shadow-sm;
        transform: scale(.97);
      }
    }
  }
}

.list {
  &-bullet {
    list-style: disc;

    &>li {
      @apply text-16 mr-6 text-black;

      &:not(:last-child) {
        margin-bottom: .8rem;
      }
    }
  }

  &-gray {
    &>li {
      opacity: .8;
    }
  }

  &-limit {

    // &-#{6} {
    //  & > li:nth-child(6+n){
    //     display: none !important;
    //   }
    // }
    &-#{6} {
      &>li:nth-child(6n) {
        display: none !important;
      }
    }
  }

}

.filter {
  &-black {
    filter: brightness(0);
  }

  &-white {
    filter: brightness(0) invert(1);
  }

  &-primary {
    filter: brightness(0) saturate(100%) invert(94%) sepia(80%) saturate(6193%) hue-rotate(59deg) brightness(94%) contrast(75%);
  }

  &-primary-dark {
    filter: brightness(0) saturate(100%) invert(21%) sepia(99%) saturate(2993%) hue-rotate(137deg) brightness(95%) contrast(102%);
  }
}

.col {
  &-count {
    &-3 {
      column-count: 3;
      column-gap: 3rem;
    }
  }

  @media (max-width: $mediaXS) {
    &-count {
      &-3 {
        column-count: 1;
      }
    }
  }
}

.overflow {
  &-x {
    &-unset {
      overflow-x: unset !important;
    }
  }
}

.rotate {
  &-180 {
    transform: rotateZ(180deg);
  }
}

.flex-list {
  @apply flex justify-between items-center w-full;

  &>* {
    @apply text-16;
  }
}

.border-v {

  position: relative;
  padding-inline-start: 1rem;

  &::before {

    content: "";
    position: absolute;
    width: 0.3rem;
    height: 105%;
    inset: 0;
    background: var(--primary);
  }
}


.box-sizing-flex-1 {
  box-sizing: border-box;
  flex: 1;
}

.grid-auto {

  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: start;
}

.padding-inline-start {
  @each $size in (1, 2, 3, 4, 5, 10, 20) {
    &-#{$size} {
      padding-inline-start: ($size)+rem !important;
    }
  }
}

.swiper-wrapper-grid-auto{

  & .swiper-wrapper {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: start;
    padding: 1rem;
    & .swiper-slide{
      box-sizing: border-box;
      flex: 1 1;
  
      &>*{

        height: 100%;
  
      }
}
   
  }

}
