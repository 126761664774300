﻿.order-car {
  &-container {
    @apply flex gap-3 items-center w-full justify-between;

    & .order-car-img {
      width: 75%;
    }

    & .order-specs {
      @apply flex flex-col gap-3;

      & > div {
        width: 11rem;
        height: 6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > .order-spec-img {
          width: $specSize;
          height: $specSize;
          margin-right: 1rem;

          & > img {
            height: 100%;
            width: 100%;
            margin-right: auto;
            filter: brightness(0);
          }
        }

      }
    }
  }
}



