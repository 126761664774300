.contact{
    position:fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    top:50%;
    right:-8rem;
    width:10rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height:25rem;
    border-left:4px solid var(--primary);
    background: var(--white);
    transition: all .5s ease-in-out;
    transform: translatey(-50%);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    &-us{
        user-select: none;
        animation: winkAnimate 1s ease-in-out 0s infinite alternate forwards;
    }

    @keyframes winkAnimate {
        from{
            transform: scale(1);
        }
        to{
            transform: scale(1.2);
        }
    }
    &-box{
        width:6rem;
        height:6rem;
        border-radius: 8px;
        display: flex;
        justify-content:center;
        cursor: pointer;
        align-items: center;
        &:hover img{
            transform: scale(1.3);
        }
        img{
            transition: all .5s ease-in-out;
            max-height: 3rem;
            max-width: 3rem;
        }
        &-whatsup{
            background-color: var(--primary);
        }
        &-call{
            background-color: var(--black);
        }
    }



    // &::after{
    //     content: "";
    //     position: absolute;
    //     width:2px;
    //     border-radius: 12px;
    //     height: 10rem;
    //     background: var(--primary);
    //     top: 50%;
    //     transform:translateY(-50%);
    //     left:8px;
    // }
}