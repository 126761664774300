.work-hours {
  &-flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & * {
      @apply text-20;
    }

    & li {
      display: flex;
      align-items: center;
      gap: 2rem;

      & .day {
        width: 8rem;
      }

      & > * {
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          width: 105%;
          height: .5rem;
          background: var(--secondary-light);
          border-radius: 2rem;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }

      &.active {
        & > * {
          color: var(--primary-dark);

          &:after {
            background: var(--primary);
          }
        }
      }
    }
  }
}