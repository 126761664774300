$iconSize: 8rem;
.icon-text {
  margin: 2rem 3rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-img {
    img {
      width: $iconSize;
      height: $iconSize;
    }
  }
}