.breadcrumb {
  margin-bottom: 4rem;

  & > a {
    @apply text-16 font-bold ;
    position: relative;
    text-transform: capitalize !important;
    display: inline-block;
    &:not(:first-child) {
      margin-right: 1rem;
    }

    // &:not(:last-child):after {
    //   position: absolute;
    //   content: '/';
    //   left: 0;
    //   top: 0;
    //   color: var(--primary);
    //   height: 100%;
    //   bottom: 0;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }



    &:hover {
      color: var(--primary-dark);
    }
  }
}


.language-ar .breadcrumb {

  & > a {
    padding-left: 1.5rem;
    margin-right: 1rem;
  &:not(:last-child)::after {
    position: absolute;
    content: '/';
    left: 0;
    top: 0;
    color: var(--primary);
    height: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

}


.language-en .breadcrumb {

  & > a {

    padding-right: 1.5rem;
    margin-left: 1rem;
  &:not(:last-child)::after {
    position: absolute;
    content: '/';
    right: 0;
    top: 0;
    color: var(--primary);
    height: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

}