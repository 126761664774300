.privacy{
    @apply relative;
    min-height: 100vh;
    &-belt{
        @apply absolute object-contain;
        top:-20rem;
        z-index: -1;
        @media (max-width:$mediaS) {
            display:none;
        }
        max-height:160rem;
        &-right{
            right:-40rem;
            transform: rotate(-60deg);
        }
        &-left{
            left:-40rem;
        }
    }

}