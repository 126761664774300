.vehicle-image-box{
    max-width: 100%;
   // width:100%;
}

.vehicle-details-box{
    background-color: var(--text-white);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    &-brands{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     &-title{
         font-size: 3.1rem;
         user-select: none;
     }
    }
}

.vehicle-brand{
    min-width: 60px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50rem;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    &-primary{
        background-color: var(--text-white);
    }
    &-hybrid{
        background-color: var(--info);
        color:var(--text-white);
    }
}


.color-box{
    height:25px;
    width:25px;
    border-radius: 5px;
    background-size: cover;
}

.feature-car-box{
    min-height:80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-details{
    display: flex;
    flex-direction: column;
    gap:3px;
    }
}

@media (max-width: $mediaS) {
    .nice-show{
        display: none;
    }
}

.private-vehicle{
    &-tab-box{
        display: flex;
        align-items: center;
        min-height:60px;
        width:100%;
        border-radius: 10px;
        background-color: var(--text-white);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
     }

     &-tabs-container{
         list-style: none;
         padding: 0;
         margin:0;
         display: flex;
         flex-direction: row;
         gap:3rem;
         & > li:hover {
             cursor: pointer;
         }
     }
     &-details{
        background-color: var(--text-white);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
        border-radius: 10px;
        min-height: 300px;
        width:100%
     }
     &-history{
       min-width: 100%;
       min-height:10rem;
       border-color: var(--primary);
       &-box{
         background-color: var(--white);
         border-radius: 1rem;
         width: 100%;
         min-height:15rem;
         position: relative;
         box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
         &-r{
           &:after{
             top:0;
             content:'';
             position:absolute;
             z-index: -1;
             border-radius: 50%;
             height:1.5rem;
             width: 1.5rem;
             background-color: var(--primary);
             right:-2.1rem;
           }
         }
         &-l{
           &:after{
             top:0;
             content:'';
             position:absolute;
             z-index: -1;
             border-radius: 50%;
             height:1.5rem;
             width: 1.5rem;
             background-color: var(--primary);
             left:-2.1rem;
           }
         }
         &-header{
           display: flex;
           flex-direction: row;
           gap: 1rem;
           &-icon-box{
             height:5rem;
             width:5rem;
             background-color: var(--page);
             display: flex;
             justify-content: center;
             align-items: center;
             @apply border-radius-full;
           }
         }
       }
    }
}

.tab-active{
    color:var(--primary);
}


