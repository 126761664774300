.specs-tile {
  @apply bg-white border-radius overflow-hidden box-shadow;
  //height: 21rem;

  padding: 1rem;

  &-bg {
    @apply border-radius;
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;
    cursor: pointer;

    .specs-tile-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;

    }
  }

   .specs-tile-content:hover &-img  img{
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-webkit-perspective: 1000;
    -moz-webkit-perspective: 1000;
    -o-webkit-perspective: 1000;
    -ms-webkit-perspective: 1000;
    perspective: 1000;
    cursor:pointer;
}
  &-img {
    height: 100%;
    width: 25%;
    order: 1;
    position: relative;

    & img {
      max-height: 17rem;
      

        transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);
    
      
    }
  }

  &-offers {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--toyota);
    color: white;
    padding: .5rem 1.3rem;
    border-radius: 100rem;
    font-weight: bold;
  }

  &-info {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;

    &-name {
      width: 50%;
      a{
        width: fit-content;
      }
    }

    &-spec {
      display: flex;
      margin-right: auto;
      gap: 2rem;

      &>div {

        height: 6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--white);
        gap: .2rem;

        &>.spec-img {
          width: $specSize;
          height: $specSize;
          margin-right: 1rem;

          &>img {
            height: 100%;
            width: 100%;
            margin-right: auto;
          }
        }

      }
    }
  }

  & .transition {
    transition: all .3s;
  }

  @media (max-width: $mediaXS) {
    &-bg {
      flex-direction: column;
    }

    &-img {
      width: 100%;
    }

    &-offers {
      font-size: 1.9rem;
    }

    &-info {
      padding: 0;
      margin-top: 4rem;

      width: 90%;

      &-name {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}