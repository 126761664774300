$sliderHeight: 100vh;

$carWidth: 100rem;
$carHeight: 35rem;

$infoTextWidth: 100%;

$specSize: 4.7rem;


.LandengButton button{
  position: relative;
overflow: hidden;
display: inline-block;
padding: 12px 36px;
font-size: 15px;
line-height: 1.25;
font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
font-weight: 500;
letter-spacing: 0.03em;
text-transform: uppercase;
white-space: nowrap;
text-overflow: ellipsis;
text-align: center;
cursor: pointer;
vertical-align: middle;
user-select: none;
transition: 250ms all ease-in-out;
border: none;
border-left: 5px solid var(--primary);


color: #ffffff;
background-color: #323232;
border-color: var(--primary);

&:hover, &:active {
color: #ffffff;
background-color: var(--primary);
border-color: #444141;
border-left: 5px solid #444141;

}



}

.ScrollToTopButton {
  position: fixed;
  right: 5rem;
  bottom: 12%;
  z-index: 100;
  width: 7rem;
  height: 7rem;
  font-size: 20px;
  border-radius: 1rem;
  color: #ffffff;
  background: var(--primary);
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transition: .45s all ease-in-out;
  transform: translate3d(0, 30rem, 0);

    font-family: 'FontAwesome';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  //   &:before {
  //     content: "\f106";
  //     font-family: 'FontAwesome';
  //     font-size: inherit;
  //     text-rendering: auto;
  //     -webkit-font-smoothing: antialiased;
  //     -moz-osx-font-smoothing: grayscale;
  // }
    &.active{

      transform: translate3d(0, 0, 0);
    }

    @media (max-width: $mediaS) {
      width: 9rem;
      height: 9rem;
    }
}

.headerLandeng {
  &-container {

    .swiper-button-prev,
    .swiper-button-next {
      top: 1.5rem !important;
      border: .315rem solid !important;
      color: var(--white) !important;
      width: 5rem;
      height: 5rem;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 2.5rem;
      font-weight: bold;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      &:after {
        content: 'next' !important;
      }

      left: auto !important;
      right: 4rem !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      &:after {
        content: 'prev' !important;
      }

      left: auto !important;
      right: 10rem !important;
    }

    &>.swiper-pagination-bullets {
      transform: rotateZ(90deg);
      right: 2rem;
      width: unset !important;
      left: unset !important;
      display: inline-block;
      bottom: 25rem !important;

      &>.swiper-pagination-bullet {
        background: var(--white) !important;
        opacity: .7 !important;

        &-active {
          background: var(--primary) !important;
          opacity: 1 !important;
        }
      }
    }

  }

  &-car {
    &-container {
      position: relative;
      height: $sliderHeight;
      overflow: hidden;

    }
  }

  &-overlay {
    position: absolute;
    inset: 0;
    height: 100%;
    // background: linear-gradient(to right, #090d0b 0%, rgba(26, 28, 27, 0.76) 51.45%, rgba(42, 42, 42, 0.13) 100%);
    z-index: 1;
  }

  &-bg {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &-info {
    display: flex;
    align-items: center;
    position: relative;

    &-img {
      position: relative;
      z-index: 2;


      &>img {
        width: $carWidth;
        height: $carHeight;

        object-fit: contain;
      }
    }

    &-extra{

      display: flex;
    padding: 25px 5px;
    height: 100%;
    max-width: 10rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 1;

    &-social-links {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &-social-links a{

      line-height: 44px;
    width: 6rem;
    height: 6rem;
    background-color: rgba(255, 255, 255, 0.05);
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 2rem;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    transition: all 0.3s ease-in-out;

    &:hover,&:focus {
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
  }
    }
    }

    &-desc {

      display: flex;
      flex-wrap: nowrap;
      width: 50%;
      padding-bottom: 10rem;
      justify-content: space-between;
    }

    &-text {
      position: relative;
      color: white;
      z-index: 2;
      width: $infoTextWidth;

      & span {
        color: white;
      }

      h2 {
        font-size: 7rem;
        font-weight: bold;
        color: white;
      }

      button{
        position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 12px 36px;
    font-size: 15px;
    line-height: 1.25;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    transition: 250ms all ease-in-out;
    border: none;
    border-left: 5px solid var(--primary);


    color: #ffffff;
    background-color: #323232;
    border-color: var(--primary);

    &:hover, &:active {
      color: #ffffff;
      background-color: var(--primary);
      border-color: #444141;
      border-left: 5px solid #444141;

  }

      }

      &-price {


        & .price {
          color: var(--primary);
          margin-right: 1.5rem;
        }

        & .old-price {
          color: var(--danger);
          text-decoration: line-through;
        }
      }

      &-links {
        gap: 2rem;
        position: absolute;
        margin-top: 1rem;

        & span {
          color: white;
        }
      }
    }

    &-spec {
      z-index: 2;

      & span {
        color: white;
      }

      &>div {
        width: 11rem;
        height: 6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: white;

        &>.spec-img {
          width: $specSize;
          height: $specSize;
          margin-right: 1rem;

          &>img {
            height: 100%;
            width: 100%;
            margin-right: auto;
          }
        }

      }
    }

    &-tag {
      @apply border-radius-full px-3 py-1;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      height: 5rem;
      width: 14rem;
      background: var(--hybrid);
    }
  }


  // new car secondary
  &-car-secondary {
    &-container {
      // height: 46rem;
    }

    .swiper-wrapper {
      align-items: flex-end !important;
    }


    .swiper-slide-active {
      position: relative;
      transition: all .5s;

      .car-img {
        transform: scale(1.4);
        // margin-bottom: 3rem;

      }

      &:after {
        content: "";
        position: absolute;
        left: -1.5rem;
        top: 0;
        animation: moveUp 1s ease forwards;

        width: .1rem;
        background: var(--gray);
      }

      &:before {
        content: "";
        position: absolute;
        right: -1.5rem;
        bottom: 0;
        animation: moveUp 1s ease forwards;
        width: .1rem;
        background: var(--gray);
      }

      .car-secondary-info {
        &-desc {
          @apply text-line-clamp text-line-clamp-2;
          max-height: 6.5rem;
        }

        &-options {
          opacity: 2;
        }
      }
    }
  }

  //car overview slider
  &-car-360 {
    overflow: unset !important;

    .swiper-button-prev,
    .swiper-button-next {
      @apply bg-white border-radius-full box-shadow;
      top: 16rem !important;
      border: .315rem solid !important;
      color: var(--white) !important;

      width: 6rem;
      height: 6rem;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 2.5rem;
      font-weight: bold;
      content: "" !important;
      background-image: url(360Arrow.svg) !important;
      width: 3rem;
      height: 3rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      &:after {}

      left: auto !important;
      right: -8rem !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      &:after {
        transform: rotateZ(180deg);
      }

      right: auto !important;
      left: -8rem !important;
    }

  }

  @media (max-width: $mediaXS) {

    &-offer-date {
      display: none;
    }

    &-info {


      &-spec {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 13rem;
        left: 3rem;
        margin: 0 !important;
        gap: 2rem;
        flex-direction: row-reverse;

        &>div {
          margin: 0 !important;
          // justify-content: center;
          width: unset;

          &>*>* {
            font-size: 1.8rem;
          }

          &>.spec-img {
            width: 4rem;
            height: 4rem;
          }
        }
      }

      &-text {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & h2 {
          @apply text-30;
        }

        & .text-section {
          order: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }

        &-price {
          display: grid;
          grid-template-columns: .6fr 1fr;
          margin-bottom: 2rem;

          & span:last-child {
            grid-column-start: 2;
          }

          &>* {
            font-size: 2.6rem !important;
          }
        }

        &-btn {

          order: 3;
          margin-top: 1rem;
        }

        &-links {
          order: 2;
          // flex-direction: column;
          // width: 35%;
          // margin-right: auto;
          // gap: .8rem;
          // margin-bottom: 4rem;
          position: relative;

          & .btn {
            min-height: unset;
            padding: 1rem 2rem;
          }

          // &>*:last-child {
          //   display: none;
          // }
        }
      }

      &-desc {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom:5rem ;

      }
      &-img {
        width: 50%;
        height: 90%;

        &>img {
          width: 100%;
          height: 100%;
        }
      }


      &-tag {
        top: unset;
        right: 2rem;
        bottom: 1rem;
      }
    }

    &-bg,
    &-overlay {
      @apply border-radius overflow-hidden;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      right: auto;
      width: 100%;
    }

    &-car-container {
      margin: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      height: 55rem !important;
      padding: 0rem;

      & .btn-primary {
        // display: none;
      }
    }

    &-car-secondary {
      &-container {
        height: 66rem;
      }

      .car-img {
        transform: scale(1.1);
        margin-bottom: 3rem;
      }

      .swiper-slide {
        padding: 0 3rem;
      }

      .swiper-slide-active {
        position: relative;
        transition: all .5s;

        .car-img {
          transform: scale(1.1);
        }

        &:after {
          left: 1.5rem;
        }

        &:before {
          right: 1.5rem;
        }

        .car-secondary-info {

          &-desc,
          &-options {
            max-height: unset;
          }

        }
      }

      .car-secondary-info {

        &-desc,
        &-options {
          display: block !important;
          max-height: unset !important;
        }
      }
    }


  }
}

@keyframes moveUp {
  from {
    height: 1%;

  }

  to {
    height: 70%;
  }

}

@keyframes moveDown {
  from {
    height: 70%;

  }

  to {
    height: 1%;
  }

}


@media (max-width: $mediaXS) {
  .main-slider {
    & .swiper-pagination {
      display: none !important;
    }
  }

}

.language-ar  .headerLandeng-info-extra{
  border-right: none;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.slide-landeng-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.slide-landeng-container > .swiper-pagination-bullets {
  width: unset !important;
  left: unset !important;
  display: inline-block;
  bottom: 5rem !important;
  left: 50%;
  right: 50%;
}

.slide-landeng-container .swiper-button-prev, .slide-landeng-container .swiper-button-next {
  top: 50% !important;
  border: 0.315rem solid !important;
  color: var(--white) !important;
  width: 6rem;
  height: 6rem;
  
}

.slide-landeng-container .swiper-button-prev {

  left:4rem !important;
  right: auto !important;

}

.slide-landeng-container .swiper-button-next  {


  left: auto !important;
    right: 4rem !important;
}

.slide-landeng-container  .swiper-button-next:after {
  content: "next" !important;
}

.slide-landeng-container .swiper-button-prev:after {
  content: "prev" !important;
}
