.dark {
  .dark {

    &-filter {
      &-white {
        filter: brightness(0) invert(.95) !important;
      }

      &-gray {
        filter: brightness(0) invert(.85) !important;

      }
    }
  }
}