.input {
  position: relative;

  // margin-bottom: 15px;
  &-label {
    @apply text-16 font-bold mb-3 inline-block;


    &.mb-0 {

      margin-bottom: 0 !important;
    }

    &.m-0 {

      margin: 0 !important;
    }
  }


  &-input {
    width: 100%;
    position: relative;

  }

  &-field {
    @apply bg-white border-radius flex justify-between items-center;
    font-size: 2rem;
    width: 100%;
    padding: .8rem 1.2rem;
    position: relative;
    border: .2rem solid var(--input-border);
    min-height: 6rem;

    &-text {
      @apply text-20 text-line-clamp text-line-clamp-1;
    }

    &-arrow {
      padding: 0 .8rem;
      width: 3rem;
      height: 1rem;

      &.invert {
        transform: rotateZ(180deg);
      }
    }
  }

  &-options {
    @apply box-shadow border-radius bg-white;
    position: absolute;
    width: 100%;
    right: 0;
    top: 6rem;
    padding: 1rem 0;
    max-height: 32rem;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;

    li {
      padding: .8rem 1rem;
      cursor: pointer;
      transition: all .3s;

      & span {
        @apply text-16 text-line-clamp text-line-clamp-1;
      }

      &:hover {
        background: var(--page);
      }
    }
  }

  // radio input
  &-radio {
    @apply text-16;
    display: inline-flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: .8rem 1.2rem;


  }


  //input types
  &-secondary {

    & .input {


      &-field {
        @apply bg-page;
      }
    }
  }

  @media (max-width: $mediaXS) {


    input {

      height: 7rem;
      font-size: 3rem;
    }

    &-field {
      padding: 1rem 2rem;
    }
  }
}

.inputs-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

  &>* {
    width: 100%;
  }
}

input,
textarea,
select,
button {
  outline: none;

  &:focus {
    box-shadow: 0 0 0 .1rem var(--primary-dark);
  }
}

.custom-input-file {


  display: flex;
  align-items: center;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  // background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 1rem;
  background: var(--white);
  width: 100%;
  font-size: 3rem;
  // padding: 0.8rem 1.2rem;
  // min-height: 8rem;
  position: relative;
  border: 0.2rem solid var(--input-border);

  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    z-index: 1;
  }

  input[type="date"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    z-index: 1;
  }

  input[type="time"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    z-index: 1;
  }

  input[type="text"] {
    border: none;
    // font-size: 3rem;
  }

  &-content {

    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 1rem;

    img {
      width: 4rem;

    }

  }

}

.input-radio {
  & span {
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 1.7rem;


    @media (max-width: 576px) {
      font-size: 2.5rem;
    }
  }

}

input[type=radio] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

input[type=radio]:checked,
input[type=radio]:not(:checked) {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  min-width: 3rem !important;
  max-height: 3rem !important;
  min-height: 3rem !important;
  max-width: 3rem !important;
  border-radius: 100%;


}


input[type=radio]:checked:before {
  content: '';
  position: absolute;
  width: 100%;
  ;
  height: 100%;
  ;
  border: 0.6rem solid #878787;
  border-radius: 100%;
  background: #3BD461
}


input[type=radio]:not(:checked):before {
  content: '';
  position: absolute;
  width: 100%;
  ;
  height: 100%;
  ;
  border: 0.4rem solid #878787;
  border-radius: 100%;
  background: rgb(255 255 255);

}

input[type=radio] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

input[type=radio]#primary {
  accent-color: #3BD461;
}

input[type=radio]:checked:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.6rem solid #878787;
  border-radius: 100%;
  background: #3BD461;
}


.custom-checkbox {
  display: none;
  /* Hide the default checkbox */
}

.custom-checkbox:checked+.checkbox-label::before {
  background-color: var(--primary);
  /* Change the checkbox color when checked */
}


input[type=radio]#primary {
  accent-color: #3BD461;

}

input[type=checkbox].text-primary {
  accent-color: #3BD461;

}

input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  cursor: pointer;
  height: auto;
  font-size: 3rem;

  @media (max-width: 576px) {

    height: auto;
    font-size: 3rem;
  }
}

input[type=checkbox]:focus {
  box-shadow: none;
}

input[type=checkbox]:before {
  font-family: "FontAwesome";
  content: "\f00c";
  font-size: 3rem;
  text-align: center;
  display: flex;
  color: transparent !important;
  background: rgba(255, 255, 255, 0);
  width: 18px;
  height: 18px;
  border: 0.1rem solid #b5b5b5;
  border-radius: 2px;
  align-content: center;
  align-items: center;
  justify-content: center;
}

input[type=checkbox]:checked:before {

  color: #3BD461 !important;
  border: 0.1rem solid #3BD461 !important;
}

input[type=number] {
  -moz-appearance: textfield; // Firefox
  appearance: textfield; // Other browsers

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; // Chrome, Safari, Edge, Opera
    margin: 0;
  }
}

.Textarea-module {

  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #fff;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

/* Label acting as the placeholder */
.Textarea-module__label {
  position: absolute;

  transition: transform 0.3s, font-size 0.3s;
  pointer-events: none;
  /* Prevent clicking the label */
  --height: 22px;
  top:16px;
  font-size: 16px;
  line-height: var(--height);
  color: #6c7a8d;
  // transition: all .2s;
  inset-inline-start: 3rem;

}

/* On focus or when input has value */
.Textarea-module:focus+.Textarea-module__label,
.Textarea-module:not(:placeholder-shown)+.Textarea-module__label,
.Textarea-module+.Textarea-module__label.input-has-placeholder {
  transform: translate(8px, -100%);
  font-size: 14px;
  top: 11px;
  inset-inline-start: 5rem;
  color: #484848;
  background-color: white;
  padding: 0rem 2px;

  font-weight: bold;
}

.Input-module {

  height: 58px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #fff;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @media (max-width: 576px) {
    & {
      height: 50px !important;
      font-size: 16px !important;
    }
  }
}

/* Label acting as the placeholder */
.Input-module__label {
  position: absolute;

  transition: transform 0.3s, font-size 0.3s;
  pointer-events: none;
  /* Prevent clicking the label */
  --height: 22px;
  top:calc(50% - var(--height) / 2);
  font-size: 16px;
  line-height: var(--height);
  color: #6c7a8d;
  // transition: all .2s;
  inset-inline-start: 3rem;

}

/* On focus or when input has value */
.Input-module:focus+.Input-module__label,
.Input-module:not(:placeholder-shown)+.Input-module__label,
.Input-module+.Input-module__label.input-has-placeholder {
  transform: translate(8px, -100%);
  font-size: 14px;
  top: 11px;
  inset-inline-start: 5rem;
  color: #484848;
  background-color: inherit;
  padding: 0rem 2px;
  border-radius: 1rem;
  font-weight: bold;
}

.Radio-Input-module {

  .input-label {
    color: #484848;
    display: block;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
  }

  .radio-list-container {


    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      min-width: 123px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #d6dfe9;
      background: #fff;
      box-sizing: border-box;
      cursor: pointer;
      color: #6c7a8d;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    & input[type=radio]:checked+span {
      color: var(--primary);
      border-color: var(--primary);
      font-weight: 700;
    }

    & input[type=radio] {
      display: none;
    }
  }

}

.Input-upload-module {
  height: 20rem;
  padding: 1rem;
  box-shadow: none !important;
  border: 1px solid #d6dfe9;
  border-radius: 3px;
  box-shadow: 8px 10px 15px 0 rgba(0, 0, 0, 0.2);
  background: #fff;

  .custom-input-file-content {

    display: none;
  }

  & input[type=file] {

    width: 100%;
    height: 100%;
  }

  .custom-input-file-imgView {
    width: 100%;
    display: flex !important;
    height: 100%;
    position: relative;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    // justify-content: space-between;

    &-remove {
      position: absolute;
      width: 5rem;
      background-color: #696969;
      height: 4rem;
      inset-inline-start: 1px;
      inset-block-start: 1px;
      cursor: pointer;
      z-index: 999;
      border-radius: 4px;
      padding: 2px;

      img {
        width: 100%;
        height: 90%;
      }
    }

    .image-add {
      width: 50%;
      position: relative;
    }

    & .image-container {

      width: 100%;
      height: 90%;
    }

    & .fallback-text {
      display: flex !important;
      flex-direction: column;
      width: 100%;
      align-items: center;

      span {

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        width: 100%;
      }
    }

    & .image-container[style*="display: none"]+.fallback-text {
      display: flex !important;
    }

    .Input-file-module__label {
      transition: transform 0.3s, font-size 0.3s;
      pointer-events: none;
      --height: 30px;
    }
    .Input-file-module__label.input-has-data {
      position: absolute;
      transition: transform 0.3s, font-size 0.3s;
      pointer-events: none;
      --height: 25px;
      top: calc(50% - var(--height) / 2);
      font-size: 16px;
      line-height: var(--height);
      color: #6c7a8d;
      inset-inline-start: 3rem;
      transform: translate(8px, -181%);
      font-size: 11px;
      inset-inline-start: 8rem;
      color: #484848;
      background-color: white;
      padding: 0rem 2px;
      font-weight: bold;
    }

  }


}