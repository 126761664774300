.nice-float {
  &-right {
    float: right !important;
  }

  &-left {
    float: left !important;
  }

  &::after {
    clear: both;
  }
}
.nice-flex-important {
  display: flex !important;

}
.nice-flex {
  display: flex;

  &-wrap-content {
    flex-flow: row wrap;
  }

  &-direction {
    flex-flow: row wrap;
  }

  &-fill-width {
    width: 300px;
  }

  &-space {
    justify-content: space-between;
  }

  @media (max-width: $mediaXS) {
    &-direction {
      flex-flow: column wrap;
    }
  }

  &-fill-width {
    width: 100%;
  }
}

.flexiable-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  min-height: 5rem;
  min-width: 15rem;
  border-radius: 1rem;
  cursor: pointer;
  text-align: center;

  img {
    max-height: 2.3rem;
    max-width: 2.3rem;
  }
}

.nice-card-box {
  width: 300px;
}

.text-bold {
  font-weight: bold;
}

.text-white {
  color: var(--white);
}

.border-section {
  &-right {
    border-right: 3px solid var(--primary);
  }

  &-left {
    border-left: 3px solid var(--primary);
  }
}

.bg {
  &-light {
    background-color: var(--secondary-lighter);
  }
}

.text-left {
  text-align: left;
  direction: ltr;
}

.text-right {
  text-align: right;
  direction: rtl;
}

.loading-btn {
  display: inline-block;
  animation: 1s infinite normal rotate;
}

.text-sub-color {
  color: var(--gray-dark);
}

.text-size-18r {
  font-size: 3.1rem;
}

.text-size-16r {
  font-size: 2rem;
}

.text-size-14r {
  font-size: 1.5rem;
}

.text-size-12r {
  font-size: 1.2rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nice-flex-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.nice-flex-center-items {
  align-items: center !important;
  justify-content: center;
  gap: 8px;
}

.user-profile-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  &-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: normal;
}

.vertical-separater {
  display: inline-block;
  min-height: 100%;
  width: 0.5px;
  background-color: var(--super-gray);
}

.hidden-scroll-horizontally {
  overflow-x: scroll;
  max-width: 100%;
}

.hidden-scroll-horizontally::-webkit-scrollbar {
  display: none;
}

.mb-14 {
  margin-bottom: 14px;
}

.flex-item-center {
  flex-grow: 1;
  text-align: center;
}

.language {
  &-ar {
    text-align: right;
    direction: rtl;

    &-pos {
      right: 0 !important;
    }

    &-pos-dir {
      left: 0 !important;
    }
  }

  &-ar-t {
    text-align: right;
  }
  &-ar-d {
    direction: rtl;
  }
  &-en {
    text-align: left;
    direction: ltr;

    &-pos {
      left: 0 !important;
    }

    &-pos-dir {
      right: 0 !important;
    }
  }

  &-en-t {
    text-align: left;
  }

  &-en-d {
    direction: ltr;
  }
}


.overflow-hidden {
  overflow: hidden;

}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.tooltipBox {
  position: relative;

  &-text {
    display: none;
    position: absolute;
    width: 50px;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    visibility: visible;
    border-radius: 6px;
    padding: 5px;
    z-index: 1;
    bottom: -1px;
    right: -55px;
  }
}

.countDown {
  display: flex;
  flex-direction: row;
  gap: 3px;

  &-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2;
    text-transform: capitalize;
    color: var(--white);
  }
}

.sign-in {
  &-tabs {
    // margin: -15px -15px 0 -15px;
    // border-bottom: 1px solid var(--gray-light);
  }
}

.nice-edit-btn {
  cursor: pointer;
  user-select: none;
  background-color: var(--white);
  border-radius: 25%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.nice-pointer {
  cursor: pointer;
  user-select: none;
}

.nice-flex-column {
  flex-direction: column;
}

.nice-flex-row {
  flex-direction: row;
}
.nice-flex-row-reverse {
  flex-direction: row-reverse;
}

.box-circle {
  border-radius: 30px;
}

.nice-flex-center {
  justify-content: center;
  align-items: center;
}

.nice-vertical-border {
  width: 2px;
  height: 6rem;
  background-color: var(--gray-dark);
}

.nice-border-primary-right {
  border-right: 0.6rem solid var(--primary);
}

.nice-border-primary-left {
  border-left: 0.6rem solid var(--primary);
}

.nice-border-tabby {
  border: 0.3rem solid #3affbb;
}

.nice-border-tamara {
  border: 0.3rem solid #fbc679;
}
.nice-border-checkout-primary {
  border: 1px solid  var(--primary);
  border-radius: 8px;
}

.nice-border-checkout-gray {
  border: 1px solid  #d6dfe9;
  border-radius: 8px;
}

.nice-align-item-center {
  align-items: center;
}

.nice-align-item-start {
  align-items: flex-start;
}
.nice-border-top-section{

  border-top: 2rem #f7f7fa solid;
}
.nice-border-bottom-section{

  border-bottom: 2rem #f7f7fa solid;
}


.nice-justifiy-end {
  justify-content: flex-end;
}

.nice-flex-space-between {
  justify-content: space-between;
}

.nice-flex-space-center {
  justify-content: space-between;
  align-items: center;
}

.gap-3r {
  gap: 3rem;
}

.nice-flex-evenly {
  justify-content: space-evenly;
}

.nice-flex-v-center {
  justify-content: center;
}

.nice-max-icon {
  max-height: 2.5rem;
  max-width: 2.5rem;
}

.nice-line-through {
  text-decoration: line-through;
}

.nice-line-underline {
  text-decoration: underline;
}

.nice-width-init {
  width: initial !important;
}

.icon-btn>img {
  max-height: 3.5rem;
  max-width: 3.5rem;
}

.nice-relative {
  position: relative;
}

.nice-absolute {
  position: absolute;
}
.nice-sticky {
  position: sticky;
  z-index: 9999;
  top: 0px;
}
.nice-fixed {
  position: fixed;
  z-index: 9999;
}
.box-strength {
  transition: all 0.3s ease-in;
  height: 1.3rem;
  width: 6rem;
  border-radius: 10rem;
  background-color: var(--secondary-light);
}

.nice {

  &-shadow{
 
    -webkit-appearance: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px !important;
    
    &-0{
      -webkit-appearance: none !important;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px  !important;
        // box-shadow: 0 0px 0px 0px  rgba(228, 233, 233, 0.966), 0 0px 0px 0px rgba(228, 233, 233, 0.966), 4px 0 1px -4px rgba(0, 0, 0, 0.8), -6px 0 1px -4px rgba(228, 233, 233, 0.966);
     }
  
     &-1{
      -webkit-appearance: none !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px  !important;
        // box-shadow: 0 0px 0px 0px  rgba(228, 233, 233, 0.966), 0 0px 0px 0px rgba(228, 233, 233, 0.966), 4px 0 1px -4px rgba(0, 0, 0, 0.8), -6px 0 1px -4px rgba(228, 233, 233, 0.966);
     }
    &-2{
      -webkit-appearance: none !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px  !important;
        // box-shadow: 0 0px 0px 0px  rgba(228, 233, 233, 0.966), 0 0px 0px 0px rgba(228, 233, 233, 0.966), 4px 0 1px -4px rgba(0, 0, 0, 0.8), -6px 0 1px -4px rgba(228, 233, 233, 0.966);
     }
     
     &-3{
      -webkit-appearance: none !important;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px  !important;
     }
    
  
     &-4{
      -webkit-appearance: none !important;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px  !important;
     }
  
     &-5{
      -webkit-appearance: none !important;
      box-shadow:rgba(0, 0, 0, 0.3) 0px 0px 3px  !important;
     }
     &-6{
      -webkit-appearance: none !important;
      // box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px  !important;
        box-shadow: 0 0px 0px 0px  rgba(228, 233, 233, 0.966), 0 0px 0px 0px rgba(228, 233, 233, 0.966), 4px 0 1px -4px  rgba(0, 0, 0, 0.8 ), -6px 0 1px -4px rgba(249, 250, 248, 0.966);
     }
    
  
   }
   
  &-sm-hidden {
    @media (max-width:$mediaXS) {
      display: none !important;
    }
  }

  &-notsm-hidden {
    @media (min-width:$mediaXS) {
      display: none !important;
    }
  }


  &-space {
    &-normal {
      white-space: normal;
    }

    &-nowrap {
      white-space: nowrap;
    }
  }

  &-flex {
    @for $var from 1 to 5 {
      &-#{$var} {
        flex: $var;
      }
    }
  }

  &-line {
    width: 5rem;
    height: .5rem;
    border-radius: 5rem;
    background-color: var(--primary);
  }

  &-style-btn {
    border-radius: 1rem;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 576px){

    height: 7rem;
     }

    img {
      max-width: 3rem;
      max-height: 3rem;
      object-fit: contain;
    }
  }

  &-bg-btn {
    border-radius: 1rem;
    width: 100%;
    height: 5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: pre;
    & img{
      max-height: 3rem;
      max-width: 3rem;
    }

    @media (max-width: 576px) {
      height: 7rem;
      
      }
    
  }

  &-sm-btn {
    border-radius: 1rem;
    width: 100%;
    height: 4rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & img{
      max-height: 3rem;
      max-width: 3rem;
    }

    @media (max-width: 576px) {
      height: 5rem;
      
      }
    
  }

  &-bg-btn-border {
    border-radius: 10rem;
    width: 12rem;
    height: 5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;
  }

  &-justifiy {
    text-align: justify;
  }

  $sizes: 1rem, 2rem, 3rem, 4rem, 5rem, 6rem, 7rem, 8rem, 9rem, 10rem;

  @each $item in $sizes {
    &-line-height-#{$item} {
      line-height: $item;
    }
  }

  &-img-responsive {
    max-height: 100%;
    max-width: 100%;
  }

  @each $item in $sizes {
    &-gap-#{$item} {
      gap: $item;
    }
  }

  &-select-none {
    user-select: none;
  }

  &-table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  $sizes2: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;
  $percent : '%';

  @each $item in $sizes2 {
    &-dem-#{$item} {
      width: unquote(($item) + $percent);
      height: unquote(($item) + $percent);
    }
  }

  &-width {
    $sizes2: 10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100;
    $percent : '%';

    @each $item in $sizes2 {
      &-size-#{$item} {
        width: unquote(($item) + $percent);
      }
    }
  }

  &-ruler {
    width: 100%;
    height: 0.1rem;

    &-white {
      background-color: var(--white);
    }
  }
}

.ads-banner {
  position: relative;
  width: 100%;
  height: 50rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    border-radius: 1rem;
  }
}

.basis {
  &-50 {
    flex-basis: 48%;
  }
}


.max-icon{
  @apply object-contain;
  max-width: 4.5rem;
  max-height: 4.5rem;

}
@media (max-width: 576px) {
  .ads-banner {
    height: 30rem;



    }
  
  }

  .line-bottom:after {
    content: "";
    position: absolute;
    width: 7rem;
    height: 3px;
    margin: auto;
    background: var(--primary);
    top: -21px;
    position: static;
    display: block;
    margin-bottom: 1rem;
}
.line-top:before {
  content: "";
  position: absolute;
  width: 7rem;
  height: 3px;
  margin: auto;
  background: var(--primary);
  top: -21px;
  position: static;
  display: block;
  margin-bottom: 1rem;
}

//
.bg-back{
  &-primary{
    background-color: var(--primary);
  }
}

.border-colors{
  &-primary{
    border-color: var(--primary) !important;
  }

  &-error{
    border: 1px solid var(--error)  !important;;
  }
}

