.epart {
  &-box{
    position: relative;
    overflow: hidden;
    &-date {
      @apply text-20 py-3 px-20 bg-toyota text-white border-radius box-shadow-sm;
      position: absolute;
      top: 0rem;
      left: -2.5rem;
      z-index: 2;
      & *{
        @apply text-20 text-white ;

      }
    }
  }
  &-campaign {
    overflow: hidden;
    @apply border-radius ;
    position: relative;
    width:100%;
    height: 40rem;
    width: 60rem;
    &-size{
      width: 80%;
      margin: auto;
      padding: 5rem;
    }
    &-type {
      @apply text-16 py-3 px-20 bg-toyota text-white border-radius box-shadow-sm;
      position: absolute;
      top:1.5rem;
      right: -10rem;
      z-index: 2;
      transform: rotate(45deg);
      overflow:hidden;
      min-width: 28rem;
      text-align: center;
      text-transform: capitalize;
      &::after{
        content: '';
        position: absolute;
        width:20px;
        height:20px;
        border-radius: 50%;
        top:33%;
        background-color: rgba(255,255,255,.8);
        opacity: .5;
        box-shadow: 8px 8px 10px 14px rgba(255,255,255,.7), -8px -8px 10px 14px rgba(255,255,255,.7);
        animation: snow-move 3s linear 0s infinite normal forwards;
        @keyframes snow-move {
          from{
            transform: scale(1.3px);
            transform: rotate(0deg);
            right:300px;
          }
          to{
            right: 0px;
            transform: rotate(180deg);
            transform: scale(1px);
          }
        }
      }
    }




    &-image {
     max-height: 50rem;
     transform: scale(1.2);
    }
    &-text {
      position: absolute;
      top: 50%;
      display: flex;
      flex-direction: column;
      vertical-align: middle;
      &-r{
        right:-10rem;
      }
      &-l{
        left:-18rem;
      }
      transform: translate(-15rem,-6rem);
      z-index: 2;
      //padding-left: 6rem;

      & > * {
        color: white;
      }

      &-title {
        @apply mb-10;
        position: relative;
        display: inline-block;
        z-index: 1;
        border-radius: 8px;
        text-transform: capitalize;
        & h2 {
          @apply text-30 font-bold text-line-clamp text-line-clamp-1;
          color: white;
        }

      }

      &-desc {
        @apply text-20 text-line-clamp text-line-clamp-3;
        text-transform: capitalize;
        // padding-right: 6rem;
      }
    }

    &-link {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      z-index: 2;
    }
    
    
    &-grid-3{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-overlay {
    position: absolute;
    inset: 0;
    height: 100%;
    background: linear-gradient(to left, #090d0b 0%, rgba(26, 28, 27, 0.76) 51.45%, rgba(42, 42, 42, 0.13) 100%);
    z-index: 1;
  }
  
  &-btns{
    position: absolute;
    bottom:0;
  }

  @media (max-width: $mediaXS) {
    &-campaign {
      // height: 57rem;
      // width: 100%;
      margin: auto;

      &-type, &-date {
        font-size: 2.3rem;
      }

      &-text {
        &-title h2 {
          font-size: 2.5rem;
        }

        &-desc {
          @apply text-line-clamp-2;
          font-size: 2.1rem;
        }
      }

      & .btn {
        min-height: unset;
        padding: 1rem 2rem;
        font-size: 2rem;
      }
    }
  }

}

.horizontal-slider{
  //width: 100%;
  //max-width: 500px;
  height: 50px;
  //border:  1px solid;
  //margin: 100px;
}

.horizontal-slider .example-track {
  top:  20px;
  height: 10px;
}

.example-track.example-track-0 {
  background: black;
}

.example-track.example-track-1 {
  background: #22ca4e;
}

.example-track.example-track-2 {
  background: rgba(34, 202, 78, 0.25);
}

.horizontal-slider .example-thumb{
  top: 32px;
  //width: 20px;
  //height: 20px;
  line-height: initial;
  font-size: 11px;
  text-align: center;
  //background-color: #22ca4e;
  //color: black;
  font-weight: bold;
  //cursor: pointer;
  //border: .5px solid gray;
  //box-sizing: inherit;
  //border-radius: 4px;


}