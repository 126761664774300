.user {
  &-notification {
    max-height: 90%;
    position: relative;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
  }
    &-line {
      position: absolute;
      top: 0;
      width: .5rem;
      border-radius: 1000rem;
      height: 100%;
      background: var(--primary);
      &-right{
        right: .5rem;
      }
      &-left{
        left: .5rem;
      }
    }
  }
}

.notification {
  &-card {
    @apply border-radius box-shadow bg-white mb-8 ;
    border-top-right-radius: 0;
    display: block;
    padding: 2rem;
    margin: 0  2rem 1.5rem 2rem;
    position: relative;
    &-right{
      &:before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100rem;
        background: var(--primary);
        position: absolute;
        top: 0;
        right: -1.9rem;
      }
    }

    &-left{
      &:before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100rem;
        background: var(--primary);
        position: absolute;
        top: 0;
        left: -1.9rem;
      }
    }


    .head {
      display: flex;
      gap: 1.4rem;
      align-items: flex-start;

      .icon {
        @apply border-radius-full;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
        width: 5rem;
        background: var(--page);


        img {
          height: 4rem;
          width: 4rem;
        }

        &.primary {
          img {
            @apply filter-primary
          }
        }

        &.error {
          img {
            @apply filter-red;
          }
        }
      }

      .text {
        width: 100%;
      }
    }

    .title {
      @apply text-20 font-bold;
    }

    .date {
      @apply text-16 mb-4;
      opacity: .8;
    }

    .message {
      @apply text-16;
    }

  }
}