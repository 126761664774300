
.news {
  &-card {
    height: 48rem;
    overflow: hidden;
    background: var(--white);

    &-img {
      height: 51%;
      overflow: hidden;
      position: relative;


      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .tag {
        position: absolute;
        z-index: 1;
        right: 1rem;
        top: 1rem;
        padding: .4rem 1.2rem;
        @apply border-radius-full box-shadow-sm text-12;

        &-toyota {
          background: var(--toyota);
          color: white;
        }

        &-lexus {
          background: var(--lexus);
          color: white;
        }
      }
    }

    &-text {
      height: 49%;
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem 1.5rem;

      a:hover {
        color: var(--primary-dark);
      }
    }
  }

  &-detail {
    width: 97%;
    @apply bg-white box-shadow p-10 border-radius;
    &-img {
      width: 50%;
      float: left;
      margin-left: -7.4rem;
      margin-right: 3rem;
      margin-bottom: 3rem;
      display: inline-block;
      overflow: hidden;
      @apply box-shadow border-radius;

    }

    &-text {

    }
  }


  @media (max-width: $mediaXS) {
    &-gap {
      gap: 2rem !important;
    }
    &-card {
      padding: 1.5rem;
      height: unset;

      &-img {
        height: 22rem;
      }

      &-text {
        height: unset;
        padding-bottom: 0;

        & h3 {
          margin-bottom: 0;
        }

        & div {
          display: none;
        }
      }
    }

    &-detail {
      width: 100%;
      margin: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;

      &-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &-img {
        float: none;
        width: 100%;
        border-radius: 0;
        z-index: 0;
        top: 0;
        margin: 0;
      }

      &-text {
        @apply bg-white box-shadow p-10 border-radius;
        margin: 3rem;
        margin-top: 5rem;
      }
    }
  }
}

.banner {
  /// new banner (news banner)
  &-news {
    &-info {
      position: relative;
      z-index: 1;
      height: 100%;
      width: 34%;
      color: white;
      background: rgba(0, 0, 0, .8);
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 7rem 8rem;

      &-flex {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        & * {
          color: white;
        }

        &-link {
          margin-top: auto;
          margin-right: auto;

          a {
            opacity: .7;

            &:hover {
              color: var(--primary);
              opacity: 1;
            }
          }
        }

      }


    }

  }

  @media (max-width: $mediaXS) {
    &-news {
      height: 40rem;

      &-info {
        display: none;
      }
    }
  }

}


.slide {

  //new slider for news
  &-news {
    & .swiper-pagination-bullets {

      left: 2rem !important;
      right: unset !important;
      bottom: 25rem !important;
    }


    @media (max-width: $mediaXS) {
      & .swiper-pagination-bullets {

        left: 0 !important;
        bottom: 2rem !important;
        transform: rotateZ(0deg);
        width: 100% !important;
      }

    }
  }


}