.evaluate{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-box{
        background-color: var(--white);
        border-radius: 1rem;
        width:50rem;
        min-height:35rem;
        &-header{
            background-color: var(--black);
            position: relative;
            border-radius: 1rem;
            height:6rem;
            width:100%;
        }
    }
}