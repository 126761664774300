.advise {
  &-tile {
    @apply border-radius;
    background: var(--white);
    overflow: hidden;

    &-name {
      @apply flex items-center justify-between border-radius py-8 px-10 ;
      background: var(--gray-light);
      cursor: pointer;
    }

    &-info {
      background: var(--white);
      overflow: hidden;
      transition: all .3s;
    }
  }

}

.reskined-top-bar-hj {
  height: 9px;
  background-color: var(--primary);
  width: calc(100% - 155px);
}