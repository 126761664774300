.nice-services {
    position: fixed;
    bottom: 12%;
    left: 2%;
    z-index: 99;

    &-box {
        border-radius: 5rem;
        height: 8rem;
        transition: all 0.5s ease 0s;
        width: 8rem;
        background-color: var(--primary);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

        &>div {

            display: none;
        }

    

        &:hover>div {

            display: block;
        }

        &:hover .nice-services-icon {
            transform: scale(1.2);
        }

        @media (max-width: $mediaS) {
            width: 9rem;
            height: 9rem;
          }
    }


    &-icon {
        max-height: 5rem;
        max-width: 5rem;
        transition: all 1s ease-out;
    }

    &-popup {
        transition: all .5s ease-out;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        @apply border-radius;
        z-index: 101;
        background-color: var(--white);
        position: absolute;
        // height: 20rem;
        width: 30rem;
        left: 58%;
        transform: translateX(-50%);

        &-header {
            height: 8rem;
            width: 100%;
            background-color: black;
            border-radius: 1rem;
            img {
                max-height: 100%;
                max-width: 100%;
                // margin-bottom:2rem ;
            }
        }
    }

    &-logo {
        background-size: cover;
        height: 10rem;
        width: 10rem;
        border-radius: 50rem;
        background-origin: content-box;
    }
}