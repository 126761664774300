.finance {
  position: relative;
  overflow: hidden;
  &-show-box {
    min-height: 25px;
  }
  &-details-box {
    position: absolute;
    background-color: var(--text-white);
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  }
  &-details-box::before {
    content: "";
    position: absolute;
    width: 10px;
    background: var(--text-white);
    height: 10px;
    bottom: -4px;
    right: 10px;
    transform: rotate(45deg);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);
  }
  &-show-btn {
    cursor: pointer;
    user-select: none;
  }
  &-color-box {
    display: flex;
    flex-flow: row wrap;
    gap: 2px;
    &-color {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: blue;
      border: 1px solid rgba(0, 0, 0, 0.2);
      transition: all 0.5s;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
  &-apply-btn {
    width: 10rem;
    height: 4rem;
    border-radius: 1rem;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  &-popup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      position: relative;
      width: 65%;
      height: auto;
      max-height:90%;
      background-color: var(--white);
      border-radius: 1rem;
      @media (max-width: $mediaXS) {
        width: 90%;
      }
      &-calculator {
        overflow-y: hidden;
        border-radius: 1rem;
        background-color: var(--gray-lighter);
       max-height: 100%;
        width: 35%;
        @media (max-width: $mediaXS) {
            display: none;
           }
        &-header{
            max-height:24rem;
            position: relative;
            img{
                max-width: 100%;
                max-height:100%;
            }
        }
      }
      &-form {
        border-radius: 1rem;
        max-height: 100%;
        overflow-y: auto;
        flex-grow: 1;
      }
    }
  }
  &-car-img{
    height: 25rem;
    overflow: hidden;
    position: relative;
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    &-bg {
        position: absolute;
        z-index: 0;
        opacity: .1;
        height: 105%;
        width: 105%;
        top: 2rem;
        left: -45%;
    }
  }
}

