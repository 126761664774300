.gallery {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;

  &-item{

  
    @apply border-radius overflow-hidden w-full h-full;
    height: 33rem;
  display: flex;
  position: relative;
    grid-column: span 3;
    grid-row: span 4;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3)
    {
      grid-column: span 4;
    }

    &:nth-child(4),
    &:nth-child(5)
    {
      grid-column: span 6;
    }

    & img {
      @apply border-radius overflow-hidden w-full h-full;
      height: 33rem;
      object-fit: cover;
  
      grid-column: span 3;
      grid-row: span 4;
  
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3)
      {
        grid-column: span 4;
      }
  
      &:nth-child(4),
      &:nth-child(5)
      {
        grid-column: span 6;
      }
  
    }
  
    &:hover .gallery-item-ovrlay{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: rgba(0,0,0,.51);
      z-index: 1;
      top: 0;
      padding: 10px;
      text-align: right;
      cursor: pointer;
      display: block;

      & svg{


        width: 5rem;
        height: 5rem;

        & path {
          color: white;
        }
      }
  
    }
  
    &-ovrlay{
  
      display: none;  
   }
  
  
  }



  @media (max-width: $mediaXS) {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    &-item {
      height: 33rem;

      grid-column: span 1;
      grid-row: span 2;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4)
      {
        grid-column: span 1;
      }

      &:nth-child(5n)
      {
        grid-column: span 2;
      }

    }

  }


}


// :not(:root):fullscreen::backdrop {
//   position: fixed;
//   inset: 0px;
//   background: #e6e6e6  !important;
// }
// .image-gallery-content.fullscreen{

//   background: var(--gray-light) !important;
// }
.image-gallery-fullscreen-button .image-gallery-svg path, .image-gallery-play-button .image-gallery-svg path, .image-gallery-play-button .image-gallery-svg path  {

color: white !important;
}

.image-gallery-fullscreen-button .image-gallery-svg polygon, .image-gallery-play-button .image-gallery-svg polygon{


  color: white !important;  

}

 .image-gallery-right-nav polyline, .image-gallery-left-nav polyline{


  color: white !important;  

}