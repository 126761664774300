.feature-tile {
  @apply bg-white border-radius overflow-hidden box-shadow;
  height: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  &-img {
    height: 100%;
    @apply border-radius overflow-hidden box-shadow-sm;
    width: 30%;
    order: 1;

  }

  &-text {
    width: 70%;
    order: 2;
  }

  &:nth-child(2n) {
    .feature-tile-text {
      order: 1;
    }

    .feature-tile-img {
      order: 2;
    }
  }

  @media (max-width: $mediaXS) {
    margin-top: 17rem;
    overflow: unset;
    flex-direction: column;
    height: unset;
    position: relative;
    &-img {
      @apply box-shadow;
      width: 95%;
      position: absolute;
      top: -10rem;
      object-fit: cover;
      height: 45rem;

    }
    &-text {
      margin-top: 35rem;
      width: 100%;
      text-align: center;
    }
  }
}
