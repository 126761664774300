

.hamburger {
  display: none;
  padding-left: 2rem;
  padding-right: 2rem;

  width: 4rem;
  height: 4rem;

  position: relative;

  transform: rotate(0deg);

  transition: .5s ease-in-out;
  cursor: pointer;



  & > div {
    border-radius: 1rem;

    background: var(--white);

    display: block;
    position: absolute;
    height: .3rem;
    width: 100%;

    opacity: 1;
    left: 0;

    transform: rotate(0deg);

    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 1.5rem;
    }

    &:nth-child(4) {
      top: 3.2rem;
    }
  }
  
  &.black > div {
    background: var(--black);
  }

  &.open {
    & > div {
      &:nth-child(1) {
        top: 1.5rem;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 1.5rem;
        width: 0%;
        left: 50%;
      }
    }
  }

  @media (max-width: $mediaXS) {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 3;


  }

}
