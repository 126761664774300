.color {
  &-picker-car {
    @apply  border-radius-sm box-shadow;
    transition: all .3s;
    width: 7rem;
    height: 7rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    &-image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      transform: scale(2);
    }

    &-text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }

    &:hover {
      @apply box-shadow-sm ;
      transform: scale(1.1);
    }

    &.active {
      border-color: var(--primary);
    }

    & .active {
      background: var(--primary);
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
  }
}
