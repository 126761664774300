.popup{  
position:fixed;
top:0;
bottom: 0;
right:0;
width: 100%;
height:100vh;
background: rgba(0,0,0,.7);
display: flex;
z-index: 9999;
align-items: center;
justify-content: center;

&-model{
    z-index: 1;
    position: relative;
    width: 80rem;
    background-color: #fff;
    border-radius: 3rem;
    overflow: hidden;
    box-shadow: 0 -2px 2px rgba(0,0,0,.5);
    animation: animate 1s;
    min-height: 33rem;
    max-height: 90%;
    padding: 2rem 2rem 5rem 2rem;
    @media (max-width: $mediaS) {
        width: 75rem;
      }

      @media (max-width: $mediaM) {
        width: 75rem;
      }

      @media (max-width: $mediaN) {
        width: 75rem;
      }


      & button {
        background-color: black !important;
        color: #3bd461;
        width: max-content;
        border-radius: 2rem;
    }

    & .input{

        background: none;
    }
    & .input-field {
        height: 6rem;
        border-radius: 2rem;
    }

    & .dropdown-field{

        height: 6rem;
        border-radius: 2rem;
    }
    & .phone-selectable{

        height: 6rem;
        border-radius: 2rem;
        display: flex;
    align-content: center;
    overflow: hidden;
    @media (max-width: 576px){
        & input {
            height: 5rem;
        }

    }
   

    }

    & .forget-password-content{


        position: relative;

        & .forget-password-link{

            position: absolute;
            bottom: 0.5rem;
            left: 2rem;
        }

        
        &.language-en .forget-password-link{
            left: auto;
            right: 2rem;
        }

        

    }
}



&-content{
    // padding: 2rem 2rem 5rem 2rem;
    overflow-y: auto;
    width:100%;
position: relative;
  
}

&-header{
height:100%;
width:40%;

background-color:#000;
position: relative;
display: flex;
align-items: center;
justify-content: center;
display: none;
&-profile{
    padding-top: 20px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

&-close{

    position: absolute;
    border-radius: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    &-icon{

    color: black;
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    & span {
    width: 9px;
    height: 8px;
    text-align: center;
    line-height: 6px;
    color: #000;
    }
}
}

@keyframes animate {
    from{
        transform: scale(.5);
    }

    to{
        transform: scale(1);
    }
}
}

.ruler{
    height: 1px;
    width: 90%;
    background-color:#ccc;
}

.ruler-2{
    height: 1px;
    width: 100%;
    background-color:#d8d8d8; 
}

.text-size-16{
    font-size: 16px;
}

.text-size-18{
    font-size: 18px;
}
.text-size-14{
    font-size: 14px;
}
.text-size-12{
    font-size: 12px;
}

.text-size-10{
    font-size: 10px;
}

.text-center{
    text-align: center;
}

.text-block{
    display: block;
}

.text-inline-block{
    display:inline-block;
}

.nice-btn{
    position: relative;
    border-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 5px 5px 20px rgba(0,0,0,.1);
    min-height:40px;
    width: 100%;
    border-radius: 8px;
    line-height: 40px;

    &-white{
        background: #fff;
        color:#B1B1B1;
    }

    &-primary{
    background: #1877F2;
    color:#fff;
    }
}

.absolute{
    position: absolute;
  &-right{
      right:10px;
  }  
  &-left{
      left:10px;
  }

  &-bottom{
     bottom:0;  
  }

  &-top{
      top:0;
  }
}