.car-feature {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &-wallpaper {
    width: 100%;
    height: 40rem;
    overflow: hidden;
    border-bottom: 0.2rem solid var(--amazing-gray);
    img {
      min-width: 100%;
      min-height: 100%;
      text-align: center;
      transition: all 0.5s linear;
      &:hover {
        transform: scale(1.1);
      }
    }
  }



&:nth-child(2) {
  .car-feature-wallpaper {
    order: 2;
  }

  .car-feature-details {
    order: 1;
  }
}

  &-details {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;
    &-r {
      &::after {
        content: "";
        position: absolute;
        width: 5rem;
        height: 0.4rem;
        background-color: var(--primary);
        right: 2rem;
        top: 3rem;
      }
    }
    &-l {
      &::after {
        content: "";
        position: absolute;
        width: 5rem;
        height: 0.4rem;
        background-color: var(--primary);
        left: 2rem;
        top: 3rem;
      }
    }


    p {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify
    }
  }
}
