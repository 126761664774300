.footer {
  background: var(--footer);

  &-heading {
    position: relative;

    &-box {
      width: 5rem;
      height: 5rem;
      border-radius: .5rem;
      border: .6rem solid var(--primary);
      position: absolute;
    }

    h3 {

      font-weight: bold;
      background: var(--footer);
      position: relative;
      bottom: -1.7rem;
      right: 2.5rem;
      padding-right: 1rem;
      color: var(--text-white);
      @apply text-24 ;
      
    }

    
    h3.en{

      left: 2.5rem;
      
    }
  }


  &-top {

    display: grid;
    grid-template-columns: 0.8fr 0fr 0.7fr 0.8fr;
    gap: 3rem;


    &-imgs {
      // max-width: 28rem;
      max-width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      .footer-logo {
        @apply border-radius;
        // background: black;
        overflow: hidden;
        // height: 8rem;
        margin-bottom: 1rem;

        & > img {
          margin: auto;
        }
      }

      &-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @apply justify-between items-center;
        gap: 2rem;

        & > img {
          width:10rem
        }
      }
    }

    &-links {
      display: flex;
      gap: 5rem;
      margin: auto 1rem;

      .bold a {
        font-weight: bold;
        text-transform: capitalize;
      }

      li:not(:last-child) {
        margin-bottom: .9rem;
      }

      a {
        @apply text-16;
        transition: all .3s;
        color: var(--text-white);
        text-transform: capitalize;
        &:hover {
          color: var(--primary-dark);
        }

      }
    }

    &-social {
      &-links {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;

        .social-box {
          // background: var(--primary);
          border-radius: .5rem;
          transition: all .3s;
          @apply flex justify-center items-center size-45 p-2;

          &:hover {
            background: var(--primary-dark);
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

    }

    &-newsletter {
      form {
        height: 5rem;
        position: relative;

        input {
          height: 100%;
          width: 100%;

          border: .2rem solid var(--primary);
          padding-inline-end: 6rem;
          padding-inline-start: 1rem;
        }

        button {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 5.8rem;
          transition: all .3s;
          margin: 0;
          @apply p-4;

          &:hover {
            background: var(--primary-dark);
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

      }
    }
  }

  &-bottom {
    background: black;

    & a {
      color: var(--text-white);
      transition: all .3s;

      &:not(.no-hover):hover {
        color: var(--primary);
      }
    }
  }


  @media (max-width: $mediaXS) {

    &-top {
      grid-template-columns: 1fr;
padding-bottom: 10rem;
      &-imgs {
        max-width: 100%;
        align-items: center;
        // position: absolute;
        display: flex;
        justify-content: center;
        left: 0;
        & a {
          // height: 10rem;
          width: 60%
        }
        & .footer-logo {
          // height: 10rem;
        }

        &-links {
          // width: 60%;

           & img{
            width: 8rem;
           }
        }
      }

      &-links {
        & a {
          @apply text-24;
         
        }
      }

      &-social {
        &-links {
          display: inline-flex;

          & .social-box {
            height: 6rem;
            width: 6rem;
          }
        }
      }

      &-newsletter {
        & form {
          height: 7.3rem;

          & button {
            padding: 0 2rem;
            width: 9rem;
            min-height: 100%;
          }
        }
      }
    }
  }
}

.language-en .footer-top-newsletter form button{

  left: auto;
  right: 0;
}