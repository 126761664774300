@font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon/icomoon.eot?yx67jz');
    src:url('../fonts/icomoon/icomoon.eot?yx67jz#iefix') format('embedded-opentype'),
      url('../fonts/icomoon/icomoon.ttf?yx67jz') format('truetype'),
      url('../fonts/icomoon/icomoon.woff?yx67jz') format('woff'),
      url('../fonts/icomoon/icomoon.svg?yx67jz#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-icomoon"], [class*=" icon-icomoon"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    // font-family: unset !important;
    // speak: never;
    // font-style: normal;
   
    // font-variant: normal;
    // text-transform: none;
    // line-height: unset;
    // /* Better Font Rendering =========== */
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // font-size: unset;
    // font-weight: unset;
    // color: unset;
    // margin:0 3px;
    // display: inline-flex;
    // float: left;
    // transform: scale(0.8);
    all: inherit;
    width: fit-content;
  }
  
  .icon-icomoon-Saudi_Riyal_Symbol-2:before {
    content: "\e900";
    font-size: inherit;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    margin:0 3px;
    display: inline-flex;
    float: left;
    margin:0 3px;
    transform: scale(0.7);
  }

  body:has(.icon-icomoon-Saudi_Riyal_Symbol-2) {
    /* Apply styles to the body or parent element that contains the icon */
  direction: ltr;
  }