.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & > li {
    @apply text-20 box-shadow border-radius bg-white;
    & a {

      display: flex;
      justify-content: center;
      align-items: center;
      @apply w-16 h-16;
    }

    &.disabled {
      box-shadow: none;
      cursor: none;
      background: var(--gray-light);
    }

    &.active {
      @apply bg-primary text-white;
    }
  }
}