.offer {
  &-card {
    height: 48rem;
    overflow: hidden;
    background: var(--white);

    &-img {
      height: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        //transform: scale(.8);
        object-fit: cover;
        object-position: center;
      }
    }

    &-text {
      height: 49%;
      display: flex;
      flex-direction: column;

      a:hover {
        color: var(--primary-dark);
      }

      &-p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        line-height: 3.5rem;
      }
    }

    &-counter {
      background-color: var(--black);
      min-height: 5rem;

      @media (max-width:$mediaXS) {
        &-text {
          display: none;
        }
      }
    }
  }

  &-package-date {
    display: flex;

    * {
      @apply text-20 text-gray pr-4;
    }
  }

  //detail
  &-detail {
    @apply bg-white border-radius box-shadow;
    position: relative;

    padding: 0rem;
    margin-top: 1rem;

    &.offer-normal {
      padding: 4rem;
    }

    &-name {
      position: absolute;
      @apply bg-white border-radius box-shadow flex justify-center items-center;
      top: -2rem;
      right: 0;
      padding-left: 2rem;
      cursor: pointer;

      &.active {

        border: #3bd461 0.4rem solid;

        &:after {


          // content: "*";
          // position: absolute;
          // width: 0.5rem;
          // height: 100%;
          // // background: var(--primary);
          // top: 0rem;
          // // left: 0rem;
          // /* position: static; */
          // display: flex;
          // font-size: 3rem;
          // /* margin-bottom: 1rem; */
          // align-items: center;
          // justify-content: center;

        }
      }

      & h3 {
        @apply text-20 font-bold text-white bg-primary py-6 px-6 border-radius;
      }


      & h4 {
        border-left: .2rem solid var(--gray);
      }

      &-info {
        display: flex;
      }

    }

    &-info {
      &-img {
        @apply border-radius overflow-hidden mr-6;
        float: left;
        width: 25%;
        max-height: 42rem;
      }

      &-details {

        width: 70%;

      }

      &-R {

        width: 50%;
        padding-inline-end: 10rem;

        @media (max-width: 576px) {
          width: 100%;
        }
      }

      &-L {
        width: 50%;

        @media (max-width: 576px) {
          width: 100%;
        }

      }
    }

    &-data {
      margin-bottom: 3rem;

      & h3 {
        @apply text-20 font-bold mb-4;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 1rem;
          inset-inline-start: -2rem;
          border-radius: 100rem;
          height: 1rem;
          width: 1rem;
          background: var(--primary);
        }
      }

      & ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0rem 2rem;

        & li {
          @apply text-16;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 1rem;
            inset-inline-start: -2rem;
            border-radius: 100rem;
            height: 1rem;
            width: 1rem;
            background: var(--primary);
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }

      & p {
        @apply text-16;

      }
    }
  }

  //media query
  @media (max-width: $mediaXS) {
    &-card {
      height: 43rem;

      &-img {
        height: 65%;
      }

      &-text {
        height: unset;

        & p.text-line-clamp {
          display: none;

        }

        & h3 {
          font-size: 2.3rem;
          margin-bottom: .1rem;


        }

        & p {
          font-size: 2rem;
        }

        & a {
          @apply btn btn-primary w-full py-2;
          min-height: 5rem;
          margin-top: 1rem;

          &:hover {
            color: var(--white);
          }
        }


      }
    }

    &-detail {
      &-name {
        display: block;
        width: 100%;
        box-shadow: none;
        position: relative;

        // & h3 {
        //   background: var(--white);
        //   color: var(--black);
        //   padding: 0;
        //   margin: 0;
        // }

        &-info {
          @apply border-radius;
          display: inline-flex;
          border: .3rem solid var(--primary);
          padding: 1.5rem;
          margin-top: 2rem;
        }
      }

      &-info {


        &-img {
          float: unset;
          width: 100%;
          margin: 1rem 0 3rem;
        }
      }
    }
  }



  &-popup {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 9997;

    &-container {
      background-color: var(--white);
      border-radius: 1rem;
      width: 50%;
      max-width: 50%;
      max-height: 90%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      z-index: 9998;

      @media (max-width: $mediaXS) {
        & {
          width: 90%;
          max-width: 90%;
        }
      }


      &-header {
        position: relative;
        // position: fixed;
        margin-bottom: 2rem;
        display: flex;
        z-index: 9999;
        background: white;
        width: 100%;
        max-width: 100%;
        padding: 2rem 0rem;

        & h1 {
          width: 100%;
        }

        &-title {

          &-text {

            & * {
              color: black;
            }
          }

          &-subtitle {
            & * {
              color: black;
            }

            margin: 0;
            padding: 0;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              width: 0.5rem;
              height: 100%;
              background: var(--primary);
              top: 0rem;
              right: -1rem;
              /* position: static; */
              display: block;
              margin-bottom: 1rem;
            }
          }
        }

        &-close {
          position: absolute;
          right: 1rem;
          top: 1rem;
          font-size: 4rem;
          cursor: pointer;
          user-select: none;

          background-color: transparent;
          position: absolute;
          display: block;
          width: 15px;
          height: 15px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='10px' viewBox='0 0 10 10' version='1.1'%3E%3C!-- Generator: sketchtool 61.2 (101010) - https://sketch.com --%3E%3Ctitle%3E66CF3711-9DBD-4B78-8AEB-8121E32F76B7@1%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Home-Page-3.0' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Home-Page---What-is-SyarahOnline' transform='translate(-275.000000, -126.000000)'%3E%3Cg id='Group-6' transform='translate(20.000000, 111.000000)'%3E%3Cg id='clear-24px-(11)' transform='translate(252.000000, 12.000000)'%3E%3Cpolygon id='Path' points='0 0 16 0 16 16 0 16'/%3E%3Cpath d='M12.2,3.80666667 C11.94,3.54666667 11.52,3.54666667 11.26,3.80666667 L8,7.06 L4.74,3.8 C4.48,3.54 4.06,3.54 3.8,3.8 C3.54,4.06 3.54,4.48 3.8,4.74 L7.06,8 L3.8,11.26 C3.54,11.52 3.54,11.94 3.8,12.2 C4.06,12.46 4.48,12.46 4.74,12.2 L8,8.94 L11.26,12.2 C11.52,12.46 11.94,12.46 12.2,12.2 C12.46,11.94 12.46,11.52 12.2,11.26 L8.94,8 L12.2,4.74 C12.4533333,4.48666667 12.4533333,4.06 12.2,3.80666667 Z' id='Path' fill='%23484848' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
    }
  }
}


.panel-section {


  .panel-content {

    margin: 3rem 0;
    background-color: #EEE;
    border-radius: 2rem;
    padding: 3rem;
    cursor: pointer;

    &-top {


      position: relative;
      display: flex;
      justify-content: space-between;
      overflow: hidden;

      img {

        transition: .3s;
      }

      &-tage {
        color: var(--black);
        z-index: 2;

        overflow: hidden;
        text-align: center;
        text-transform: capitalize;

        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
        border-radius: 1rem;
        font-size: 1.7rem;

        &:after {
          content: "";
          position: absolute;
          width: 50px;
          height: 3px;
          background: var(--primary);
          top: -21px;
          position: static;
          display: block;
        }
      }
    }

    .panel-content-body {

      display: none;

      >ul {
        display: flex;
        flex-wrap: wrap;

        li {

          margin: 1rem;
          display: flex;
          align-items: center;

          span {

            // width: 95%;
          }

        }

        li::before {
          content: "";
          width: 2rem;
          height: 2rem;
          display: block;
          background-color: #3bd461;
          margin: 2rem 2rem;
        }
      }
    }



  }

  .show-panel-info {

    >.panel-content-body {

      display: flex;
      flex-direction: column;
    }

    >.panel-content-top {

      img {

        transform: rotate(180deg);
      }

    }


  }

  .how-buy-menu {
    margin-bottom: 1rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem;
    border-radius: 1rem;
    gap: 1rem;
    min-height: 10rem;
    cursor: pointer;

    .how-buy-menu-btn-active {


      border: 0.4rem solid #3bd461;
    }

    .how-buy-menu-btn {


      width: 20rem;
      height: 8rem;
      display: flex;
      text-align: center;
      transition: all 0.3 ease-in-out;
      padding: 1rem 5rem;
      border-radius: 3rem;
      background: #EEE;
      justify-content: center;
      align-items: center;
    }
  }

  .how-buy-body {

    display: flex;
    flex-direction: column;
    align-items: center;

    .how-buy-body-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .how-buy-body-footer {

      display: flex;
      width: 100%;
      justify-content: space-around;
      margin: 3rem 0;

      .how-buy-body-footer-part {

        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.panel-white {

  // box-shadow: rgb(0 0 0 / 20%) 0px 0px 12px;

  .panel-content {

    background-color: #FFF;
    padding: 0;

    .panel-content-top {

      padding-top: 2rem;
      padding-bottom: 2rem;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;

      &-icon {

        width: 6rem;
        height: 6rem;

        & img {

          transform: none;
          transition: none;
        }

      }
    }

    .panel-content-body .bank-item {

      display: flex;
      align-items: center;
      border-bottom: 0.3rem solid #70707073;
      margin: 1rem 4rem;

      & img {

        width: 7rem;

      }
    }
  }

}


//detail
.box-package-detail {
  @apply bg-white border-radius box-shadow;
  position: relative;
  cursor: pointer;

  &.active {

    border: #3bd461 0.4rem solid;

    &:after {


      // content: "*";
      // position: absolute;
      // width: 0.5rem;
      // height: 100%;
      // // background: var(--primary);
      // top: 0rem;
      // // left: 0rem;
      // /* position: static; */
      // display: flex;
      // font-size: 3rem;
      // /* margin-bottom: 1rem; */
      // align-items: center;
      // justify-content: center;

    }
  }


  &-name {
    @apply bg-white border-radius flex items-center;
    top: -2rem;
    right: 0;
    padding-left: 2rem;
    justify-content: space-between;




    &-info {
      &-img {
        @apply border-radius overflow-hidden mr-6;
        float: left;
        width: 25%;
        max-height: 42rem;
      }

      &-details {

        width: 70%;

      }
    }


    & h3 {
      @apply font-bold border-radius;
    }


    & h4 {
      border-left: .2rem solid var(--gray);
    }

    &-info {
      display: flex;
    }

  }

}