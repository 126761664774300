.nav {
  &-contact {
    &-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      & > *:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    &-data {

      & li {
        display: flex;
        align-items: flex-start;
        gap: 2rem;

        & img {
          height: 3rem;
          width: 3rem;
          object-fit: contain;
        }

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }


    }

    &-map {
      @apply border-radius overflow-hidden box-shadow-sm;
      width: 100%;
      height: 40rem;
    }
  }
}