.carOverview {


    &-page {
        @media (min-width: 992px) {
            transform: scale(0.85);
            transform-origin: top center;
        }


        @media (max-width: $mediaS) {
            margin: 0;
            margin-top: 9rem;
        }


        &-content {
            // @media (min-width: 1200px) {

            //     width: 100%;
            //     height: 100%;
            //     /* Full height of the viewport */
            //     overflow: auto;
            // }
            @media (min-width: $mediaS) {
                height: auto;
                max-height: 100vh;
                overflow-y: auto;
                padding: 2rem 2rem;

                &::-webkit-scrollbar {
                    width: 0rem !important;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: none !important;
                    background-color: transparent !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: transparent !important;
                    border-radius: 1rem !important;
                    outline: 0 !important;
                }
            }

            @media (max-width: $mediaS) {

                margin: 0;

            }



        }


        margin: 0 7rem;
        text-transform: capitalize;


        &-main {

            // height: auto;
            // max-height: 100vh;
            // overflow-y: auto;
            // padding: 2rem 2rem;

            &::-webkit-scrollbar {
                width: 0rem !important;
            }

            &::-webkit-scrollbar-track {
                box-shadow: none !important;
                background-color: transparent !important;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent !important;
                border-radius: 1rem !important;
                outline: 0 !important;
            }

            @media (max-width: $mediaS) {
                padding: 0;
            }
        }

        &-sub {
            padding: 0rem 2rem;
            position: sticky;
            top: 0px;
        }

        padding-bottom: 2rem;

        &-header {
            @apply bg-gray-light;
            height: 35rem;
            position: relative;
            border-radius: 2rem;
            //background: #F2F2F2;

            &-top {
                @apply flex flex-row justify-between;
                width: 100%;
                height: 5%;

                &-tags {
                    @apply flex justify-center items-center text-24 text-white;
                    height: 10rem;
                    width: 10rem;
                }
            }

            &-slider {

                height: 85%;
            }

            &-img {
                margin: auto;
                max-height: 25rem;
                border-radius: 2rem;

            }

            &-actions {
                @apply flex px-10 flex-row absolute items-center nice-gap-3rem justify-end;
                bottom: 2rem;
                width: 100%;
                height: 10%;
                //z-index: 100;

                & .carOverview-btn-like {

                    -tw-bg-opacity: 1;
                    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
                    border-radius: 50%;
                    background: var(--white);
                    width: 4.5rem;
                    height: 4.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 1rem;
                    transition: all 0.3s;

                }
            }
        }

        &-body {

            .swiper-wrapper {
                display: grid;
                grid-auto-columns: auto;
                grid-auto-flow: column;
                justify-content: start;
                padding: 1rem;

            }

            .car-card-top {

                height: auto;
            }

            &-name {
                @apply relative;

                margin-top: 2.5rem;
                margin-bottom: 2.5rem;
                display: flex;
                flex-direction: column;


                & h3 {
                    margin-inline-start: 3rem;
                }

                @media (min-width: $mediaS) {
                    &::after {
                        content: '';
                        @apply bg-primary border-radius absolute;
                        height: 10rem;
                        width: 1rem;
                        top: 0rem;
                    }
                }

            }

            &-type {

                display: flex;

                &-content {
                    border-bottom: 0.5rem solid gray;
                    display: flex;
                    margin-inline-start: 3rem;
                    align-items: center;

                    img {

                        height: 4rem;
                    }
                }
            }

            .img-bank {

                & img {
                    width: 10rem;
                    height: 10rem;
                }


            }

            .desc-bank {

                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-delevary {
                @apply flex my-20 flex-col;

                &-time {
                    &-title {
                        @apply my-5 relative;



                        & h3 {
                            @apply relative font-bold;
                            margin: 0 3rem;
                        }

                        &::after {
                            content: '';
                            @apply bg-primary border-radius absolute;
                            height: 7rem;
                            width: 1rem;
                            top: -2rem;
                        }
                    }

                    &-cities {
                        width: 25%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .dropdown {
                            margin-bottom: 0;
                        }

                        .dropdown-field {
                            min-height: 5rem;
                        }
                    }

                    &-value {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        color: var(--primary);
                        @apply bg-secondary;
                        //background: var(--secondary);
                        padding: 1rem 2rem;
                        border-radius: 2rem;
                    }
                }

                &-branch {
                    height: 25rem;
                    @apply p-8 border-radius flex flex-col justify-center;
                    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;
                    padding: 1rem 2rem;

                    &:not(:first-child) {
                        border-right: 0.5rem solid #EEE;
                    }

                }
            }


            .available-section .available-body>.vailable-part {

                margin-right: 3rem;
            }

            .available-section {
                margin: 1rem 0;
                display: flex;
                flex-direction: column;

                .available-body {


                    .vailable-part {
                        height: 100%;
                        box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px;
                        // margin: 2rem 2rem;
                        padding: 1rem 2rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-radius: 2rem;
                        gap: 1rem;
                        justify-content: center;

                        >span {

                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 1;
                        }

                        .vailable-type>span {

                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            // height: 10rem;
                            align-items: center;
                            font-weight: bold;
                        }

                        .vailable-type-color-part {

                            display: grid;
                            align-items: center;
                            grid-template-columns: auto;
                            grid-auto-flow: column;
                            align-items: center;

                            span {

                                font-weight: bold;
                            }

                            img {
                                border-radius: 5rem;

                                position: absolute;
                                inset: 0;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }

                            .vailable-type-color {

                                width: 3rem;
                                height: 3rem;
                                border-radius: 5rem;
                                margin: 0 1rem;
                                position: relative;
                                overflow: hidden;
                                border: 2px var(--gray-dark) solid;
                            }
                        }
                    }
                }
            }






            .panel-section {


                .panel-content {

                    margin: 1rem 0;
                    background-color: #EEE;
                    border-radius: 2rem;
                    padding: 2rem 3rem;
                    cursor: pointer;

                    &-top {



                        display: flex;
                        justify-content: space-between;

                        img {

                            transition: .3s;
                        }
                    }

                    .panel-content-body {

                        display: none;

                        >ul {
                            display: flex;
                            flex-wrap: wrap;

                            li {

                                margin: 1rem;
                                display: flex;
                                align-items: center;

                                span {

                                    // width: 95%;
                                }

                            }

                            li::before {
                                content: "";
                                width: 2rem;
                                height: 2rem;
                                display: block;
                                background-color: #3bd461;
                                margin: 2rem 2rem;
                            }
                        }
                    }



                }

                .show-panel-info {

                    >.panel-content-body {

                        display: flex;
                        flex-direction: column;
                    }

                    >.panel-content-top {

                        img {

                            transform: rotate(180deg);
                        }

                    }


                }

                .how-buy-menu {
                    margin-bottom: 1rem;
                    margin-top: 3rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    padding: 0.5rem;
                    border-radius: 1rem;
                    gap: 1rem;
                    min-height: 10rem;
                    cursor: pointer;

                    .how-buy-menu-btn-active {


                        border: 0.4rem solid #3bd461;
                    }

                    .how-buy-menu-btn {


                        width: 20rem;
                        height: 8rem;
                        display: flex;
                        text-align: center;
                        transition: all 0.3 ease-in-out;
                        padding: 1rem 5rem;
                        border-radius: 3rem;
                        background: #EEE;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .how-buy-body {

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .how-buy-body-top {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .how-buy-body-footer {

                        display: flex;
                        width: 100%;
                        justify-content: space-around;
                        margin: 3rem 0;

                        .how-buy-body-footer-part {

                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                    }
                }
            }


            .panel-white {

                // box-shadow: rgb(0 0 0 / 20%) 0px 0px 12px;

                .panel-content {

                    background-color: #FFF;
                    padding: 0;

                    .panel-content-top {

                        padding-top: 2rem;
                        padding-bottom: 2rem;
                        box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;

                        &-icon {

                            width: auto;
                            height: 4rem;

                            & img {

                                transform: none;
                                transition: none;
                            }

                        }
                    }

                    .panel-content-body .bank-item {

                        display: flex;
                        align-items: center;
                        border-bottom: 0.3rem solid #70707073;
                        margin: 1rem 4rem;

                        & img {

                            width: 7rem;

                        }
                    }
                }

            }



            .panel-dark {
                .panel-content {

                    margin: 2rem 2rem;
                    background-color: #EEE;
                    border-radius: 3rem;
                    padding: 3rem;
                    cursor: pointer;

                    &-top {


                        padding: 0 !important;
                        box-shadow: none !important;
                        display: flex;
                        justify-content: space-between;

                        img {

                            transition: .3s;
                        }
                    }


                }


            }

            .panel-radius {

                margin: 3rem 0;
                border-radius: 3rem;
                padding: 3rem;
                cursor: pointer;
            }

            .panel-none-radius {

                margin: 0;
                border-radius: 0;
                padding: 3rem;
                cursor: pointer;
            }

            .panel-dark>.panel-content {
                background-color: #EEE;
            }

            .panel-white>.panel-content {

                background-color: #FFF;

            }

            .specs-section {
                padding-top: 2rem;
                padding-bottom: 2rem;

                .specs-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .specs-wrap-part {
                        box-shadow: rgb(0 0 0 / 10%) 0px 0px 12px;
                        margin: 1rem 1rem;
                        padding: 2rem 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-radius: 2rem;
                        width: 16rem;
                        background-color: #FFFFFF;
                        height: 11rem;
                        justify-content: space-between;

                        @media (max-width: $mediaS) {

                            width: 20rem;
                            height: 12rem;
                        }

                        .specs-wrap-part-contant {

                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;

                        }

                        .specs-wrap-part-titel {

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            img {

                                height: 2rem;
                            }


                            span {

                                margin: 0 1rem;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 1;
                            }
                        }
                    }
                }
            }

        }


    }

    .brand {
        height: 8rem;
        width: 7rem;
        @apply flex flex-col-reverse items-center p-2;
        border-radius: 0 0 2rem 2rem;

        & img {
            @apply object-full;
            object-fit: scale-down;
        }

        & span {
            @apply text-center block font-bold text-16 capitalize text-white;
        }

        &-toyota {
            background-color: var(--toyota);
        }

        &-lexus {
            background-color: var(--lexus);
        }
    }


}


.box-package-detail {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background: var(--white);
    position: relative;
    cursor: pointer;

    .box-package-detail-name {
        display: flex;
        align-items: center;
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
        border-radius: 1rem;
        background: var(--white);
        top: -2rem;
        right: 0;
        padding-left: 2rem;
        justify-content: space-between;
    }

    .box-package-detail-name-info {
        display: flex;
    }
}

.box-package-detail.active {
    border: #3bd461 0.4rem solid;
}

.offer-detail-data ul li:after {
    content: "";
    position: absolute;
    top: 1rem;
    inset-inline-start: -2rem;
    border-radius: 100rem;
    height: 1rem;
    width: 1rem;
    background: var(--primary);
    width: 0.5rem;
    height: 0.5rem;
}

.botder-bottom:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 3px;
    background: var(--primary);
    top: -21px;
    position: static;
    display: block;
}


.eparts-page-footer {
    z-index: 9999;
    border-top: .1rem solid var(--secondary);
    height: 18rem;
    display: none;
    width: 100%;
    position: relative;
    transition: all .5s ease-in-out;
    @apply fixed bg-white flex flex-col nice-gap-2rem p-10 nice-shadow;
    bottom: 0;

    &>div:after {
        content: "";
        height: 2px;
        width: 20%;
        position: absolute;
        right: 40%;
        left: 40%;
        top: 0;
        background-color: var(--gray-dark)
    }

    &-scrollable {
        @apply absolute bg-gray;
        height: .5rem;
        width: 20rem;
        top: 0;
        left: 50%;
        transform: translateX(-50%)
    }

    &-actions {
        @apply flex flex-row;
        border-radius: .5rem;
        height: 7rem;
        margin-top: auto;
        margin-bottom: auto;
        width: 30rem;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;

        @media (max-width: $mediaS) {
            height: 11rem;
        }

        &-btn-cart {
            @apply flex justify-center items-center;
            //border-radius: 0rem;
            flex-basis: 50%;
            cursor: pointer;
            border-start-end-radius: 1rem;
            border-end-end-radius: 1rem;


            img {
                @apply object-contain;
                height: 80%;
                width: 80%;
            }
        }

        &-btn-buy {
            @apply flex justify-center items-center;
            //border-radius: 0rem;
            flex-basis: 50%;
            cursor: pointer;
            border: 0.3rem solid var(--primary);
            border-end-start-radius: 1rem;
            border-start-start-radius: 1rem;

            .loading-btn {

                height: 70%;
                width: 70%;

                img {
                    @apply object-contain;
                    height: 100%;
                    width: 100%;
                }
            }

            img {
                @apply object-contain;
                height: 80%;
                width: 80%;
            }
        }


    }

    & .btn-notfound {

        display: flex;
        flex-direction: row;
        border-radius: 3rem;
        height: 13rem;
        margin-top: auto;
        margin-bottom: auto;
        width: 40rem;
        overflow: hidden;
        border: 0.5rem solid #707070;

        &-available {

            width: 50%;
        }

        &-alert {

            width: 70%;
        }

        & img {

            width: 6rem;
            height: 6rem;
        }

    }
}

@media (min-width: 992px) {
    .carCheckout-page {
        transform: scale(0.85);
        transform-origin: top center;
    }
}

.carCheckout-page {
    margin: 0 7rem;
    text-transform: capitalize;
    padding-bottom: 2rem;
}

@media (max-width: 768px) {
    .carCheckout-page {
        margin: 0;
        margin-top: 9rem;
    }
}

.checkoutStepper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: center;
    gap: 2rem;
}

.checkoutStepper-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: -webkit-fill-available;

    &.active .checkoutStepper-part-titel li {

        color: var(--gray);
        border-color: var(--gray);
    }

    .checkoutStepper-part-titel {

        width: 100%;
        padding: 2rem 1rem;
        font-size: 2rem;

        & span {

            width: 100%;
            font-size: 2rem;
            color: var(--gray);

            @media (max-width: $mediaXS) {
                // display: none;
            }

        }

        &.active {
            & span {
                color: var(--primary);
                font-size: 2.5rem;
                font-weight: bold;
            }

            & li {

                color: var(--primary) !important;
                border-color: var(--primary) !important;
            }
        }

        @media (max-width: 1024px) {
            & span {
                font-size: 2rem !important;
            }
        }

    }
}

.checkoutStepper-part-titel {
    width: 100%;
    padding: 2rem 1rem;
    font-size: 2rem;
    color: var(--gray-light);
    text-align: center;

    & span {

        font-size: 2rem;
        color: var(--gray);
        font-weight: 700;
    }
}

.checkoutStepper-part-connector {
    width: 99%;
    height: 1rem;
    border-radius: 2rem;
    background-color: var(--gray-light);
    overflow: hidden;
}

.checkoutStepper-part.active {

    .checkoutStepper-part-connector {
        background-color: var(--primary);
    }
}


.overview-share-content {

    position: fixed;
    min-height: 5rem;
    top: 25vh;
    z-index: 10;
    width: 8rem;
    margin: 0.5rem 0;
    display: flex;
    transition: all 0.5s ease-in-out;
    transform: translate(100rem, 0);

    &-icons {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

}

.finance-requirements-modal {


    .offer-popup-container {

        width: 35%;
        position: relative;
        background-color: #fff;
        border-radius: 16px;
        padding: 35px;
        @media (max-width: 1024px) {
                width: 90%;
                min-height: 50vh;      
        }
    }

    .header {
        color: var(--primary);
        font-weight: bold;

    }

    .tabs {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c7a8d;

        .tab {
            min-width: 50%;
            border-radius: 4px;
            background: #f1f6fc;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #6c7a8d;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            cursor: pointer;

            &.tab-active {
                background: var(--primary);
                color: #fff;
                font-weight: 700;
                pointer-events: none;
            }
        }


    }

    .requirements-section {
        margin-bottom: 20px;

        .section-title {
            margin-top: 24px;
            margin-bottom: 10px;
            display: block;
            color: var(--primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        ul {
            list-style: none;
            padding: 0;
            

            li {
                color: #484848;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;

                &::before {
                    content: "•";
                    color: var(--primary);
                    font-weight: bold;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
}