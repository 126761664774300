$border: .2rem solid rgba(0, 0, 0, .15);
.part {
  &-detail {
    display: grid;
    grid-template-columns:1fr 1.6fr .8fr;
    gap: 3rem;
    align-items: start;

    &-imgs {
      display: flex;
      flex-direction: column;
      height: 60rem;

      &-main {
        @apply border-radius flex justify-center items-center;
        background: var(--secondary-light);
        height: 75%;

        & > div {
          height: 100%;
          width: 100%;
        }


      }

      &-others {
        height: 25%;
        display: grid;
        gap: 1rem;
        margin-top: 1rem;
        grid-template-columns:repeat(3, 1fr);
      }

      &-img {
        @apply border-radius flex justify-center items-center;
        background: var(--secondary-light);
        padding: 1.5rem;
      }
    }

    &-info {
      &-num {
        & > * {
          @apply text-16;
          display: inline-block;

        }
      }

      &-rate {
        display: flex;
        gap: 2rem;
        align-items: center;

        & .rating {
          display: flex !important;
          gap: .5rem !important;
        }

        & .rating-info {
          display: flex;
        }

        & .rating-divider {
          width: .1rem;
          background: var(--gray);
          margin: 0 .8rem;
          border-radius: 100rem;
        }

      }

      &-price {
        @apply flex  items-center;
        gap: 2rem;
        border: .1rem solid var(--gray);
        padding: 1rem 0;
        border-left: none;
        border-right: none;
      }


      & .info-spec {
        display: flex;
        gap: 2rem;
        margin-bottom: .5rem;

        & > * {
          @apply text-16;
        }

        &-key {
          @apply font-bold;
          width: 7rem;
        }
      }


    }

    &-options {
      & .options {
        @apply bg-white box-shadow border-radius ;
        padding: 5rem 0 2rem 0;
        position: relative;
        margin-top: 2rem;

        & .options-like {
          position: absolute;
          &-r{
            left: 23.5%;
            transform: translateX(-50%);
          } 
          &-l{
            right: 23.5%;
            transform: translateX(50%);
          }
          top: -2rem;
          width: 100%;
        }

        & .option-title {
          @apply text-16 font-bold pr-10 ;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            border-radius: 1rem 0 0 1rem;
            background: var(--primary);
            width: .5rem;
            height: 120%;
          }
        }

      }

      &-counter {
        @apply flex justify-center items-center;
        gap: 2rem;
        height: 7.5rem;
        position: relative;

        & .btn {
          @apply border-radius-sm;
          height: 90%;
        }

        & .counter {
          display: grid;
          grid-template-columns: 1fr 1.2fr 1fr;
          height: 90%;
          gap: .5rem;

          &-num, &-btn {
            @apply flex justify-center items-center h-full;
          }

          &-num {
            @apply text-20 font-bold bg-white border-radius-sm;

          }

          &-btn {
            @apply text-30 font-bold bg-white box-shadow border-radius-sm;
            padding: 0 1.5rem;
            transition: all .3s;
            cursor: pointer;

            &:hover {
              @apply box-shadow-sm;
              transform: scale(.95);
            }
          }
        }
      }

      & .services {
        @apply bg-white box-shadow border-radius mt-6;
        padding: 2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        row-gap: 3rem;

        & > div {
          display: flex;
          align-items: center;
          gap: .5rem;


          & .service-img {
            width: 2.5rem;
            height: 2.5rem;
            object-fit: contain;
          }

          & .service-text {
            position: relative;

            & .info-icon {
              width: 1rem;
              height: 1rem;
              object-fit: contain;
              position: absolute;
              top: 0;
              left: -1rem;
            }
          }
        }
      }
    }

  }

  &-locations {

    &-flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }

    &-title {
      @apply text-16 font-bold bg-primary border-radius-sm;
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
      padding: 1rem 2rem 1rem 1rem;
      color: white;
      display: inline-block;


    }

    &-name {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-place {
      position: relative;
      min-height: 2ch;

      &-link {
        position: absolute;
        bottom: .5rem;
        left: .5rem;
      }
    }
  }

  &-info {
    &-detail {
      &-btns {
        display: flex;
        align-items: center;
        border-bottom: .4rem solid rgba(0, 0, 0, 0.15);

        & > button {
          padding: .5rem 2rem 1.5rem 2rem;
&:focus{
  outline: none !important;
  box-shadow: none;
}
          &:hover {
            @apply text-primary-dark;
          }

          &.hidden {
            display: block;
          }

          &.active {
            @apply text-primary;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              bottom: -.4rem;
              left: 0;
              width: 100%;
              height: .4rem;
              background: var(--primary);
            }
          }

        }
      }

      &-car {
        &-container {
          display: grid;
          grid-template-columns: .8fr 2.5fr;

          border: $border;


          & > .part-info-detail-car-img {
            border-left: $border;
            padding: 2rem;

            & .img-flex {
              @apply flex justify-center items-center flex-col;

              & > img {
                width: 80%;
                height: 80%;
                object-fit: contain;
                margin-bottom: 1rem;
              }
            }

          }

          &:not(:first-child) {
            border-top: none;
          }
        }

        &-detail {
          display: grid;
          grid-template-columns: .15fr .15fr 1fr;
          //align-items: center;
          //border-top: $border;
          border-bottom: $border;


          &:first-child {
            border-top: none;
          }


          & > div {
            @apply text-16 py-8 px-6;
            &:not(:last-child) {
              border-left: $border;
            }
          }
        }

        &-name {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;

        }
      }


      &-rate {
        &-container {
          display: flex;
          align-items: flex-start;
          gap: 2rem;
          padding: 3rem 0;
          border-bottom: .2rem solid rgba(0, 0, 0, .15);
        }

        &-img {
          @apply border-radius-full overflow-hidden;
          width: 8rem;
          height: 8rem;

        }

        &-text {
          width: 93%;
          height: 100%;
        }


      }
    }
  }

  @media (max-width: $mediaXS) {

    &-detail {
      margin-top: 18rem;
      grid-template-columns:1fr;
      gap: 8rem;

      &-imgs {
        height: unset;
      }
    }

    &-info {
      &-detail {


        &-car {
          &-container {
            border: $border !important;
            grid-template-columns: 1fr;
            margin-bottom: 4rem;
          }

          &-img {
            border: none !important;
          }

          &-detail {
            grid-template-columns: repeat(2, 1fr);
            border: none;

            & > div {
              padding: 1rem;
            }

            & div:last-child {
              grid-column: span 2;
              text-align: center;
            }


            & .text-center {
              border: $border !important;
              border-right: none !important;
              border-left: none !important;

              &:first-child {
                border-left: $border !important;
              }
            }


          }
        }
      }
    }
  }
}

.no-b-bottom {
  border-bottom: none !important;
}