﻿.job {
  &-heading {
    @apply bg-primary text-white px-5 py-4 text-20 font-bold box-shadow inline-block border-radius mb-6;
  }

  &-tag {
    @apply border-radius-sm text-12 font-bold bg-gray;
    padding: 1.2rem 1.5rem;
    display: inline-block;
    color: white;
    max-width: 10.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-card {
    height: 30rem;
    overflow: hidden;
    padding: 1.2rem 2.5rem 1.8rem 1.2rem;
    background: var(--white);


    &-text {
      height: 100%;
      display: flex;
      flex-direction: column;

      &-upper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          color: var(--primary-dark);
        }
      }

      a:hover {
        color: var(--primary-dark);
      }
    }
  }

  &-filter {
    @apply p-8 border-radius box-shadow;
    // -mt-32 
    @apply flex justify-center items-end;
    position: relative;
    z-index: 2;
    gap: 2.5rem;
    align-items: center;

    & .job-dropdown {
      width: 33.33%;
    }

    button {
      @apply bg-primary text-16 text-white border-radius;
      padding: .8rem 3rem;
    }
  }


  @media (max-width: $mediaXS) {

    &-grid {
      grid-template-columns: 1fr;
      gap: 2.5rem;
    }

    &-filter {
      gap: 3rem;
      flex-direction: column;

      & .job-dropdown {
        width: 100%;
      }

      &>* {
        width: 100%;
      }

      & button {
        padding: 1rem;
      }
    }
  }
}

.employment-application {

  @media (max-width: $mediaXS) {
  .phone-selectable {

    height: 7rem;
  }

  .phone-selectable-country-box span{

    font-size: 1.5rem;
  }

  .phone-selectable-input {
    font-size: 2.5rem;
    height: 100%;
  }
  .dropdown-field{
    height: 7rem;

  }
  .custom-input-file{

    height: 7rem;
    overflow: hidden;
  }
  .input input{
    font-size: 2.5rem;
    }
  }
  .customStepper {

    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: center;
    padding: 1rem;
    grid-template-columns: repeat(6, auto);

    @media (max-width: $mediaXS) {
      grid-template-columns: repeat(4, 1fr);
    }

    .customStepper-part {

      width: auto;

      &.active .customStepper-part-titel li {

        color: var(--gray);
        border-color: var(--gray);
      }

      .customStepper-part-titel {

        width: 100%;
        padding: 2rem 1rem;
        font-size: 2rem;

        & span {

          width: 100%;
          font-size: 2rem;
          color: var(--gray);

          @media (max-width: $mediaXS) {
            display: none;
          }

        }

        &.active {
          & span {
            color: var(--primary);
            font-weight: bold;
          }

          & li {

            color: var(--primary) !important;
            border-color: var(--primary) !important;
          }
        }

      }
    }
  }
}