.loading {
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @apply items-center  justify-center flex absolute;
  background: var(--page);
  &-content{
    @apply border-radius p-2 bg-black flex flex-col justify-center items-center;
    &-img {
      height: 10rem;
      object-fit: contain;
    }
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}