.what {
  &-offer {
    display: grid;
    grid-template-columns: 1.2fr 3fr;
    gap: 4rem;
    padding: 2rem;
    position: relative;
    overflow: hidden;

    &-boxes {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
      z-index: 1;
    }

    &-text {

      z-index: 1;
    }

    &-box {
      @apply bg-white box-shadow border-radius;
      height: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .5rem;

      & h3 {
        color: var(--primary);
        font-size: 5.5rem;
        font-weight: bold;
      }

      & small {
        @apply text-20;
      }

    }

    &-second-theme{

      &-overlay {
        position: absolute;
        inset: 0;
        height: 100%;
        z-index: 1;
    }

    &-bg {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      & img {
        width: 100%;
    height: 100%;
    object-fit: cover;
      }
  }

  &-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    z-index: 1;
  }


  &-text {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color:white ;
    }
    & p {

      color:white ;
    }
  
  }

  &-box {
    @apply  border-radius;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    border-style: solid;
    border-width: 0.5rem;
    border-color: #ffffff;
    stroke: #ffffff;

    & img {

      width: 5rem;
      height: 5rem;
    }

    & h3, .account-balance {
      color: var(--primary);
      font-size: 5.5rem;
      font-weight: bold;
      display: flex;
    align-items: center;
    gap: 1rem;

    & span {

      color: var(--primary);
      font-size: 5.5rem;
      font-weight: bold;
    }
     
    }

    & small {
      @apply text-20;
      color:white ;
    }

  }
    }
    
  }

  @media (max-width: $mediaXS) {
    &-offer {
      grid-template-columns: 1fr;

      &-boxes {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }

      &-box {
        &:first-child {
          grid-column: span 2;
        }
      }
    }
  }
}

.about {
  &-box {
    position: relative;

    &-title {
      @apply bg-primary border-radius text-30 font-bold text-white py-4 px-6;
      position: absolute;
      top: -4rem;
      // right: 0;
    }

    &-desc {
      @apply bg-white border-radius box-shadow text-20 w-full pt-20 pb-12 px-12;
    }
  }

  @media (max-width: $mediaXS) {
    &-w-1\/2 {
      width: 90%;
    }
  }
}

.award {
  &-container {
    @apply flex justify-center items-center;
    gap: 3rem;
    flex-wrap: wrap;
    margin-top: 5rem;

  }

  &-img {
    filter: brightness(0);
    opacity: .45;
    max-width: 18rem;
    max-height: 18rem;
  }
}