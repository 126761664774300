$sliderHeight: 50rem;

$carWidth: 100rem;
$carHeight: 35rem;

$infoTextWidth: 54rem;

$specSize: 4.7rem;

.slide {
  &-container {

    .swiper-button-prev,
    .swiper-button-next {
      top: 1.5rem !important;
      border: .315rem solid !important;
      color: var(--white) !important;
      width: 5rem;
      height: 5rem;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 2.5rem;
      font-weight: bold;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      &:after {
        content: 'next' !important;
      }

      left: auto !important;
      right: 4rem !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      &:after {
        content: 'prev' !important;
      }

      left: auto !important;
      right: 10rem !important;
    }

    &>.swiper-pagination-bullets {
      transform: rotateZ(90deg);
      right: 2rem;
      width: unset !important;
      left: unset !important;
      display: inline-block;
      bottom: 25rem !important;

      &>.swiper-pagination-bullet {
        background: var(--white) !important;
        opacity: .7 !important;

        &-active {
          background: var(--primary) !important;
          opacity: 1 !important;
        }
      }
    }

  }

  &-car {
    &-container {
      position: relative;
      height: $sliderHeight;
      overflow: hidden;

    }
  }

  &-overlay {
    position: absolute;
    inset: 0;
    height: 100%;
    background: linear-gradient(to right, #090d0b 0%, rgba(26, 28, 27, 0.76) 51.45%, rgba(42, 42, 42, 0.13) 100%);
    z-index: 1;
  }

  &-bg {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    direction: rtl;
    &-img {
      position: relative;
      z-index: 2;
      height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: flex-end;


      &>img {
        width: $carWidth;
        height: $carHeight;

        object-fit: contain;
        transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);

        &:hover {
          transform: scale(1.2);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-webkit-perspective: 1000;
          -moz-webkit-perspective: 1000;
          -o-webkit-perspective: 1000;
          -ms-webkit-perspective: 1000;
          perspective: 1000;
      }
      }
    }

    &-desc {

      display: flex;
      flex-wrap: nowrap;
      width: 50%;
      height: 100%;
      padding-top: 10rem;
      justify-content: space-between;
      direction: rtl !important;
    }

    &-text {
      position: relative;
      color: white;
      z-index: 2;
      width: $infoTextWidth;

      & span {
        color: white;
      }

      h2 {
        font-size: 5rem;
        font-weight: bold;
        color: white;
      }

      &-price {


        & .price {
          color: var(--primary);
          margin-right: 1.5rem;
        }

        & .old-price {
          color: var(--danger);
          text-decoration: line-through;
        }
      }

      &-links {
        gap: 2rem;
        position: absolute;
        margin-top: 1rem;

        & span {
          color: white;
        }
      }
    }

    &-spec {
      z-index: 2;

      & span {
        color: white;
      }

      &>div {
        width: 11rem;
        height: 6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: white;

        &>.spec-img {
          width: $specSize;
          height: $specSize;
          margin-right: 1rem;

          &>img {
            height: 100%;
            width: 100%;
            margin-right: auto;
          }
        }

      }
    }

    &-tag {
      @apply border-radius-full px-3 py-1;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      height: 5rem;
      width: 14rem;
      background: var(--hybrid);
    }
  }


  // new car secondary
  &-car-secondary {
    &-container {
      // height: 46rem;
    }

    .swiper-wrapper {
      align-items: flex-end !important;
    }


    .swiper-slide-active {
      position: relative;
      transition: all .5s;

      .car-img {
        transform: scale(1.4);
        // margin-bottom: 3rem;

      }

      &:after {
        content: "";
        position: absolute;
        left: -1.5rem;
        top: 0;
        animation: moveUp 1s ease forwards;

        width: .1rem;
        background: var(--gray);
      }

      &:before {
        content: "";
        position: absolute;
        right: -1.5rem;
        bottom: 0;
        animation: moveUp 1s ease forwards;
        width: .1rem;
        background: var(--gray);
      }

      .car-secondary-info {
        &-desc {
          @apply text-line-clamp text-line-clamp-2;
          max-height: 6.5rem;
        }

        &-options {
          opacity: 2;
        }
      }
    }
  }

  //car overview slider
  &-car-360 {
    overflow: unset !important;

    .swiper-button-prev,
    .swiper-button-next {
      @apply bg-white border-radius-full box-shadow;
      top: 16rem !important;
      border: .315rem solid !important;
      color: var(--white) !important;

      width: 6rem;
      height: 6rem;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 2.5rem;
      font-weight: bold;
      content: "" !important;
      background-image: url(360Arrow.svg) !important;
      width: 3rem;
      height: 3rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      &:after {}

      left: auto !important;
      right: -8rem !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      &:after {
        transform: rotateZ(180deg);
      }

      right: auto !important;
      left: -8rem !important;
    }

  }

  @media (max-width: $mediaXS) {

    &-offer-date {
      display: none;
    }

    &-info {

      display: flex;
      // flex-direction: column;
      width: 100%;
      padding: 2rem;
      &-spec {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        justify-content: center;
        // position: absolute;
        top: 13rem;
        width: 100%;
        left: 3rem;
        margin: 0 !important;
        gap: 2rem;
        flex-direction: row-reverse;
        // display: none;
        &>div {
          margin: 0 !important;
          // justify-content: center;
          width: unset;

          &>*>* {
            font-size: 1.8rem;
          }

          &>.spec-img {
            width: 4rem;
            height: 4rem;
          }
        }
      }

      &-desc {

        width: 50%;
        height: 100%;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
      }

      &-text {
        // position: absolute;
        z-index: 3;
        // left: 2rem;
        // bottom: 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        position:unset;
        gap: 1rem;
        margin: 0rem;
        & h2 {
          @apply text-30;
        }

        & .text-section {
          order: 1;
          display: flex;
          height: 40%;
          width: 42%;
          // justify-content: space-between;
          flex-direction: column;
          position: absolute;
          top: auto;
        
        }

        &.language-ar .text-section {

          right: 5rem;
        }

        
        &.language-en .text-section {

          right: 5rem;
        }

        &-price {
          display: grid;
          grid-template-columns: .6fr 1fr;
          margin-bottom: 2rem;

          & span:last-child {
            grid-column-start: 2;
          }

          &>* {
            font-size: 2.6rem !important;
          }
        }

        &-btn {

          order: 3;
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .btn{

          width: 100%;
          min-height: 5rem;
          height: 5rem;
        }

        }

        &-links {
          order: 2;
          // flex-direction: column;
          // width: 35%;
          // margin-right: auto;
          // gap: .8rem;
          // margin-bottom: 4rem;
          position: relative;
          display: grid;
          grid-gap: 1rem;
          gap: 1rem;
          grid-template-columns: repeat(1, 1fr);
          & .btn {
            min-height: unset;
            padding: 1rem 2rem;
          }

          // &>*:last-child {
          //   display: none;
          // }
        }
      }

      &-img {
        width: 50%;
        height: 95%;

        &>img {
          width: 100%;
          height: auto; //100%
        }
      }


      &-tag {
        top: unset;
        right: 2rem;
        bottom: 1rem;
      }
    }

    &-bg,
    &-overlay {
      @apply border-radius overflow-hidden;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      right: auto;
      width: 100%;
    }

    &-car-container {
      margin: 9rem 0 0 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      height: 55rem !important;
      padding: 0rem;

      & .btn-primary {
        // display: none;
      }
    }

    &-car-secondary {
      &-container {
        height: 66rem;
      }

      .car-img {
        transform: scale(1.1);
        margin-bottom: 3rem;
      }

      .swiper-slide {
        padding: 0 3rem;
      }

      .swiper-slide-active {
        position: relative;
        transition: all .5s;

        .car-img {
          transform: scale(1.1);
        }

        &:after {
          left: 1.5rem;
        }

        &:before {
          right: 1.5rem;
        }

        .car-secondary-info {

          &-desc,
          &-options {
            max-height: unset;
          }

        }
      }

      .car-secondary-info {

        &-desc {
          display: block !important;
          max-height: unset !important;
        }
      }
    }


  }
}

@keyframes moveUp {
  from {
    height: 1%;

  }

  to {
    height: 70%;
  }

}

@keyframes moveDown {
  from {
    height: 70%;

  }

  to {
    height: 1%;
  }

}


@media (max-width: $mediaXS) {
  .main-slider {
    & .swiper-pagination {
      display: none !important;
    }
  }

}