.advanced{
    height: 100%;
    width:100%;
    display:flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    top:20px;
    // animation-name: animate-down;
    // animation-timing-function: linear;
    // animation-duration: .8s;
    // animation-fill-mode: forwards;
    &-search-box{
        width: 90%;
        height:9rem;
        background-color:var(--white);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        border-radius: 12px;
        &-container{
            height:100%;
            display:flex;
            flex-direction: row;
            &-item-r{
                flex-basis: 10rem;
                overflow: hidden;
            }
            &-item-c{
                flex-grow: 1;
            }
            &-item-l{
                flex-basis: 15rem;
            }
            // position:relative;
            // align-content: stretch;
            // height: 100%;
            // width: 100%;
            // display:flex;
            // align-items: stretch;
            // flex-direction: row;
            // gap: 0;
        }
    }
    &-suggestions{
        overflow: hidden;
        position:absolute;
        border-radius: 8px;
        max-height: 300px;
        overflow-y: auto;
        width: 90%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        background-color: var(--white);
        top:10rem;
        & > ul{
            padding: 0;
            margin: 0;
            & > li{
                position: relative;
                display: flex;
                justify-items: baseline;
                justify-content: space-between;
                &:last-of-type(){
                    border-bottom-style: none;
                }
                border-bottom: 2px solid var(--secondary);
                cursor: pointer;
                padding: 12px;
                font-size: 2.5rem;
                transition: all .3s ease-out;
                &:hover{
                    background-color:  var(--gray-lighter);
                }
            }
        }
    }
}


.suggestion-btn{
                
    min-width: 80px;
    min-height: 35px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 12px;
    font-size: 1.8rem;
    color:var(--white);
}


.suggestions-flow-box{
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    width: 100%;
    padding: 8px;
    height: 100%;
    background-color: var(--white);
    display: flex;
    // align-items: center;
    // justify-content: space-between;
    &-cancel{
        display: inline-block;
        color: var(--primary);
        font-size: 2rem;
    }
}

@keyframes animate-down {
    from{
        top:-500px;
    }

    to{
        top:20px;
    }
}


.search-input{
    width:100%;
    height:100%;
    color:var(--super-gray);
    border-style: none;
    outline-style: none;
}

.drop-box{
    border-right: 2px solid var(--secondary);
}

.search-icon{
    height: 100%;
    display: flex;
    transition: all .3s ease-out;
    justify-content: center;
    align-items: center;
}

.search-btn{
    cursor: pointer;
    border-radius: 0 12px 12px 0;
    &:hover .search-icon{
     transform : scale(1.4);
    }
}

