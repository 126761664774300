.user-sidebar-button {

  display: none;
  // position: absolute;
  top: -7px;
  left: -23px;
  z-index: 9;
  margin: 2rem 0;

  @media (max-width:$mediaXS) {
    display: flex;
  }

  img {
    width: 5rem;
    height: 6rem;
  }
}

.user {
  &-sidebar {
    @apply box-shadow-sm border-radius;
    height: 100%;
    padding: 2rem;
    background: var(--white);
    position: relative;

    @media (max-width:$mediaXS) {
      // display: none;
      margin-top: 10rem;
      padding: 1rem;
      top: 0;
      display: flex;
      flex-direction: column;
      width:100%;
      outline: 0;
      height: 100%;
      position: fixed;
      transition: transform 0.3s ease-in-out;
      z-index: 9;

      &.language-ar {
        right: 0;
        border-left: 2px #FFF solid;
      }

      &.language-en {
        left: 0;
        border-right: 2px #FFF solid;
      }
      

        &-hidden {
          &.language-ar {
            transform: translateX(100%);
          }
          &.language-en {
            transform: translateX(-100%);
          }

        }
      


        &-show {
          transform: none;
          visibility: visible;
        }


      }

      &-header {

        &-phone {
          display: none;

          @media (max-width:$mediaXS) {
            display: flex;
            inset-inline-start: 0;
            flex-direction: row-reverse;
          }

          &-icon {

            & div {
              width: 5rem;
              height: 5rem;
              font-size: 7rem;

            }

          }

          img {
            width: 5rem;
            height: 6rem;
            margin-top: 2rem;
          }

        }

        @media (max-width:$mediaXS) {
          &-items {
            transition: all 0.25s ease-in-out;

            &-show {
              transition: all 0.25s ease-in-out;
              display: block;
            }

            &-hidden {
              transition: all 0.25s ease-in-out;
              display: none;
            }

          }
        }

        .logout {
          @apply border-radius-full;
          padding: .2rem;
          cursor: pointer;
          // display: inline-block;
          transition: all .3s;
          width: 3.5rem;

          &:hover {
            @apply box-shadow-sm;
            background: var(--secondary-lighter);

            img {
              @apply filter-red;
            }
          }


        }

        .profile {
          &-image {
            @apply border-radius-full;
            overflow: hidden;
            width: 15rem;
            height: 15rem;
            margin: auto;
          }

          &-name {
            @apply text-20 font-bold my-8 text-center text-line-clamp text-line-clamp-3;
            color: var(--gray-dark);
          }
        }
      }

      &-nav {
        display: flex;
        flex-direction: column;
        gap: .7rem;

        .sidebar-link {
          padding: 1.2rem;
          display: flex;
          gap: 1rem;
          align-items: center;
          transition: all .3s;
          cursor: pointer;
          border-bottom: 1px var(--gray-light) solid;
          .link-text {
            font-weight: bold;
            color: var(--gray-dark);
          }

          img {
            width: 3rem;
          }

          &.active {
            background: var(--secondary);

            img {
              @apply filter-primary;
            }

            .link-text {
              color: var(--black);
            }
          }

          &:hover {
            background: var(--secondary-lighter);
          }
        }
      }
    }
  }