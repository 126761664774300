﻿.helper
{
  &-section{
  &-heading{
  font-size: 18px;
  font-weight: bold;
  color:#1d1d1d;
}
  &-label{
  color:#090d0b;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;}
}
  // @media (max-width: $mediaXS) {
  //  display:none;
  // }
}