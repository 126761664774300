.mainteance {
  position: relative;

  &-service-btn {
    height: 30rem;
    width: 100%;
    border-radius: 1rem;
    background-color: var(--primary);
    background-position: 1rem 8rem;
    background-repeat: no-repeat;
    background-size: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;

    @media (max-width: $mediaXS) {
      height: 8rem;
      margin: 5rem 0rem;
    }
  }

  &-box {
    position: relative;
    min-height: 32rem;
    width: 100%;
    border-radius: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 1rem;
      content: "";
      background-image: linear-gradient(-30deg,
          rgba(0, 0, 0, 0.8),
          rgba(255, 255, 255, 0.2));
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    &-details {
      justify-content: end;
      width: 100%;
      position: absolute;
      height: 10rem;
      background-color: var(--white);
      border-radius: 1rem;
      bottom: -1rem;
      z-index: 2;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      &-links {
        display: flex;
        flex-direction: row;
        // justify-content: end;
        gap: 1.4rem;

        &>a {
          position: relative;
          display: flex;
          flex-direction: row;
          gap: 0.2rem;

          img {
            height: 2.5rem;
            width: 2.5rem;
          }
        }

        &-vertical {
          width: 0.3rem;
          height: 100%;
          background-color: var(--amazing-gray);
        }
      }

      @media (max-width: $mediaXS) {
        // display: none;
      }
    }

    @media (min-width: $mediaN) {

      &-phone,
      &-actions {
        display: none;
      }
    }

    @media (max-width: $mediaXS) {
      &-phone {
        position: absolute;
        z-index: 3;
        top: 3rem;
        right: 1rem;
      }

      &-actions {
        z-index: 3;
        position: absolute;
        left: 1rem;
        bottom: 1rem;
      }
    }
  }

  &-popup {
    width: 100%;
    height: 100%;

    &-container {
      background-color: var(--white);
      border-radius: 1rem;
      width: 50%;
      // max-height: 90vh;
      overflow-x: hidden;

      &-body {

        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 4rem 2rem;

      }

      @media (max-width: $mediaXS) {
        & {
          width: 90%;
        }
      }

      &-header {
        position: relative;
        margin-bottom: 2rem;

        &-close {
          position: absolute;
          right: 1rem;
          top: -1rem;
          font-size: 4rem;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  &-list {
    counter-reset: item;

    &>&-item {
      position: relative;
      text-indent: -1em;
      list-style-type: none;
      counter-increment: item;

      &-r {
        &:before {
          font-size: 2.5rem;
          display: inline-block;
          width: 1em;
          padding-right: 0.2em;
          font-weight: bold;
          text-align: right;
          content: counter(item);
        }

        &:after {
          position: absolute;
          content: "";
          background-color: var(--primary);
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 1rem;
          z-index: -1;
          right: -4.4rem;
          bottom: -0.3rem;
        }
      }

      &-l {
        &:before {
          font-size: 2.5rem;
          display: inline-block;
          width: 1em;
          padding-left: 0.2em;
          font-weight: bold;
          text-align: left;
          content: counter(item);
        }

        &:after {
          position: absolute;
          content: "";
          background-color: var(--primary);
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 1rem;
          z-index: -1;
          left: -4.4rem;
          bottom: -0.3rem;
        }
      }

    }
  }

  &-feature {
    position: relative;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);

    img {
      max-height: 90%;
      max-width: 90%;
    }

    &:after {
      position: absolute;
      content: "";
      width: 95%;
      height: 95%;
      border-radius: 50%;
      border: 0.4rem solid var(--white);
    }
  }

  &-banner {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    height: 45rem;
    width: 100%;
    border-radius: 1rem;

    &-overlay {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 2rem;
      top: 1.6rem;
      width: 100%;
      left: 0;
      border-radius: 1rem;
      background-image: linear-gradient(-30deg,
          rgba(0, 0, 0, 0.8),
          rgba(255, 255, 255, 0.2));
      z-index: 1;
    }
  }
}

.border-box-periodic-maintenance {
  width: 100%;
  /* height: 16rem; */
  display: inline-block;
  /* background-color: #FBFBFB; */
  padding: 2rem 1rem;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;
  border-radius: 2rem;
  cursor: pointer;
  height: 10rem;

  & h3 {
    padding-inline-start: 1rem;
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    font-size: 2rem;
  }
}

.border-primary-box {
  border: 0.3rem solid var(--primary) !important;
}

.border-box-periodic-checkInput {
  height: fit-content;

  .input input {
    height: auto;
    font-size: unset;
  }
}

.protection-and-shading,
.offer-service-reservation {

  &-top {
    width: 100%;
    height: auto;
    margin-bottom: -1rem;

    &-lang {
      background: var(--primary);
      // position: relative; 
      z-index: 5;
      display: flex;
      height: 5rem;
      /* left: -2rem; */
      /* margin-right: auto; */
      /* border-radius: 2rem; */
      /* border-top-right-radius: 0; */
      /* border-top-left-radius: 0; */
      padding: 0rem 1rem;
      align-items: center;


      & span {
        font-size: 2rem;
      }

      & img {

        width: 3rem;
      }
    }
  }

  &-head {

    width: 100%;
    display: flex;
    color: #fff;
    padding: 2rem 2rem;
    justify-content: space-between;
    align-items: center;

    &-extra {

      display: flex;
      position: relative;
      gap: 1rem;
      inset-inline-start: 2rem;
    }

    &-Logo {

      & .res-Logo-icon {
        width: 30rem;
        height: auto;
      }

    }




    &-brand {
      display: flex;
      gap: 1rem;
      flex-direction: row-reverse;
      align-content: center;
      align-items: center;
      justify-content: center;

      & img {

        width: 22rem;
      }
    }
  }


  &-titel {

    word-spacing: 3px;

    &-sub1 {
      width: fit-content;

      &::after {
        background: var(--primary);
        content: "";
        position: absolute;
        width: 100%;
        height: 1.5px;
        top: -21px;
        position: static;
        display: block;
        margin-bottom: 1rem;
      }


    }
  }

  &-menu {

    & .swiper-wrapper {
      display: grid;
      grid-auto-columns: auto;
      grid-auto-flow: column;
      justify-content: start;
      padding: 1rem;
    }

    &-item {

      width: 100%;
      display: inline-block;
      padding: 2rem 1rem;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
      cursor: pointer;

      &-titel {

        font-size: 3rem;
      }
    }

    &-servie {
      padding-inline-start: 3rem;
      display: flex;
      justify-content: space-between;
      padding-inline-end: 3rem;
      gap: 1rem;
      width: -webkit-fill-available;
      align-items: center;

      &.selectd::before {
        position: absolute;
        font-family: "FontAwesome";
        content: "\f00c";
        font-size: 2.5rem;
        color: #3BD461 !important;
        inset-inline-start: 0rem;
      }

    }

    &-price {
      white-space: nowrap;
      font-size: 2rem;
     direction: ltr;
    }

    &-addbtn {

      border-radius: 1rem;
      height: 5rem;
      position: relative;
      margin-right: auto;
      align-items: end;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0rem 1rem;
    }

    & .panel-section {
      position: relative;
    }

    & .panel-section-overlay {

      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: #00000030;
    }

    & .panel-content {
      margin: 0.5rem 0 !important;
      border-radius: 0 !important;
      padding: 2rem !important;

      & .panel-content-body>ul {
        display: block;

        & li span {

          white-space: break-spaces;
        }

      }

      &.show-panel-info {
        border: 1px var(--primary) solid;
        background: var(--white);
      }

      .panel-content-body>ul li::before {
        content: none;
        width: 0rem;
        height: 0rem;
        display: block;
        background-color: #000000;
        margin: 0rem;
        border-radius: 50%;
      }
    }
  }

  & .reservation-head-sub {

    background: var(--black);

    & h1 {
      color: #fff;

    }

  }

  &.toyota-theme {


    & .protection-and-shading-top,
    & .offer-service-reservation-top {


      &-lang {

        background: var(--danger);
        color: #fff;
      }
    }

    & .protection-and-shading-head,
    & .offer-service-reservation-head {


      &-brand {


        & span {

          color: var(--danger);
        }
      }

    }

    & .protection-and-shading-titel,
    & .offer-service-reservation-titel {
      &-sub1 {
        &::after {

          background: var(--danger);
        }
      }

    }

    & .protection-and-shading-menu,
    & .offer-service-reservation-menu {

      &-item {

        &.active {

          background-color: var(--danger);

          h3 {
            color: #fff;
          }

        }

      }

      &-price {
        color: var(--danger);
      }

      &-addbtn {
        background-color: var(--danger);
      }

      & .panel-content.show-panel-info {
        border: 1px var(--danger) solid;
        background: var(--white);
      }

    }

    & .reservation-head-sub {

      background: var(--danger);

      & h1 {
        color: #fff;

      }
    }
  }

  &.lexus-theme {

    & .protection-and-shading-top,
    & .offer-service-reservation-top {


      &-lang {

        background: var(--black);
        color: #fff;
      }
    }

    & .protection-and-shading-head,
    & .offer-service-reservation-head {


      &-brand {


        & span {

          color: var(--black);
        }
      }

    }

    & .protection-and-shading-titel,
    & .offer-service-reservation-titel {
      &-sub1 {
        &::after {

          background: var(--black);
        }
      }

    }

    & .protection-and-shading-menu,
    .offer-service-reservation-menu {

      &-item {

        &.active {

          background-color: var(--black);

          h3 {
            color: #fff;
          }

        }

      }

      &-price {
        color: var(--black);
      }

      &-addbtn {
        background-color: var(--black);
      }

      & .panel-content.show-panel-info {
        border: 1px var(--black) solid;
        background: var(--white);
      }

    }

    & .reservation-head-sub {

      background: var(--black);

      & h1 {
        color: #fff;

      }
    }
  }
}