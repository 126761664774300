@import "sidebar/sidebar";
@import "notification";
.user {
  &-page {
    display: grid;
    grid-template-columns: .35fr 1.65fr;
    gap: 1rem;
     @media (max-width:$mediaXS) {
       display:flex;
       flex-direction: column;
       margin-top: 9rem;
     }
    &-content {
      @media (max-width:$mediaXS) {
        // margin-top: 8rem;
      }
      padding: .6rem 2rem;
      width: 100%;
      overflow-x: hidden;
      scrollbar-width: none; 
      overflow-y: scroll; 
      position: relative;
      &::-webkit-scrollbar{
        display: none;
      }
      &-phone{
        display: none;
        @media (max-width:$mediaXS) {
          // display: block;
        }
        position: fixed;
        z-index: 5000;
        &-menu{
          position: absolute;
          top:5rem;
          right:-3rem;
          cursor: pointer;
          user-select: none;
          height: 10rem;
          width:10rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:rgba(0,0,0,.9);
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          transition: transform .5s ease-in-out;
          &:hover img{
            transform: scale(1.2);
          }
          img{
            transition: transform .5s ease-in-out;
            max-width: 3.5rem;
            max-height: 3.5rem;
          }   
        }
        &-items{
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          position: absolute;
          display: flex;
          flex-direction: column;
          min-height: 25rem;
          width: 10rem;
          background-color: rgba(0,0,0,.9);
          border-radius: 10rem;
          &-icon{
            transition: transform .5s ease-in-out;
            img{
              transition: transform .5s ease-in-out;
              width:5rem;
              height: 5rem;
            }
            &-cancel{
              transition: transform .5s ease-in-out;
            }
            &:hover .user-page-content-phone-items-icon-cancel{
              transform: scale(1.2);
            }
            &:hover img{
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}

.icon-8{
  font-size: 8rem;
}