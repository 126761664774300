.finance {
  &-detail {
    &-car {
      display: grid;
      grid-template-columns:1fr 3fr;
      align-items: start;
      gap: 2rem;
      position: relative;


      & .img {
        @apply flex flex-col justify-evenly items-center bg-secondary border-radius p-8;
        width: 50rem;
        height: 36rem;
        overflow: hidden;
        position: relative;

        .car-img {
          position: relative;
          z-index: 1;
        }

        .car-img-bg {
          left: -30%;
          height: 80%;
          width: 80%;
        }

      }
    }

    &-money {
      display: grid;
      grid-template-columns: 4fr 1fr;
      gap: 2rem;
    }
  }

  @media (max-width: $mediaXS) {
    &-detail {
      & > div:nth-child(2) {
        margin-top: 6rem;
      }

      &-car {
        grid-template-columns: 1fr;
        gap: 6rem;

        & .img {
          width: 100%;
          height: 50rem;
        }
      }

      &-money {
        grid-template-columns: 1fr;
      }
    }
  }
}

.finance-table {

  border-collapse: collapse;
  width: 100%;

  td, th {
    border: .2rem solid rgba(0, 0, 0, .1);
  }

  &-row {
    & > * {
      @apply text-16 font-bold py-6 px-8 text-center;
    }

    & > td:first-child {
      width: 40%;
      text-align: right;
    }

    &:nth-child(2n-1) {
      background: var(--secondary-lighter);
    }

  }
}