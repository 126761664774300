.finance {
  &-car {

    &-card {
      // height: 35rem;
      position: relative;
      margin-bottom: 3rem;
      background: var(--secondary);
      @media (max-width:$mediaXS) {
        margin-top: 6rem;
      }
      & .car-img {
        height: 53%;
        overflow: hidden;
      }

      &-type {
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        margin: auto;
        border-top: .4rem solid var(--toyota);

        &.lexus {
          border-top: .4rem solid var(--lexus);
        }

        &-brand {
          position: relative;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: auto;
          margin-top: -1px;
          height: 4.9rem;
          width: 4.7rem;
          background: var(--toyota);
          @apply border-radius;
          border-top-right-radius: 0;
          border-top-left-radius: 0;

          img {
            width: 80%;
            height: 80%;
            object-fit: contain;
          }
        }
      }

      &-info {
        height: unset;
        padding: 1.5rem 2rem;
        background: var(--white);

        display: flex;

        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        // height: 47%;

        &-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem;
          width: 100%;

          h3{

            width: 85%;
          }

        }

        &-tag {
          @apply bg-primary border-radius-full p-2;
          width: 15%;
        }
      }
    }

  }

  @media (max-width: $mediaXS) {
    &-gap {
      gap: 2rem;
    }
    &-car-card {
      height: 43rem;

      &-type-brand h4 {
        font-size: 1.3rem;
      }
    }
  }
}