.filter-search {
  &-btns-2 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    & > button {
      @apply w-full py-2 border-radius bg-white box-shadow ;
      text-align: center;
      transition: all .3s;

      &.hidden {
        box-shadow: none;
        background: none;
        display: block;

        &:hover {
          background: var(--page-dark);
        }
      }
    }
  }

  &-btns {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);

    &-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    & > button {
      @apply w-full py-2 border-radius bg-white box-shadow ;
      text-align: center;
      transition: all .3s;

      &.hidden {
        box-shadow: none;
        background: none;
        display: block;

        &:hover {
          background: var(--page-dark);
        }

      }
    }
  }

  &-container {
    @apply bg-white border-radius box-shadow p-8 ;
  }

  &-grid {
    width: 100%;
    display: grid;
    gap: 2rem;
  }

  &-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  &-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $mediaXS) {

    &-container {
      & > div {
        flex-direction: column;
        width: 100%;
        gap: 2rem;

        & .btn {
          width: 100%;
        }

        & .filter-search-grid {
          grid-template-columns: 1fr !important;
        }
      }
    }

    &-4 {
      grid-template-columns: repeat(2, 1fr);
      width: 100% !important;
    }

    &-btns {
      & button.hidden {
        background: var(--secondary-light);
      }
    }
  }
}