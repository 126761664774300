.own-order {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  z-index: 3;
  &-history {
    flex-basis: 55rem;
    max-height: 100vh;
    scrollbar-width: none; 
    overflow-y: scroll; 
    &-item{
        position: relative;
        background-color:var(--white);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        min-height: 20rem;
        border-radius: 10px;
        &-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &-status{
              display: flex;
              padding: 0.5rem 2rem;
              justify-content: center;
              align-items: center;
              color:var(--white);
              white-space: nowrap;  /* يجعل النص على سطر واحد فقط */
              overflow-wrap: break-word; /* يسمح بكسر النص عند الحاجة */
              word-wrap: break-word; /* متوافق مع المتصفحات القديمة */
            }
        }
        &-products{
          flex-wrap: wrap;
          height: 9rem;
          gap:5px;
          &-item{
            border-radius: 1rem;
            background-color: var(--gray-light);
            height:100%;
            width:8rem;
            img{
              max-height: 4rem;
              max-width: 8rem;
            }
          }
        }
        &-summary{
          align-items: center;
          min-height: 7rem;
        }
        &-editable{
         height:5rem;
         width:100%;
         &-btn{
           min-width:12rem;
           height:70%;
           border-radius: 10rem;
         }
        }
    }
    &::-webkit-scrollbar{
        display: none;
    }
  }
  &-details{
    align-items: center;
    max-height: 100vh;
    flex-basis: 50vw;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
    &-box{
      width: 80%;
      border-radius: 1rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      &-header{
        // border-bottom: 2px solid var(--amazing-gray);
        min-height: 5rem;
        justify-content: space-between;
        align-items:baseline;
      }
      &-records{
        &-item{
          align-items: center;
          position: relative;
          width:100%;
          &-right{
            &::after{
              content:'';
              position: absolute;
              width:1.5rem;
              height:1.5rem;
              top:30%;
              transform:translateY(50%);
              right:-2.2rem;
              z-index: 2;
              border-radius: 50%;
              background-color: var(--primary);
            }
          }
          &-left{
            &::after{
              content:'';
              position: absolute;
              width:1.5rem;
              height:1.5rem;
              top:30%;
              transform:translateY(50%);
              left:-2.2rem;
              z-index: 2;
              border-radius: 50%;
              background-color: var(--primary);
            }
          }
        }
        
      }
    }
  }
  &-vehicle{
    min-height:14rem;
   &-box{
     width:14rem;
     background-color: var(--gray-light);
     border-radius: 1rem;
     img{
       max-height: 100%;
       max-width: 100%;
     }
     &-btn{
      bottom:-1rem;
      background-color:var(--primary);
      color:var(--white);
      border-radius: 1rem; 
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;    }
   }
  }
}

.border-gray {
  border-style: solid;
  border-width: 0 0 0 0;
  border-color: var(--amazing-gray);
  &-right {
    border-right-width: 3px;
  }
  &-left {
    border-left-width: 3px;
  }
}
.item-active{
    border:2px solid var(--primary);
    &-indicator{
        position:absolute;
        left:0;
        opacity:0;
        top:50%;
        transition:all .3s ease-in-out;
        transform:translateY(-50%);
        img{
            user-select: none;
            max-height: 2.5rem;
            max-width: 2.5rem;
        }
    }
}
.status-detail{
  padding: 0.5rem 2rem;
  img{
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
}
.info-circle{
  height:3.5rem;
  width:3.5rem;
  border-radius: 50%;
  background-color: rgba(59, 212, 97, 0.1);
  img{
    max-width: 3 rem;
    max-height:3rem;
  }
}
.amazing-ruller{
  width:100%;
  height:2px;
  background-color: var(--amazing-gray);
}
.return-btn{
  display: none;
}
@media (max-width:$mediaXS) {
  .own-order-history{
    z-index: 100;
    flex-basis: 100%;
  }
  .own-order-details{
    flex-basis: 100%;
    margin-top:9rem;
  }
  .own-order-details-box{
    width: 100%;
  }

  .return-btn{
    display: flex;
  }
}

.nice-flex-baseline{
  align-items: baseline;
}