.vehicle-compare {
  position: relative;
  scrollbar-width: none;
  overflow-x:scroll;
  max-width: 95%;
  &::-webkit-scrollbar{
      display: none;
  }  
  &-header {
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: row;
    &-item-primary {
      height: 100%;
      display: flex;
      min-width:25rem;
      max-width: 25rem;
      flex-basis: 25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--gray-light);
      background-color: var(--white);
    }
    &-item {
      position: relative;
      height: 100%;
      display: flex;
      min-width: 25rem;
      max-width: 25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--gray-light);
      background-color: var(--white);
      &-img{
          width:100%;
          height: 100%;
          &-container{
              height: inherit;
              width: 100%;
              background-color: var(--gray-light);
              border-radius: 1rem;
              img{
                  max-height: 15rem;
                  max-width: 15rem;
              }
          }
      }
    }
  }
  &-body{
      width:100%;
      display:flex;
      flex-direction: column;
      &-row{
          min-height: 20rem;
          max-height: 20rem;
          display: flex;
          flex-direction: row;
          &-item{
              position: relative;
              min-width: 25rem;
              max-width:25rem;
              min-height:100%;
              max-height: 100%;
              border: 1px solid var(--gray-light);
              background-color: var(--white);
              &-properity{
               &:after{
                content: '';
                position: absolute;
                height:80%;
                width:100%;
                top:0;
                border-style: solid;
                border-color: var(--gray-lighter);
                border-width: 0 .8rem .8rem .8rem;
                border-bottom-left-radius: 5rem;
                border-bottom-right-radius: 5rem;
               } 
              }
          }
      }
  }
}
.nice-trash-btn{
    position: absolute;
    top: .2rem;
    left:0;
    transition:  all .5s ease-in-out;
    img{
        max-width: 2.8rem;
        max-height: 2.8rem;
    }
    &:hover{
        transform: scale(1.2);
    }
}
