.part-search {
  display: grid;
  grid-template-columns: .8fr 3.2fr;
}

.list-items-cat{
    position: absolute;
    //margin: 0px;
    inset: 0px auto auto 0px;
    transform: translate3d(0px, 0px, 0px);
  
  //inset: 0px auto 0px auto;
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}