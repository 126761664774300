.serviceEvaluation {
    &-section {
        @apply flex flex-col;

        &-title {

            // @apply text-bold text-40;
            // font-weight: lighter;
            @media only screen and (max-width: 2200px) {
                @apply text-bold ;

            }

            @media only screen and (max-width: 576px) {
                @apply text-bold ;
;
            }
            &-error {
                @apply text-bold ;
                text-align: center;
            }
        }

        &-icons {
            @apply flex flex-row justify-evenly;

            &-item {
                @apply flex justify-center items-end text-center;
                flex-basis: 12rem;
                height: 8rem;
                margin-bottom: 0px;

                img {
                    max-width: 70%;
                }
            }
        }

        &-map {
            @media only screen and (max-width: 2200px) {
                @apply flex flex-row justify-evenly items-center nice-gap-1rem mb-0;
            }

            @media only screen and (max-width: 576px) {
                @apply flex flex-row justify-evenly items-center nice-gap-1rem mb-0;
            }

            @media only screen and (max-width: 567px) {
                // margin-bottom: 0rem;
                @apply flex flex-row justify-evenly items-center nice-gap-1rem mb-0;
            }

            &-item {
                @media only screen and (max-width: 2200px) {
                    @apply flex justify-center items-center text-center nice-pointer;
                    border-width: 0.3rem;
                    border-style: solid;
                    border-radius: 1rem;
                    flex-basis: 12rem;
                    height: 5rem;
                }

                @media only screen and (max-width: 576px) {
                    @apply flex justify-center items-center text-center nice-pointer;
                    border-width: 0.3rem;
                    border-style: solid;
                    border-radius: 1rem;
                    flex-basis: 12rem;
                    height: 5rem;
                }

                // @media only screen and (max-width: 567px) {
                // height: 6rem;
                // }
            }
        }
    }
}

.ruler-gray {
    width: 100%;
    height: 0.3rem;
    background-color: var(--gray-light);
}

.item-active {
    background: var(--primary);
    color: var(--white) !important;
}

.serviceEvaluation-text-note {
    font-weight: bold;
    margin: 12px 0px 0px 0px;

    &-input {
        font-weight: bold;
        margin: 12px 0px;
        padding-top: 14px;
        padding-bottom: 14px;
        border-radius: 5rem;
        border: 0.2rem solid #d1d1d1;
    }
}

.serviceEvaluation-btn {
    padding-top: 16px;
    padding-bottom: 16px;

    @media only screen and (max-width: 2200px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 576px) {
        font-size: 13px;
    }

    @media only screen and (max-width: 567px) {
        font-size: 13px;
    }

    box-shadow: 0px 0px 3px 10px rgba(0, 0, 0, 3%);
    // box-shadow: 0px 0px 3px 10px #f5f5f5; //?? على حسب اختياربعد الفحص
    border-radius: 8px;
}

.serviceEvaluation-star-error {
    margin: 10px 0px;
    padding: 0px 0px;
}

$background-color_1:var(--toyota);
$background-color_2: var(--lexus);
$background-color_3: var(--toyota);

/* toggle in label designing */
/* After slide changes */
/* Checkbox checked effect */
/* Checkbox checked toggle label bg color */
/* Checkbox vanished */
.toggle-switch {
	margin: 2rem;
.label-toggle {
	position: relative;
	display: inline-block;
	width: 10rem;
	height: 3.7rem;
	background-color: $background-color_3;
	border-radius: 30px;
	cursor: pointer;
	img {
		position: absolute;
		width: 5rem;
		height: 4rem;
		object-fit: contain;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		top: 0rem;
		left: 6rem;
		transition: all 0.5s;
		// box-shadow: 0px 5px 0rem 0px rgb(0 0 0 / 28%);
		max-height: none;
		background:$background-color_1;
	}
}
.checkbox {
	&:checked+.label-toggle {
		img {
			left: -2rem;
			background-color: $background-color_2;
		}
		background-color: $background-color_2;
	}
	display: none;
}
}