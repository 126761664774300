.nav {
  &-brand {
    &-filter {
      @apply border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;

      & .item {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background: var(--page-dark);
        height: 6rem;
        padding: 1rem;
        width: 100%;

        & img {
          height: 100%;
          object-fit: contain;
        }

        &.active {
          background: var(--white);
        }
      }
    }
  }

  &-car {

    
    &-list{

      height: 100%;
      overflow-y: scroll;
      max-height: 45vh;
    }

    &-filter {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: .4rem solid rgba(0, 0, 0, 0.15);

      margin-bottom: 2rem;


      & > * {
        @apply text-16 font-bold;
        flex: 1 1;
        text-align: center;
        padding: 1rem 0;
cursor: pointer;
        &.active {
          @apply text-primary;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: -.4rem;
            left: 0;
            width: 100%;
            height: .4rem;
            background: var(--primary);
          }
        }
      }


    }

    &-list {
      & .car {
        @apply border-radius;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        cursor: pointer;

        & img {


          transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);

         &:hover {
            transform: scale(1.2);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-webkit-perspective: 1000;
            -moz-webkit-perspective: 1000;
            -o-webkit-perspective: 1000;
            -ms-webkit-perspective: 1000;
            perspective: 1000;
        }
        }

        & span {
          @apply text-line-clamp text-line-clamp-1 font-bold;
          width: 100%;
          text-align: left;
        }
        & .nav-car-link {
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;
         & > a {
            color: var(--black);
            color: var(--black);
            font-weight: lighter;
            display: flex;
            padding: 0 1.3rem;
            text-transform: capitalize !important;
            --tw-text-opacity: 1;
            font-size: 2rem;
        }
          & > a:not(:last-child) {
            border-left: 0.1rem solid var(--gray);
        }

        & > a:hover {
          color: var(--primary);
      }
        }
        &:hover {
          background: var(--page-dark);

          & .nav-car-link {
            opacity: 1;
          }
        }
      }
    }
  }
}