.selectable-drop {
    position: relative;
}

.phone-selectable {
    width: 100%;
    flex-direction: row;
    position: relative;
    border: 0.2rem solid var(--input-border);
    border-radius: 1rem;
    //    padding:.8rem 1.2rem;
    font-size: 1.7rem;

    &-bg {
        &-white {
            background-color: var(--white);
        }

        &-grey {
            background-color: var(--gray-lighter);
        }
    }

    &-country-box {
        user-select: none;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 100%;
        width: 20%;
        border-right: 0.2rem solid var(--input-border);

        &-columns {
            flex-direction: column;
        }

        &-rows {
            flex-direction: row;
        }

        &-list {
            position: absolute;
            top: 50px;
            background-color: var(--white);
            width: 100%;
            overflow-y: auto;
            max-height: 200px;
            z-index: 10;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

            &-item {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                justify-content: space-between;
                transition: all .2s ease-out;

                &:hover {
                    background-color: var(--gray-light);
                }
            }
        }
    }

    &-flex {
        position: relative;
        display: flex;

        &-rows {
            flex-direction: row;
        }

        &-columns {
            flex-direction: column;
        }
    }

    &-input {
        position: relative;
        direction: ltr;
        text-align: left;
        width: 80%;
        font-size: 2rem;
        border-style: hidden;
        outline-style: hidden;
        box-shadow: 0 0 0 transparent !important;
        min-height: 4rem;
    }
}


.flag-icon {
    height: 1.9rem;
    width: 1.9rem;
}

.flag-text {
    font-size: 13px;
}

.flag-heading {
    color: var(--gray-dark);
    user-select: none;
}

.flag-btn {
    display: flex;
    align-items: center;
    transition: all .3s ease-out;
    transform: rotate(30deg);

    &-open {
        transform: rotate(180deg);
    }

    &-close {
        transform: rotate(0deg);
    }

    &>img {
        max-width: 1.9rem;
        max-height: 1.9rem;
    }
}

.list-unstyle {
    list-style: none;
    padding: 0;
    margin: 0;
}

.active-border {
    border: 0.2rem solid var(--primary-dark);
}

.selectable-drop {
    position: relative;
}

.phone-selectable {
    width: 100%;
    flex-direction: row;
    position: relative;
    border: 0.2rem solid var(--input-border);
    border-radius: 1rem;
    font-size: 1.7rem;
    min-height: 6rem;
    overflow: hidden;

    &-bg {
        &-white {
            background-color: var(--white);
        }

        &-grey {
            background-color: var(--gray-lighter);
        }
    }

    &-country-box {
        user-select: none;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 100%;
        width: max-content;
        padding: 1rem;
        border-right: 0.2rem solid var(--input-border);

        &-columns {
            flex-direction: column;
        }

        &-rows {
            flex-direction: row;
        }

        &-list {
            position: absolute;
            top: 50px;
            background-color: var(--white);
            width: 100%;
            overflow-y: auto;
            max-height: 200px;
            z-index: 10;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

            &-item {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                justify-content: space-between;
                transition: all .2s ease-out;

                &:hover {
                    background-color: var(--gray-light);
                }
            }
        }
    }

    &-flex {
        position: relative;
        display: flex;

        &-rows {
            flex-direction: row;
        }

        &-columns {
            flex-direction: column;
        }
    }

    &-input {
        position: relative;
        direction: ltr;
        text-align: unset;
        width: 75%;
        border-style: hidden;
        outline-style: hidden;
        box-shadow: 0 0 0 transparent !important;
        padding: 1rem 2rem;
    }

    &-input-from-login {
        position: relative;
        direction: ltr;
        width: 84%;
        border-style: hidden;
        outline-style: hidden;
        box-shadow: 0 0 0 transparent !important;
        padding: 1rem 2rem;
    }
}


.flag-icon {
    height: 1.9rem;
    width: 1.9rem;
}

.flag-text {
    font-size: 13px;
}

.flag-heading {
    color: var(--gray-dark);
    user-select: none;
}

.flag-btn {
    display: flex;
    align-items: center;
    transition: all .3s ease-out;
    transform: rotate(30deg);

    &-open {
        transform: rotate(180deg);
    }

    &-close {
        transform: rotate(0deg);
    }

    &>img {
        max-width: 1.9rem;
        max-height: 1.9rem;
    }
}

.list-unstyle {
    list-style: none;
    padding: 0;
    margin: 0;
}

.active-border {
    border: 0.2rem solid var(--primary-dark);
}


@media (max-width: 576px) {
    .phone-selectable-country-box-list {
        position: fixed !important;
        top: 20% !important;
        right: 5% !important;
        margin: auto 0 !important;
        width: 90% !important;
        min-height: 50% !important;
        border: solid 2px #919191 !important;
        z-index: 9999 !important;
    }
}

.phone-Input-module {

    .phone-selectable-flex-rows {
        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
        }
    }

    .phone-selectable {
        overflow: visible;
    }

    input {

        height: 58px;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        background-color: inherit;
        border-radius: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        @media (max-width: 576px) {
            & {
                height: 50px !important;
                font-size: 16px !important;
            }
        }
    }


    .phone-selectable-country-box-list {
        top: 8rem;
        border: solid 2px #d6dfe9;
        padding: 3rem 1rem;
    }



    /* Label acting as the placeholder */
    .Input-module__label {
        position: absolute;

        transition: transform 0.3s, font-size 0.3s;
        pointer-events: none;
        /* Prevent clicking the label */
        font-weight: bold;
        --height: 22px;
        top: calc(50% - var(--height) / 2);
        font-size: 16px;
        line-height: var(--height);
        color: #6c7a8d;
        // transition: all .2s;
        inset-inline-start: 3rem;

    }
}

/* On focus or when input has value */
.phone-Input-module input:focus+.Input-module__label,
.phone-Input-module input:not(:placeholder-shown)+.Input-module__label,
.phone-Input-module input+.Input-module__label.input-has-placeholder {
    transform: translate(8px, -100%);
    font-size: 14px;
    top: 11px;
    inset-inline-start: 5rem;
    color: #484848;
    background-color: inherit;
    padding: 0rem 2px;
    border-radius: 1rem;
}