$gap: 6rem;
$imageMaxWidth: 40%;

.info {
  &-section {
    display: flex;
    gap: $gap;

    &-text {
      @apply pb-8;
      width: 60%;
      h2 {
        font-weight: bold;
        @apply text-30 mb-10;
      }

      p {
        @apply text-20 text-height-20 ;
      }
    }

    &-img {
      max-width: $imageMaxWidth;
      overflow: hidden;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    @media (max-width: $mediaXS) {
      & {
        flex-direction: column;
        flex-direction: column-reverse;
        gap: 0;

        &-text{
          width: 100%;
          text-align: center;
        }

        &-img {
          max-width: 100%;
        }
      }
    }

  }

}