.cart {
  &-branches {
    min-height: 14rem;
    max-height: 16rem;
    position: relative;
    overflow-y: hidden;
    scrollbar-width: none;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &-cart {
      display: inline-block;
      border-radius: 1rem;
      height: 16rem;
      width: 100%;
      margin: 0 .5rem;
      border: .2rem solid var(--gray-light);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }
  }

  &-card {
    @apply bg-white border-radius box-shadow;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;
    position: relative;

    .card-error-lable {

      display: none;
    }


  }

  &-card-error {

    .cart-card {
      border: 0.4rem var(--danger) solid;
    }

    .card-error-lable {

      display: flex;

      & span {
        font-size: 2rem;
        color: var(--danger);
        padding: 0rem 2rem;
      }
    }


  }

  &-detail {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1rem;

      // & h3 {
      //   width: 15%;
      // }

      // & p {
      //   @apply text-line-clamp text-line-clamp-1;
      // }
    }

    &-left {
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }
  }

  &-counter {

    display: flex;
    gap: 1rem;
    position: relative;
    flex-direction: column;

    &-btn {
      @apply flex items-center justify-center border-radius-sm text-gray;
      border: .2rem solid var(--gray);
      width: 4rem;
      height: 4rem;
      transition: all .3s;

      &:hover {
        background: var(--page-dark);
      }

      & img {
        width: 60%;
        height: 60%;
        object-fit: contain;
      }
    }

    &-num {
      padding: 1rem 2rem;
    }
  }

  &-delete {
    @apply flex items-center justify-center border-radius-sm;
    transition: all .3s;

    // position: absolute;
    // top: 1rem;
    width: 5rem;
    height: 5rem;

    &-right {
      right: 1rem;
    }

    &-left {
      left: 1rem;
    }

    &:hover {
      background: var(--page-dark);
    }

    & img {
      width: 60%;
      height: 60%;
      object-fit: contain;
    }
  }

  &-groups {
    display: flex;
    position: absolute;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    bottom: 0;
    width: 90%;

    &-btn {
      @apply bg-primary border-radius;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: .5rem 2rem;
      justify-content: center;
      min-width: 14rem;
      align-items: center;
      color: white;

      & img {
        transition: all .3s ease-in-out;
        width: 1.5rem;
        height: 1rem;
      }
    }
  }

  &-more {
    @apply bg-primary border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: .5rem 2rem;
    position: absolute;

    justify-content: center;
    color: white;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    & img {
      width: 1.5rem;
      height: 1rem;
    }
  }

  &-additional {
    @apply bg-white box-shadow border-radius mt-4;
    overflow: hidden;
    transition: all .3s;

    &-title {
      @apply text-line-clamp text-line-clamp-1;
    }

    &>div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;


    }

    &-container {
      @apply bg-secondary border-radius p-4 inline-flex justify-center items-center flex-col;
      position: relative;
      width: 9rem;
      height: 9rem;

      & .img {
        height: 80%;
        width: 100%;
        object-fit: contain;
      }
    }

    &-info {
      position: absolute;
      top: .5rem;
      left: .5rem;
      width: 1.5rem;
      height: 1.5rem;
      object-fit: contain;
    }
  }

  @media (max-width: $mediaXS) {

    &-delete {
      top: unset;
      bottom: 6rem;
      width: 9rem;
      height: 9rem;
    }

    &-card {
      padding-bottom: 7rem;
      grid-template-columns: 1fr;
    }

    &-detail {
      gap: 2.5rem;

      // &-container {
      //   & h3 {
      //     width: 25%;
      //   }
      // }
      &>div {

        flex-direction: column !important;
      }

      &-left {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        gap: 4rem;
        margin-top: 1.5rem;

        &>div:first-child {
          width: 100%;

          & div:first-child {
            //  width: 25%;
          }
        }
      }
    }

    &-counter {
      margin-top: 3rem;

      &-title {
        top: -4.5rem;
      }

      &-btn {
        width: 5rem;
        height: 5rem;
      }
    }

    &-additional {
      &-info {
        width: 2.5rem;
        height: 2.5rem;
      }

      &-title {
        font-size: 2rem;
      }

      &-container {
        width: 14rem;
        height: 14rem;
      }
    }

    &-more {
      width: 75%;
    }
  }

  &-copon {
    border: .3rem dotted var(--gray-light);
    border-radius: 1rem;
    position: relative;

    &-delete {
      color: var(--gray);
      font-size: 5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-available-payments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
    width: 100%;

    &-link {
      width: 5rem;
      height: 5rem;

      &>img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }

  &-finalized {
    @apply flex flex-col nice-gap-2rem justify-center items-center bg-white;
    padding: 4rem 1rem;
    border-radius: 3rem;
    position: relative;
    width: 30%;
    max-height: 90%;
    overflow: auto;

    &-container {
      min-height: 50rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 2px;
      &-img {
        height: 50%;

        & img {
          @apply object-contain object-full;
        }

      }

    }

    &-code {
      @apply bg-gray flex flex-row nice-gap-2rem justify-between items-center p-4 border-radius nice-shadow nice-pointer;

      margin-top: 2rem;
      // height:5rem;
      width: 80%;
    }


    @media (max-width: $mediaXS) {
      width: 80%;

    }
  }
}

//the page
.cart {
  &-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 4rem;
    align-items: start;

    &-carts {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;
    }

    &-info {
      height: max-content;
      @apply bg-white box-shadow border-radius p-8;

      &-div {
        @apply flex items-center justify-between py-4;

        &.border-tb {
          margin: 1rem 0;
          padding: 1rem 0;
          border: .25rem solid rgba(0, 0, 0, .07);
          border-left: none;
          border-right: none;
        }
      }
    }
  }

  @media (max-width: $mediaXS) {
    &-grid {
      grid-template-columns: 1fr;
      gap: 4rem
    }
  }
}

.icon-2 {
  width: 2.5rem;
  height: 2.5rem;
}

.product-cart-image {
  max-height: 18rem !important;
}

.cart-payment-icon {
  max-height: 3rem;
  max-width: 6rem;
  min-height: 3rem;
  min-width: 6rem;
}

.cart-copon-module {
  .cart-copon-button {
    position: absolute;
    z-index: 1;
    inset-inline-end: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    // width: 10% !important;

    & button {

      height: 70% !important;
      width: 100% !important;
    }
  }
}