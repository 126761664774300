.vehicle {
  &-offer {
    &-title {
      @apply text-16 font-bold inline-block;
    }
  }

  @media (max-width: $mediaXS) {
    &-offer {
      &-title {
        @apply border-radius-sm bg-primary py-4 px-4 text-white;
      }
    }
  }
  

}