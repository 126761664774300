.borderTop-sm {
  border-top: 1px solid #e5e5e5;

}

.slide-part-container {

  position: relative;
  height: 60rem !important;
  overflow: hidden;
}

.part-input-search {

  background: #f5f5f5;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2rem  !important;

  &.input-field {

    border: 0.2rem var(--primary) solid;
  }
}
.input-field-search-Icon{

  position: absolute;
  top: 0;
  inset-inline-end: 0;
  width: 10%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  & button {

    width: 70%;
    height: 70%;
  }
}
.custom-popover {

  border-top: 1px solid #e5e5e5;

  &-dropdown:before {
    content: "";
    z-index: 0;
    position: absolute;
    right: initial;
    inset-inline-start: 40px;
    top: -8px;
    background-color: var(--white);
    box-shadow: inherit;
    transform: rotate(45deg);
    border: 1px solid rgba(196, 196, 196, .3);
    pointer-events: none;
    width: 24px;
    height: 24px;
  }

  &-dropdownzzxxx:after {
    content: "";
    display: block;
    position: absolute;
    inset: 12px 0 0;
    background-color: var(--white);
    z-index: 0;
    border-radius: inherit;
    pointer-events: none;
  }
}

.part {

  &-img {
    @apply flex justify-center items-center;
    height: 70%;

    overflow: hidden;
    position: relative;
    transition: all .5s;

    &>img {
      height: 98%;
      width: 98%;
      object-fit: cover;
      object-position: center;
      position: relative;
      z-index: 1;

    }

    &-bg {
      position: absolute;
      z-index: 0;
      opacity: .1;
      height: 105%;
      width: 105%;
      top: 2rem;
      left: -45%;
    }
  }


  &-card {
    height: 41rem;
    position: relative;
    background: var(--secondary);

    & .btn-like {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 2;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    }

    &-info {
      //height: 30%;
      padding: 2.5rem 2rem 1.5rem;
      background: var(--white);

      display: flex;

      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;

      &-cart {
        @apply px-6 py-3 border-radius;
        position: absolute;
        top: -2rem;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 1;
      }

      &-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;


      }

    }
  }

  @media (max-width: $mediaXS) {
    &-img {
      height: 25rem;
    }

    &-card {
      height: unset;

      &-info {
        padding: 1rem 1rem 0rem;
        gap: 3rem;

        &-name h3 {
          font-size: 2.1rem;
        }

        & p {
          display: none;
        }

        &-cart {
          position: relative;
          width: 100%;
          left: 0;
          order: 2;
          min-height: unset;
          font-size: 2rem;

          & img {
            height: 3rem;
            width: 3rem;
          }
        }

        & a {
          width: 100%;
        }

        & .part-options {
          text-align: left;

          & h4 {
            font-size: 2.1rem;
          }
        }

      }
    }
  }

}