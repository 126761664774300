.search{
    display: flex;
    flex-direction: column;
    margin: 120px 0 0 0;
    &-header{
        display: flex;
        justify-content: center;

     &-content{
        width:100%;
         &-box{
             &-r{
                border-right: 5px solid var(--primary);
             }
             &-l{
                border-left: 5px solid var(--primary);
             } 
             position: relative;
             border-radius: 1rem;
             height:6rem;
             background-color: var(--white);
             box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
             input{
                 width:100%;
                 height:100%;   
                 border-radius: 1rem;
                 border-style: none;
                 outline-style: none;
                 box-shadow: 0 0 0 transparent;
                 font-size: 2rem;
             }
         }
     }
    }
    &-result{
        min-height:40rem;
        &-item{
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-items: center;
            justify-content:space-between;
            background-color: var(--white);
            width:100%;
            &-right{
                &:after{
                    content: '';
                    position: absolute;
                    top:22%;
                    right:0;
                    width:.3rem;
                    height:30%;
                    transform: translateY(50%);
                    background-color: var(--primary);
                }
            }
            &-left{
                &:after{
                    content: '';
                    position: absolute;
                    top:22%;
                    left:0;
                    width:.3rem;
                    height:30%;
                    transform: translateY(50%);
                    background-color: var(--primary);
                }
            }
            height: 140px;
            box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
            border-radius: 12px;
            &-img-box{
                position: relative;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                background-position: center center;
                height:100%;
                width:25%;
                border-radius: 1rem;
                &-contianer{
                    img{
                        max-width: 70% !important;
                        max-height: 70% !important;
                    }
                }
                background-color: var(--gray-light);
                img{
                    max-height: 4rem;
                    max-width: 4rem;
                }
            }

            &-content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow: hidden;
                width: 75%;
                div.nice-title{
                    max-width:95%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    &-filters{
        min-height: 10rem;
        width:100%;
        background-color: var(--white);
        border-radius: 1rem;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        &-heading{
            img{
                max-width: 3.5rem;
                max-height: 3.5rem;
            }
        }
    }
}
.auto-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    &-r{
    right:10px;
    }
    &-l{
    left:10px;
    }
    top:30%;
    span{
    display: inline-block;
    }
}

@keyframes opacity-animate {
    from{
    filter: blur(5px);
    }
    to{
    filter: blur(1px);
    }
}

@media (max-width:$mediaXS) {
    .auto-type{
        max-width: 90%;
    }
}

.search-btn {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;
    img{
        transition: all .5s ease-out;
        max-height: 1.9rem;
        max-width: 1.9rem;
    }
    &:hover img{
        transform: scale(1.5);
    }
}
.rulerBlock{
    color:#333;
    animation-name: ruler-animate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes ruler-animate{
    from{
     opacity: 1;
    }
    to{
      opacity: 0;
    }
}