﻿.service {
  &-grid {
    display: grid;
    grid-template-columns:repeat(6, 1fr);
    gap: 4rem;

    &-padding {

      & > * {
        padding: 0 4rem;
      }
    }
  }

  @media (max-width: $mediaXS) {

    &-grid {
      grid-template-columns:repeat(2, 1fr);
      gap: 2rem;

      &-padding {
        grid-template-columns:repeat(3, 1fr);
        padding: 0;
        margin: 0 !important;
      }
    }


  }
}