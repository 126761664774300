/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(-100%); }
  to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(-100%); }
  to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
// .text-line-clamp content{

//   /* animation properties */
//  -moz-transform: translateX(-100%);
//  -webkit-transform: translateX(-100%);
//  transform: translateX(-100%);
 
//  -moz-animation: my-animation 15s linear infinite;
//  -webkit-animation: my-animation 15s linear infinite;
//  animation: my-animation 15s linear infinite;
// }
.car {
  &-tags {
    display: flex;
    align-items: center;
   max-width: 50%;
   height: max-content;
    // span:not(:last-child) {
    //   margin-left: .6rem;
    // }
    span {
      margin-left: .6rem;
    }

    .tag {
      padding: .4rem .8rem;
      @apply border-radius-full box-shadow-sm text-12;
      height: max-content;
      // max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:pre;
    }
  }


  &-img {
    // height: 100%;
    // overflow: hidden;
    // position: relative;
    // transition: all .5s;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: 70%;
    position: relative;
    padding-top: 70%;


    &-share {
      position: absolute;
      min-height: 5rem;
      height: 100%;
      top: 0;
      z-index: 1000;
      width: 8rem;
      margin: .5rem 0;
      display: flex;
      transition: all .5s ease-in-out;

      &>button {
        max-height: 6rem;
        max-width: 6rem;
      }

      &-icons {
        height: 100%;
        flex-wrap: wrap;
      }

      &-icons,
      &-toggle {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      &-toggle-btn {
        background-color: rgba(0, 0, 0, .8);
        display: flex;
        width: 4rem;
        height: 4rem;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        z-index: 10000;
      }
    }

    &-discount {
      position: absolute;
      background-color: var(--toyota);
      border-top-right-radius: 1.2rem;
      border-top-left-radius: 1.2rem;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      min-height: 5rem;
      z-index: 100;

      @media (max-width:$mediaXS) {
        & {
          bottom: unset;
          top: 0 !important;
          border-top-left-radius: unset;
          border-top-right-radius: unset;
          border-bottom-right-radius: 1.2rem;
          border-bottom-left-radius: 1.2rem;
        }
      }
    }


    &>img {

      // object-fit: contain;
      // object-position: center;
      // position: relative;
      // z-index: 1;



      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all .5s cubic-bezier(.335,.015,.46,.995);
      &:hover{

        transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-webkit-perspective: 1000;
    -moz-webkit-perspective: 1000;
    -o-webkit-perspective: 1000;
    -ms-webkit-perspective: 1000;
    perspective: 1000;
      }
    }

    &-bg {
      position: absolute;
      z-index: 0;
      opacity: .1;
      height: 105%;
      width: 105%;
      top: 2rem;
      left: -45%;
    }
  }

  &-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 30%;
    &-links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;


      &-showImg{

        color: var(--primary);
        display: flex;
        gap: 1rem;
        cursor: pointer;
        border-radius: 1.5rem;
        padding: 0rem 0.5rem;
        padding-inline-start: 0.3rem;
        padding-inline-end: 1rem;
        align-items: center;
        justify-content: center;
        // background-image: linear-gradient(8deg, #0c0c0c 0%, #000000 100%, #000000 100%);
        position: relative;
        z-index: 2;
        // border: 1px solid black;
        // box-shadow: 2px -1px 0rem 0px rgba(0, 0, 0, 0.55);
        transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);
        &:hover{

          transform: scale(1.05);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-webkit-perspective: 1000;
          -moz-webkit-perspective: 1000;
          -o-webkit-perspective: 1000;
          -ms-webkit-perspective: 1000;
          perspective: 1000;

        }
        &-cover{
         position: absolute;
         width: 100%;
         height: 100%;
         background-image: linear-gradient(8deg, #0c0c0c 0%, #000000 36%, #b9b6b6 100%);
         transform: translateY(-6px);
         z-index: 1;
        }
        &-btn{

          & img {
            color: var(--primary);
            width:4rem;
            height: 4rem;

            &.z-4{

            width:4rem;
            height: 4rem;
            }
            &.z-3{

              width:3rem;
              height: 3rem;
              }
          }

        }
      }
    }

    a {
      cursor: pointer;
      margin: 0 .5rem;
      @apply text-primary inline-block;

      svg {
        fill: var(--primary);
      }
    }

    // a:first-child , a:nth-of-type(2n) {
    //   padding-left: .5rem;
    //   margin-left: .5rem;
    //   border-left: .2rem solid #D4D4D4;
    // }

    a:hover {
      color: var(--primary-dark);

      & img {
        @apply filter-primary;
      }
    }
  }


  &-listcolors{
 
    &-vailable{
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      transition: all 0.3s;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      margin: 0rem 0.5rem;
      border: 1px solid var(--gray-dark);
      & img{

        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        transform: scale(1.5);
      }


    }


  }

  &-card {
    // height: 45rem;
    position: relative;
    background: var(--secondary);
    display: flex;
    flex-direction: column;
    &-active{


      border: 0.5rem var(--primary) solid;
    }
    &-top {

      height: 75%;
      position: relative;
      overflow: hidden;

      &-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        padding: 1rem;
        gap: 2rem;
        z-index: 5;
        align-items: center;

        &-tags {
          display: flex;
          align-items: center;
          span {
            margin-left: .6rem;
          }
      
        
          .tag {
            padding: .4rem .8rem;
            @apply border-radius-full box-shadow-sm text-12;
            height: max-content;
            // max-width: 10rem;
            overflow: hidden;
            text-overflow: ellipsis;
        
        
          
           
          }
        }

        &-showImg {

          cursor: pointer;

          & img {

            transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);

            height: 4rem;
            width: 4rem;

            &:hover {
              transform: scale(1.2);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              -webkit-webkit-perspective: 1000;
              -moz-webkit-perspective: 1000;
              -o-webkit-perspective: 1000;
              -ms-webkit-perspective: 1000;
              perspective: 1000;
          }
          }
        }


      }

      &-color{
        overflow: hidden;
        // max-height: 7rem;
        & .tag{
          padding: 0.4rem 0.8rem;
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          background: var(--gray-light);
        }
      }
    }

    &-type {
      position: absolute;
      top: 0;
      left: 5%;
      width: 90%;
      margin: auto;
      border-top: .4rem solid var(--toyota);

      &.lexus {
        border-top: .4rem solid var(--lexus);
      }

      &-hybird {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: auto;
        margin-top: -1px;
        height: 4.9rem;
        width: 12rem;
        // background:rem var(--info);
        top: 0;
        color: var(--white);
        right: 0;
        @apply border-radius;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      &-online {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: auto;
        margin-top: 0.5rem;
        height: 4.9rem;
        max-width: 20rem;
        top: 0;
        color: var(--white);
        right: 1rem;
        @apply border-radius;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      &-gift {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-top: -1px;
        height: 4.9rem;
        width: 4.9rem;
        background: var(--white);
        top: 0;
        color: var(--danger);
        left: 4.7rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        @apply border-radius;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        img {
          width: 75%;
          height: 75%;
          // animation: blow 1s ease-in 0s infinite alternate;
          object-fit: contain;

          @keyframes blow {
            from {
              transform: scale(1);
            }

            to {
              transform: scale(1.3);
            }
          }
        }

        div.gifts-count {
          position: absolute;
          bottom: .5rem;
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          left: .5rem;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
          background-color: var(--white);
        }
      }

      &-brand {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: auto;
        margin-top: -1px;
        height: 4.9rem;
        width: 4.7rem;
        background: var(--toyota);
        @apply border-radius;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
      }
    }

    &-info {
      height: auto;
      // height: 11rem;
      overflow: hidden;
      padding: 1rem 1rem;
      background: var(--white);

      display: flex;

      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      &-name {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        max-height: 70%;


      }

    }
  }

  &-secondary {
    padding: 0 0.5rem;
    margin: auto 5rem 0;

    .car-img {

      img {


        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

    }


    &-info {
      padding-top: 6rem;

      &-desc,
      &-options {
        opacity: 0;
        overflow: hidden;
        transition: all .5s;
      }
    }
  }

  &-secondary-vehicles {
    padding: 0 1.5rem;
    margin: auto 10rem 0;

    .car-img {

      img {


        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

    }


    &-info {
      padding-top: 6rem;

      &-desc,
      &-options {
        opacity: 0;
        overflow: hidden;
        transition: all .5s;
      }
    }
  }



  @media (max-width: $mediaXS) {
    &-tags {
      display: none;
    }

    &-img {
      // height: 100%;
      // padding-top: 5rem;

      &-bg {
        height: 80%;
        width: 100%;
      }
    }

    &-options {
      // display: block;

      & .options-links {
        margin-top: 1rem;
        text-align: left;
      }
    }

    &-card {
      // background: var(--white);
      // height: 40rem;

      &-type {
        &-brand {
          h4 {
            font-size: 1.2rem;
          }
        }

        &-hybird {
          // left: 0;
          // top: 5rem;
        }
      }

      // &-info {
      //   box-shadow: none;
      //   z-index: 1;
      //   position: absolute;
      //   height: 100%;
      //   top: 0;
      //   width: 100%;
      //   background: transparent;
      // }
    }

  }
}

.offer-car {

  &-img {
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: all .5s;

    &>img {
      height: 80%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      position: relative;
      z-index: 1;

    }

    &-bg {
      position: absolute;
      z-index: 0;
      opacity: .1;
      height: 80%;
      width: 100%;
      top: 2rem;
      left: -45%;
    }
  }


  &-card {
    background: var(--white);
    height: 23rem;

    position: relative;

    &-type {
      position: absolute;
      top: 0;
      left: 5%;
      width: 90%;
      margin: auto;
      border-top: .4rem solid var(--toyota);

      &.lexus {
        border-top: .4rem solid var(--lexus);
      }

      &-brand {
        position: relative;
        z-index: 1;
        display: flex;
        font-size: 1.2rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: auto;
        margin-top: -1px;
        height: 4.9rem;
        width: 4.7rem;
        background: var(--toyota);
        @apply border-radius;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
      }
    }

    &-info {
      padding: 1.5rem 2rem;

      display: flex;

      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;


      box-shadow: none;
      z-index: 1;
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      background: transparent;

      &-name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;


      }

    }
  }


}

.share-btn {
  @apply nice-pointer mx-2;

  &>img {
    max-height: 2.5rem;
    max-width: 2.5rem;
  }
}