.dropdown {
  position: relative;

  // margin-bottom: 15px;
  &-label {
    @apply text-16 font-bold mb-3 inline-block;

    &.secondary {
      @apply text-12 text-gray mb-3;
    }
  }

  &-input {
    width: 100%;
    position: relative;
  }

  &-field {
    @apply border-radius flex justify-between items-center;
    width: 100%;
    padding: .8rem 1.2rem;
    background: var(--white);
    min-height: 6rem;
    user-select: none;
    cursor: pointer;
    position: relative;
    border: .2rem solid var(--input-border);

    &.secondary {
      border: none;
      background: var(--page);
    }

    &-text {
      @apply text-16 text-line-clamp text-line-clamp-1;
    }

    &-arrow {
      padding: 0 .8rem;
      width: 3rem;
      height: 1rem;

      &.invert {
        transform: rotateZ(180deg);
      }
    }
  }


  &-field-extra {
    @apply flex justify-between items-center;
    //width: 95%; //todo:: التكد من عند مراد 
    padding: 3rem 3rem;
    background: var(--white);
    height: 4rem;
    border-radius: 2rem;
    user-select: none;
    cursor: pointer;
    position: relative;
    box-shadow: rgba(0, 0, 0, 13%) 0px 0px 2px 2px;

    &-lable {
      gap: 1rem;
      align-items: center;
      display: flex;
    }

    &-text {
      @apply text-line-clamp text-line-clamp-1;
      font-size: 2.5rem;

    }
  }

  &-field-border-bottom {
    border-bottom: 0.2rem solid var(--gray-mid);
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 1rem 0rem;
    background: none;
    border-radius: 0rem;


  }

  &-options {
    @apply box-shadow border-radius bg-white;
    position: absolute;
    width: 100%;
    right: 0;
    top: 5rem;
    padding: 1rem 0;
    max-height: 32rem;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;

    li {
      padding: .8rem 1rem;
      cursor: pointer;
      transition: all .3s;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & span {
        @apply text-16;

        &.tag {

          padding: 0.4rem 0.8rem;
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
          border-radius: 50rem;
          font-size: 1.3rem;
          margin: 0rem 1rem;
          display: flex;
          line-height: unset;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: max-content;
          align-items: center;
        }
      }

      &:hover {
        background: var(--page);
      }
    }

    & input {

      border: none;
      margin: auto;
      width: 100%;
      margin-bottom: 2rem;
      min-height: 2rem;
      max-height: 4rem;
      height: 4rem;
      font-size: 2rem;
      box-shadow: none;
      border-radius: 0rem;
      border-bottom: 0.3rem solid var(--gray-lighter);
      padding: 1rem 1rem;
      background: transparent;
    }
  }

  @media (max-width: $mediaXS) {
    &-field {
      padding: 1.2rem 1.5rem;
      min-height: 7rem;
    }

    &-options {
      position: fixed !important;
      top: 20% !important;
      right: 5% !important;
      margin: auto 0 !important;
      width: 90% !important;
      min-height: 35% !important;
      border: solid 1px #919191 !important;
      z-index: 9999 !important;
    }
  }
}

.dropdown-Input-module {

  & .dropdown-field {

    position: relative;
    height: 58px !important;
    border-radius: 4px;
    text-indent: 1px;
    box-sizing: border-box;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;

    .dropdown-field-arrow {
      padding: 0 0.8rem;
      width: 4rem;
      height: 1rem;
  }

    & .dropdown-field-text{

      font-size: 16px;
      line-height: 24px;
      color: var(--black);
    }

    @media (max-width: 576px) {
      & {
        height: 50px !important;
        font-size: 16px !important;
      }
    }

  }

  & .dropdown-label {
    inset-inline-start:3rem;
    color: #484848;
    font-size: 14px;
    line-height: 16px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    padding: 3px;
    box-sizing: border-box;
    height: 22px;
    font-weight: bold;
    top: calc(22px / 2* -1);
  }


  & .dropdown-options{
    top: 9rem;
    border: solid 2px #d6dfe9;
    padding: 3rem 1rem;
  }
}