
.why {
  &-container {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
  }

  &-box {
    height: 20rem;
    border: .4rem solid var(--primary);
    position: relative;
    display: flex;
    // align-items: center;
    margin: 0rem 1rem;

    &-img {
      position: absolute;
      width: 8rem;
      height: 8rem;
      background: var(--page);
      right: -4rem;
      top: .6rem;
      padding: .5rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      
      position: relative;
      z-index: 5;
      display: flex;
    flex-direction: column;
    justify-content: center;
        & h3 {
          margin: 0rem 2.5rem;
          padding: 1rem;
        text-align: center;
        font-size: 2.5rem;

        }

        & p{

          margin: 0rem 1rem;
          padding: 1rem;
        text-align: center;
        }
      }
 
    
    &.why-box-second{

      border: none;

      .why-box-img {
        position:initial;
        background: none;
        padding: 0rem;
        width: 10rem;
        height: 10rem;
      }
      .why-box-text{

        margin-inline-start: 5rem;
        margin-right: 0rem;
      }
    }
  }

  @media (max-width: $mediaXS) {
    &-container {
      gap: 2rem;
      margin-top: 3rem;
      grid-template-columns: repeat(2, 1fr);
    }

    &-box {
      justify-content: center;
      margin-right: 0;
      min-height: 10rem;
      height: unset;
      margin: 2rem 0;

      &-img {
        top: -5rem;
        right: 40%;
        // transform: translateX(-50%);
      }

      &-text {
        margin: 0;

        h3 {
          display: block;
          padding: 3rem 2rem 2rem;
          text-align: center;
          font-size: 2rem;
        }
      }

      & p {
        // display: none;
      }

      // &:last-child {
      //   display: none;
      // }

      &.why-box-second{

        .why-box-text{
  
          margin-inline-start: 2rem;
          margin-right: 0rem;
        }
      }

    }
  }


}

.HJService{

  & h2{

    text-transform: uppercase;
  }
  &-section{

    width: 30%;
    margin: 0px 2rem;
    padding: 0px 3rem;
    margin: 0 2rem;

  
      & .img{
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        text-align: center;
        display: inline-block;

  width: 10rem;
  display: flex;
  height: 10rem;
  justify-content: center;
  margin: 2rem 0rem;
  
  & img{
  
    max-width: 100%;
    background: transparent;
      border: 0 none;
      font-size: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: top;
  }
}

  &-titel{

    padding: 2rem 0 5rem;
    margin-bottom: 5rem;
    width: 100%;
    color: #cdc9cb;
    font-size: 20px;
    border-bottom: 1px solid #2d9184;
  }

  &-desc{

    margin: 2rem 0rem;
    font-size: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: top;
    line-height:4rem
  }
  & a{
    margin: 2rem 0rem;
    color: #cdc9cb;
    font-size: 20px;
    text-transform: uppercase;
  }
  
      }


      @media (max-width: $mediaXS) {

        &-section{

          width: 100%;
        }

      }
    }