.vehicle {

  &-brand {
    @apply flex justify-between items-center;

    &-name {
      @apply font-bold text-40;
      text-transform: uppercase;
    }

    &-search {
      @apply flex flex-col items-end;
    }
  }

  &-filter-group {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 1.2rem;
  }

  @media (max-width: $mediaXS) {

    &-brand {
      flex-direction: column;

      &-search {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 4rem;

        & h3 {
          font-size: 1.9rem;
        }
      }
    }
    &-gap {
      gap: 2.5rem !important;
    }
    &-filter-group {
      flex-wrap: wrap;
      gap: 2rem;

      &.start {
        justify-content: flex-start;
      }
    }
  }
}