.filter {
  &-btns{
    display: flex;
    gap: 1.5rem;
  }
  &-button {
    @apply py-2 px-6 inline-block border-radius-full bg-secondary;
    text-align: center;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      @apply bg-white box-shadow-sm;
    }
  }
}