.box {
  @apply border-radius overflow-hidden box-shadow;
  position: relative;
  z-index: 1;
  padding: 4rem;
  display: flex;
    padding: 4rem;
    flex-direction: column;
    justify-content: space-between;
  &-h-18 {
    height: 18rem;
  }

  &:before{

    position: absolute;
    width: 100%;
    content: '';
    height: 100%;
    inset: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 46.19%, #000 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(26, 26, 26, .98)), color-stop(90%, rgba(26, 26, 26, 0)));
    background: -o-linear-gradient(left, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    background: linear-gradient(90deg, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    -webkit-transition: opacity  0.5s linear;
    -o-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
    z-index: 2;
  }
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 46.19%, #000 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(26, 26, 26, .98)), color-stop(90%, rgba(26, 26, 26, 0)));
    background: -o-linear-gradient(left, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    background: linear-gradient(90deg, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    -webkit-transition: opacity  0.5s linear;
    -o-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
    z-index: 2;
    opacity: 0;
  

    &-top {
      background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 46.19%, #000 100%);

    }
  }

  &:hover &-overlay{
    opacity: 1;
    background: -webkit-gradient(linear, left top, right top, color-stop(35%, rgba(26, 26, 26, .98)), color-stop(90%, rgba(26, 26, 26, 0)));
    background: -o-linear-gradient(left, rgba(26, 26, 26, .98) 35%, rgba(26, 26, 26, 0) 90%);
   background: linear-gradient(33deg, rgba(26, 26, 26, .98) 30%, rgba(26, 26, 26, 0) 90%);
  }

  // &:hover::before{
  //   opacity: 0;
  // }



  &-img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &-text {
    position: relative;
    // top: 30%;
    // right: 2rem;
    z-index: 3;

    & > * {
      color: white;
    }
  }

  &-btn {
    position: relative;
    z-index: 3;
    // bottom: 1rem;
    // left: 1rem;
    width: fit-content;
  }

  //sizes
  &-small {
    width: 40rem;
    height: 22rem;
  }

  &-mid {
    height: 30rem;
    
  }

  //types
  &-about {
    display: flex;
    padding: 2rem;
    align-items: flex-start;

    gap: 2rem;
    background: var(--white);

    &-icon {
      @apply border-radius-full flex justify-center items-center;
      padding: 1.2rem;
      width: 6rem;
      height: 6rem;
      background: var(--page);

    }

    &-text {
      width: 90%
    }

  }


  @media (max-width: $mediaXS) {
    &-h-18 {
      height: 24rem;
    }

    &-service {
      height: 22rem;

      & .btn {
        min-height: unset;
      }
    }
    &-small {
      width: unset;

      & .btn {
        min-height: unset;
      }
    }

    &-mid {
      height: 30rem;
  }
    &-about {
      flex-direction: column;
      gap: 2.5rem;
      padding: 1rem;
      align-items: center;
      justify-content: center;

      &-icon {
        height: 11rem;
        width: 11rem;
      }

      &-text {
        & h3 {
          text-align: center;
        }

        & p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
          text-align: center;
        }
      }
    }
  }
}



.language-ar .box {
  @apply border-radius overflow-hidden box-shadow;
  position: relative;
  z-index: 1;
  padding: 4rem;
  display: flex;
    padding: 4rem;
    flex-direction: column;
    justify-content: space-between;
  &-h-18 {
    height: 18rem;
  }

  &:before{

    position: absolute;
    width: 100%;
    content: '';
    height: 100%;
    inset: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 46.19%, #000 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(26, 26, 26, .98)), color-stop(90%, rgba(26, 26, 26, 0)));
    background: -o-linear-gradient(left, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    background: linear-gradient(270deg, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    -webkit-transition: opacity  0.5s linear;
    -o-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
    z-index: 2;

  }
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 46.19%, #000 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(26, 26, 26, .98)), color-stop(90%, rgba(26, 26, 26, 0)));
    background: -o-linear-gradient(left, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    background: linear-gradient(270deg, rgba(26, 26, 26, .98) 25%, rgba(26, 26, 26, 0) 90%);
    -webkit-transition: opacity  0.5s linear;
    -o-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
    z-index: 2;
    opacity: 0;
  

    &-top {
      background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 46.19%, #000 100%);

    }
  }

  &:hover &-overlay{
    opacity: 1;
    background: -webkit-gradient(linear, left top, right top, color-stop(35%, rgba(26, 26, 26, .98)), color-stop(90%, rgba(26, 26, 26, 0)));
    background: -o-linear-gradient(left, rgba(26, 26, 26, .98) 35%, rgba(26, 26, 26, 0) 90%);
   background: linear-gradient(268deg, rgba(26, 26, 26, .98) 30%, rgba(26, 26, 26, 0) 90%);
  }

}