﻿$border: solid .1rem var(--gray);
.compare {
  &-table {

    & .row {
      display: grid;
      grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr;

      border-top: $border;
      background-color: var(--white);

      &:last-child {
        border-bottom: $border;
      }

      &:nth-child(2n) {
        background-color: var(--gray-lighter);
      }

      &-key {
        font-weight: bold;
      }

      & div:not(:first-child) {
        padding: 1.2rem .6rem;
      }

      & div:first-child {
        padding: 0 .6rem;
      }

      &-center {
        @apply text-16;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: $border;

        &:last-child {
          border-left: $border;
        }

      }
    }
  }

  &-car {
    width: 100%;

    & .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .name {
        @apply text-16 font-bold text-line-clamp text-line-clamp-1;
      }

      & .options {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;

        & img {
          width: 3rem;
          height: 3rem;
        }
      }
    }

    & .img {
      height: 14rem;
    }
  }

  &-model {
    @apply flex flex-col justify-evenly items-center bg-secondary border-radius p-16;
    background-color: var(--white);
    gap: 1rem;
    width: 40%;
    @media (max-width:$mediaXS) {
      &{
        width:80% !important;
      }
    }
  }
}