
  
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  

  
  
  .z-pag-header{
    display: flex;
    flex-direction: row;
    margin: 0;
    height: 50px;
    margin-bottom: 40px;

    align-items: center;
  }

  .z-upper-part-n{
    background-color: #F7F8F7;
    padding-top: 6rem;
    padding-bottom: 6rem;
    
  }

  .z-margin-box{
    display: flex;
    flex-direction: row;
    height: 25rem;

    gap: 5rem;

  }

  .left-button{
    flex: 1;
    background-color: white;
    gap: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #090d0b0a;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    &-active{


        border: 0.2rem solid var(--primary);

    }
    
  }

  
  .button-icon{
    flex: 1;
    max-height: 70%;
    margin: 0 auto;
  
  }

  .button-text{
    flex: 1;
    font-size: 9px;
    font-weight: bold;
    max-height: 30%;
    margin: 0 auto;

  }

  .nav-bar{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    height: 43px;
    
  }

  .choice{
    flex: 1;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #090d0b1a;
    text-align: center;
    margin-right: 4%;
    margin-left: 4%;
   
    &-active{

      border: 1.2px solid #3BD461;
    }
    
  }
  

  .choice-text{
    font-size: 13px;
    font-weight: bold;
    margin-top: 7%;
  }


  .z-details-box-m {
    background-color: #c6c6c680;
    border-radius: 10px;
    box-shadow: 0px 0px 15px #090d0b1a;
    align-items: center;
    display: flex;
    flex-direction: row;
 }

 .z-right-side-imageBox{
  flex: 0.5;

 }

 .z-boxImage-s{
  width: 80%;
  margin:auto;
 }

 .z-left-side-details-m{
  flex: 3;
  height: 100%;
 }

 .z-middle-lineM{
  flex: 0.01;
  background-color:#AFAFAF;
  height: 80px;
  width: 1px;
  margin-right: 8px;
 }
 
 .z-text {
    font-size: 12px;
    line-height: 12px;
 
 }

 
 .green{
    font-weight: bold;
    font-size: 15px;

    line-height: 20px;
 }
 .rial{
  font-size: 10px;

  font-weight: bold;
 }
 .z-gray-text{
    font-size: 12px;
 }

 
 .line{
    background-color:#AFAFAF;
    height: 80px;
    width: 1px;
    position: absolute;
    bottom:  13px;
    right: 90px;
 
 }
 
 .img1{
    margin: 20px 14px;
    border-radius: 10px;
    height: 55%;
    object-fit: fill;
    position: absolute;
    width: 15%;
    margin-right: 5%;
    margin-top: 7%;

 }

 .z-size-box{
  border: 14px solid #FAFAFA;

 }

 
 .z-size-box2{
  border: 11px solid #FAFAFA;

 }


 .z-details-box-m-white {
  background-color: #ffffff80;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #090d0b1a;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.z-request-button{
  height: 10rem;
    margin: 0 auto;
    background-color: var(--white);
    border: none;
    width: 80%;
    color: black;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.151);
  }