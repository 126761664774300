
.testimonial {
  &-card {
    border-left: .3rem solid var(--page-dark);

    height: 44rem;
    overflow: hidden;
    padding: 0 2.5rem;

    &-img {
      height: 40%;
      overflow: hidden;

      img {
        margin: auto;

        height: 14rem;
        width: 14rem;
        object-fit: cover;
        object-position: center;
      }
    }

    &-text {
      height: 60%;
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem 1.5rem;

      h4 {
        margin-top: auto;
      }
    }
  }

  @media (max-width: $mediaXS) {

    &-card {
      height: unset;
      padding: 0 .5rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      &-text {

        & *:first-child {
          font-size: 1.9rem;
          line-height: 2;
          margin-bottom: 4rem;
        }

        & h4 {
          font-size: 2.1rem;
        }
      }
    }
  }
}