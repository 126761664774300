$navIconWidth: 22rem;

.nav {
  background: black;
  transition: top 0.5s;
  position: sticky;
  z-index: 6;

  &-first {
    height: 100%;
    padding-right: 2rem;
  }

  &-heading {
    @apply text-20 font-bold;
    margin-bottom: 1.5rem;
  }

  &-height {
    height: 8rem;
  }

  &-icon {
    //width: $navIconWidth;
    height: 80%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      width: 23rem;
    }
  }

  &-links {
    & a {
      transition: all 0.3s;
      color: white;
    }
    .onlinelinke {
      position: relative;
      margin: 0rem 1rem;
      a {
        position: relative;
        z-index: 100;
        background-color: black;
      }
      &-heading-box {
        width: 5rem;
        position: absolute;
        top: -1rem;
        height: 5rem;
        z-index: 1;
        border-radius: 0.5rem;
        border: 0.6rem solid var(--primary);

        &.language-ar {
          right: -1rem;
        }

        &.language-en {
          left: -1rem;
        }
      }
    }

    & .active,
    a:hover {
      color: var(--primary-light);
    }
  }

  &-btns {
    a {
      height: 4.5rem;
      width: 4.5rem;

  

      img {
        height: 100%;
        width: 100%;

        @apply filter-primary-hover;
      }
    }


    .btn-cart{

     .cart-notification.hidden{
      opacity: -1 !important;
      top: -75rem;

     }
     .cart-notification.show{
      opacity: 5 !important;

     }



    }
  }

  &-lang {
    img {
      height: $size4;
      width: $size4;
    }

    &:hover {
      background: var(--primary-dark);
    }
  }

  &-fixed {
    @apply box-shadow-large;
    position: fixed !important;
    width: 97.5%;
    z-index: 23;
  }

  &-mega {
    display: inline-block;
    position: absolute;
    top: calc(100% - 1rem);
    right: 0;
    width: 100%;
    z-index: 20;

    &-menu {
      height: 100%;
    }
  }

  &-grid {
    &-3-cols {
      display: grid;
      grid-template-columns: 1fr 0.3fr 0.7fr;
      gap: 2rem;
    }

    &-middle {
      padding-right: 1rem;
      border-left: 0.2rem solid var(--secondary);
      border-right: 0.2rem solid var(--secondary);
    }

    &-contact {
      grid-template-columns: 1fr 0.8fr 0.8fr;
    }
  }

  &-show-sm {
    display: none;
    position: absolute;
  }

  @media (max-width: $mediaXS) {
    &-first {
      padding-right: 0;
    }
    &-height {
      height: 10rem;
    }
    &-icon {
      @apply border-radius p-3;
      background: black;
    }
    &-active {
      @apply border-radius py-2;
      background: black !important;
    }
    &-fixed {
      @apply py-3;
      background: black !important;
      width: 100%;
      left: 0;
    }
    &-default {
      @apply py-3 box-shadow-large;
      position: fixed !important;
      width: 100%;
      left: 0;
      border-radius: unset !important;
      display: flex;
      justify-content: space-between;
    }

    &-hide-sm {
      display: none !important;
    }

    &-btns {
      gap: 2rem;
      padding-right: 1rem;
      padding-top: 0.75rem;
      padding-left: 2rem;
      padding-bottom: 0.75rem;
      & > a:first-child {
        width: 4.5rem;
        height: 4.5rem;
      }

      // & > a:not(:first-child) {
      //   display: none;
      // }
    }
    &-links {
      &.active {
        display: flex;
      }

      @apply box-shadow;
      display: none;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      position: absolute;
      top: calc(100% + 0rem);
      left: 0;
      width: 100%;
      background: black;
      padding: 2rem 3rem;
      flex-direction: column;
      gap: 2rem;
      z-index: 10;
      margin: 0rem;
      & > div {
        display: block;
        margin: 0 !important;
        position: relative;

        & > a {
          @apply text-24;
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            height: 4rem;
          }
        }
      }
    }
    &-mega,
    &-lang {
      display: none;
    }
  }
}

.nav-box {
  @apply border-radius overflow-hidden box-shadow;
  position: relative;
  width: 100%;
  height: 100%;
  &-part {
    &-date {
      @apply text-20 border-radius;
      background: var(--toyota);
      color: white;
      padding: 0.5rem 1rem;
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 2;
    }

    &-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 40%;

      z-index: 2;

      & h2 {
        color: white;
        padding: 1rem;
      }

      & p {
        color: white;
        padding: 1rem;
      }
    }
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.56) 46.19%,
      #000 100%
    );
    z-index: 1;
  }

  &-img {
    width: 100%;
    height: 100%;
  }

  &-date {
    position: absolute;
    border-radius: 0 0 1rem 1rem;
    background: var(--primary);
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 1.5rem 1.2rem;
    top: 0;
    right: 1rem;
    z-index: 2;

    & > * {
      color: white;
    }

    & div:last-child {
      line-height: 0.1rem;
    }
  }

  &-text {
    text-align: center;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;
    & > * {
      color: white;
    }
  }
}

.nav-about-container {
  @apply text-20 bg-white;
  position: relative;
  z-index: 1;

  &-box {
    @apply border-radius;
    position: absolute;
    height: 5rem;
    width: 5rem;
    border: 0.55rem solid var(--primary-dark);
    top: -1.5rem;
    right: 0;
    z-index: -1;
  }

  &-text {
    @apply bg-white mr-8;
    margin-top: 3rem;
    line-height: 2;
  }
}

@media (max-width: $mediaXS) {
  .header-absolute {
    position: absolute !important;
  }
}

.cart-notification{
  opacity: 1;
  position: absolute;
  top: 6rem;
  transition: opacity 0.25s ease-in-out;
  z-index: 99;
  min-width: 40rem;
  left: 0rem;
  display:inline-block ;
  background-color: white;
  

  &::before{


    content: "";
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 14px 11px;
    border-color: transparent transparent white transparent;
    left: 2.75px;
  }

  &.language-en{

    left: auto;
    right: 0rem;

    &::before{
      left: auto;
      right: 2.75px;
    }

  }

  &-products{
    overflow: auto;
    max-height: 25rem;
  }


}
