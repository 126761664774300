.error {
  &-page {
    display: grid;
    grid-template-columns: .9fr 1.1fr;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  &-heading {
    @apply font-bold;
    font-size: 8rem;
  }

  &-code {
    @apply font-bold;
    font-size: 10rem;
    margin: 1rem;
    color: var(--primary);
    line-height: 1;
  }

  &-message {
    @apply text-20;
    color: var(--gray-dark);
  }

  @media (max-width: $mediaXS) {
    &-page {
      margin-top: 16rem;
      grid-template-columns: 1fr;
    }
  }
}

$danger-color : #FF2626;
$font-size :2rem;
$font-color:#fff;

@mixin warning-alert-block {
  display:block;
  min-height: 100px;
  padding: 8px;
  border-radius: 10px;
  color: $font-color;  
  font-size: $font-size;
}

@mixin warning-alert-inline {
    display: inline-block;
    color: $danger-color;
    font-size: $font-size;
    margin:0 8px;
}


.warning-block{
    @include warning-alert-block;
}

.warning-inline{
    @include warning-alert-inline;
}