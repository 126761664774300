
.text {
  &-nowrap {
    white-space: nowrap;
  }

  //font size and line height of the text
  @each $header, $size in (10:1rem, 12: 1.3rem, 14: 1.5rem, 16: 1.7rem, 20: 2.1rem, 24: 2.5rem, 30: 3.1rem, 40:4.1rem,50:5.1rem) {
    &-#{$header} {
      font-size: $size;
      //line-height: $size;

    }
    &-#{$header}-children > * {
      font-size: $size;
      //line-height: $size;

    }
    &-height-#{$header} {
      line-height: ($size * 1.9);
    }

  }
  @media (max-width: $mediaXS) {

    //font size and line height of the text
    @each $header, $size in (10:1.1rem, 12: 2rem, 14: 1.7rem, 16: 2.5rem, 20: 2.5rem, 24: 2.6rem, 30: 3.2rem, 40:4.2rem) {
      &-#{$header} {
        font-size: $size;
        //line-height: $size;

      }
      &-height-#{$header} {
        line-height: ($size * 1.9);
      }

    }

    @each $header, $size in (10:1rem, 12: 1.3rem, 14: 1.5rem, 16: 1.7rem, 20: 2.1rem, 24: 2.5rem, 30: 3.1rem, 40:4.1rem,50:5.1rem) {
      &-#{$header}-importent {
        font-size: $size;
        //line-height: $size;
  
      }
    }
  }

  //text colors
  &-black {
    color: var(--black);

    &-50 {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &-black {
    color: var(--black);
  }

  &-gray {
    color: var(--gray);

    &-dark {
      color: var(--gray-dark);
    }
  }


  &-primary {
    color: var(--primary);

    &-dark {
      color: var(--primary-dark);
    }
  }

  &-secondary {
    color: var(--secondary);
  }
  &-info {
    color: var(--info);
  }

  &-toyota {
    color: var(--toyota);
  }

  &-danger{
    color: var(--toyota);
  }
  &-error{
    color: var(--error);
  }
  &-line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @each $size in (1, 2, 3, 4) {
      &-#{$size} {
        -webkit-line-clamp: $size;
      }
    }

    @each $size in (10, 20, 30, 40) {
      &-ch-#{$size} {
        max-width: $size+ch;
      }
    }
  }


  &-line-max-width{
    max-width: 25rem;
  }

}


.long-and-truncated {
  flex: 1;

  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

