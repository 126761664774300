.nav {
  &-maintenance {

    &-container {
      display: flex;
      align-items: stretch;
      gap: 1rem;
      height: 26rem;

      & > & {
        flex: 1 1;
      }

    }

    &-links {
      & a {
        @apply text-20 text-line-clamp text-line-clamp-1;
        color: var(--gray-dark);
        margin: .8rem 1rem;

        &:hover {
          color: var(--primary-dark);
        }
      }

      & .show-more a {
        color: var(--primary);

        &:hover {
          color: var(--primary-dark);
        }
      }
    }

  }
}
