.alert {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  &-box {
    display: inline-block;
    animation: animate-down .5s ;
    position: relative;
    height: max-content;
    // height: 35rem;
    border-radius: 8px;
    // background-size: 35rem;
    // background-position: 0px;
    // background-repeat: no-repeat;
    // border-bottom: 5px solid var(--primary);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 100rem;
    border-top-width: 5px;
    border-top-style: solid;
    background-color: var(--white);
    padding: 2rem 0rem;
    @media (max-width: $mediaXS) {
      width: 100%;
      padding: 2rem;
    }
    &-header{
      display: flex;
      flex-direction: column;
      justify-content: center;
      img{
        max-height:30rem;
        max-width:30rem;
      }
      &-show{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 5px;
        &:not(&:last-of-type){
          border-bottom: 1px solid var(--gray-light);
        }
    }
    }
    &-brand {
      z-index: -1;
      &-border {
        &-lexus {
          border-top-color: var(--lexus);
        }

        &-toyota {
          border-top-color: var(--toyota);
        }
      }
      &-img {
       display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        border-radius: 1rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 5.7rem;
        height: 5.9rem;
        top:0;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;     
           border: 1px solid var(--grey);
        &-lexus {
          background-color:  var(--lexus);
        }

        &-toyota {
            background-color:  var(--toyota);
        }
        &-r{
          right:0;
        }
        &-l{
          left:0;
        }
      }
    }
  }
  &-tags{
    display: flex;
    flex-direction: row;
    gap:5px;
    &-tag{
      width: 8rem;
      height:4rem;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-model{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    &-item{
      flex: 1;
    }
  }
}


.alert-btns-container{
  position:absolute;
  min-height: 8rem;
  background-color: var(--white);
}

.alert-btns{
  display: flex;
  position: absolute;
  bottom:2rem;
  gap:5px;
  & > button{
    min-width: 14rem;
  }
}

.fix-bg{
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 35rem;
}

.car-name{
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 15px;
}


.car-background{
  background-color: rgba(0,0,0,.1);
  background-position:center;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes animate-down{
  from{
    transform:translateY(-500px);
  }
  to{
    transform:translateY(0);
  }
}

.nice-row-center{
align-items: center;
}

.nice-center{
  justify-content: center;
  align-items: center;
}

