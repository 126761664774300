.car {
  &-overview {
    &-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
    }

    &-filter {

      height: 5rem;
    }

    &-colorimage {

      height: 30rem;
      width: 50%;

      @media (max-width: $mediaXS) {
        width: 90%;
      }

      & img {
        transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);

        &:hover {
          transform: scale(1.2);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-webkit-perspective: 1000;
          -moz-webkit-perspective: 1000;
          -o-webkit-perspective: 1000;
          -ms-webkit-perspective: 1000;
          perspective: 1000;
          cursor: pointer;
        }
      }

    }

    &-colorname {

      height: 2rem;

    }
  }

  &-model {

    &-img {
      height: 55%;
      overflow: hidden;
      position: relative;
      transition: all .5s;

      &>img {
        height: 65%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        position: relative;
        z-index: 1;
        transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);

        &:hover {
          transform: scale(1.2);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-webkit-perspective: 1000;
          -moz-webkit-perspective: 1000;
          -o-webkit-perspective: 1000;
          -ms-webkit-perspective: 1000;
          perspective: 1000;
          cursor: pointer;
        }

      }

      &-bg {
        position: absolute;
        z-index: 0;
        opacity: .1;
        height: 105%;
        width: 105%;
        top: 2rem;
        left: -45%;
      }
    }

    &-offers {
      position: absolute;
      top: 1.3rem;
      left: 1.3rem;
      background: var(--toyota);
      color: white;
      padding: .5rem 1.3rem;
      border-radius: 100rem;
      font-weight: bold;
    }

    &-name {
      height: 35%;
      padding: 0 1.5rem;
      margin-top: .5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-card {
      overflow: hidden;
      height: 60rem;
      position: relative;
      background: var(--white);
      border-bottom: .5rem solid var(--primary);


      &-info {
        height: 45%;
        padding: 2.5rem 3rem;
        background: var(--white);
      }
    }
  }

  &-model-swiper {
    & .swiper-slide {
      max-width: 44rem !important;
      margin: 0 1.5rem;
    }
  }


  // car page
  &-navbar {
    @apply box-shadow border-radius -mt-20;

    height: 6.5rem;
    transition: all .3s;
    position: relative;
    z-index: 2;


    &-fixed {
      transition: all .3s;

      position: fixed;
      top: 1rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 86%;
    }

    &-links {
      @apply flex items-center;
      gap: 1rem;
      height: 100%;

      .name {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .7;

        padding: 0 2rem;
        white-space: pre;
        text-transform: capitalize !important;
      }

      .name.language-en {

        border-right: .1rem solid var(--gray);

      }

      .name.language-ar {

        border-left: .1rem solid var(--gray);

      }

      &-logo {
        margin: 0 2rem;
        max-width: 30rem;
        display: flex;
        height: 5rem;
        position: absolute;
        left: 0;
      }

      &-group {
        display: flex;
        justify-content: center;
        align-items: center;

        &>a {
          @apply text-16 text-black;
          font-weight: lighter;
          display: inline-block;
          padding: 0 1.3rem;
          text-transform: capitalize !important;

          &:hover {
            @apply text-primary;
          }

          &.active {

            color: var(--primary);
            font-weight: bold;
            font-size: 2rem;
          }



        }
      }

      &-group.language-ar {

        &>a {
          &:not(:last-child) {
            border-left: .1rem solid var(--gray);
          }
        }

      }

      &-group.language-en {

        &>a {
          &:not(:last-child) {
            border-right: .1rem solid var(--gray);
          }
        }

      }

      button {
        @apply bg-primary text-16 font-bold text-white border-radius;
        padding: .8rem 3rem;
        height: 100%;
        margin-right: auto;
      }
    }

  }




  &-heading {
    @apply w-full flex items-start;
    flex-direction: column;
    gap: 1rem;

    &-name {
      h1 {
        @apply text-40 font-bold mb-6;
      }
    }

    &-desc {
      @apply text-20 flex-grow-1;
      max-width: 100%;
      margin: 0rem 2rem;
      position: relative;
      white-space: break-spaces;

      &:after {
        content: "";
        position: absolute;
        width: 30%;
        height: 0.5rem;
        background: var(--primary);
        top: -21px;
        position: static;
        display: block;
        margin-top: 0.5rem;
      }
    }

    &-menu {
      @apply mr-auto;

      button {
        @apply text-16;
        padding: 1rem 2rem;
      }
    }
  }

  @media (max-width: $mediaXS) {
    &-navbar {
      margin-top: -8rem;
      height: 8rem;

      &-fixed {
        margin-top: 0;
        width: 94%;
      }

      &-links {
        // padding-right: 3rem;
        // justify-content: space-between;
        position: relative;

        & .name {
          // border: none;
        }

        &-logo {
          display: none;
          max-width: 25rem;
        }

        &-group {
          @apply border-radius;
          display: flex;
          // top: 0;
          // right: 0;
          // padding: 3rem 2rem;
          // position: absolute;
          // flex-direction: column;
          // align-items: flex-start;
          gap: 1rem;
          background: var(--white);
          width: 100%;
          opacity: 1;
          transition: all .3s;

          &>a {
            // border: none !important;
            width: 100%;
            font-size: 2rem;
            white-space: pre;
          }



          &.active {
            display: flex;
            opacity: 1;
            top: 9rem;
            transition: all .3s;
          }
        }

        & .btn {
          min-height: unset;
          margin: 0;
        }
      }
    }

    &-model {
      &-card {
        & .options-price>* {
          font-size: 2.1rem !important;
        }
      }

      &-name {
        margin-top: 0.1rem;

        & h3 {
          font-size: 2.3rem;
        }


      }

      &-options {
        & li {
          font-size: 1.9rem;
        }
      }
    }

    &-heading {
      flex-direction: column;
      gap: 1rem;

      &-desc {
        max-width: 100%;
      }

      &-menu button {
        min-height: unset;
      }
    }
  }
}

.feature {
  @apply border-radius flex flex-col;
  border: .1rem solid rgba(0, 0, 0, .2);
  height: 65rem;

  &-img {
    @apply border-radius overflow-hidden box-shadow-large;
    height: 70%;
    order: 1;


    & img {
      transition: all 0.5s cubic-bezier(0.335, 0.015, 0.46, 0.995);

      &:hover {
        transform: scale(1.2);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-webkit-perspective: 1000;
        -moz-webkit-perspective: 1000;
        -o-webkit-perspective: 1000;
        -ms-webkit-perspective: 1000;
        perspective: 1000;
        cursor: pointer;
      }
    }

  }

  &-text {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
  }

  &:nth-child(2) {
    .feature-img {
      order: 2;
    }

    .feature-text {
      order: 1;
    }
  }

  @media (max-width: $mediaXS) {
    border: none;

    &-img {
      order: 1 !important;
    }

    &-grid {
      grid-template-columns: 1fr;
      gap: 3rem;

      &>div:last-child {
        display: none;
      }
    }
  }
}


.simple-gallery {
  display: grid;
  grid-template-columns: 1.3fr 1fr 0.7fr;
  gap: 1rem;
  height: 40rem;

  &-item {

    position: relative;
    display: flex;
    @apply border-radius overflow-hidden;
    height: 100%;

    img {
      @apply border-radius overflow-hidden;
      height: 100%;
      object-fit: cover;

    }

    &:hover .simple-gallery-item-ovrlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: rgba(0, 0, 0, .51);
      z-index: 1;
      top: 0;
      padding: 10px;
      text-align: right;
      cursor: pointer;
      display: block;


      & svg {


        width: 5rem;
        height: 5rem;

        & path {
          color: white;
        }
      }

    }

    &-ovrlay {

      display: none;
    }


  }


}

.slide-show {
  display: flex;
  max-height: 30rem;
  justify-content: center;
}

.language-en .car-navbar-links-logo {

  right: 0;
  left: auto;
}