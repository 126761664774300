.contact {
  &-list {
    @apply border-radius;
    background: var(--secondary-lighter);
    overflow: hidden;

    &-name {
      @apply flex items-center justify-between border-radius py-6 px-10 ;
      background: var(--secondary-light);
      cursor: pointer;
    }

    &-info {
      background: var(--secondary-lighter);
      overflow: hidden;
      transition: all .3s;
    }
  }


  &-tile {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    &-icon {
      @apply border-radius-full box-shadow p-3 bg-white;
      height: 4rem;
      width: 4rem;
    }

    &-key {
      @apply text-12 text-gray;
    }

    &-value {
      @apply text-16 font-bold;
    }
  }
}

.contact {
  &-filter {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}