.finance {
  &-search {
    @apply flex justify-center items-center;
    gap: 2rem;
   
    &-bank {
      @apply bg-white box-shadow border-radius px-8;
      height: 8rem;
      border: .3rem solid transparent;
      cursor: pointer;
      transition: all .3s;
      height: 17rem;
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      &:hover {
        @apply box-shadow-sm;
        transform: scale(.97);
      }

      & picture{


        width: 100%;
        height: 70%;

      }
      &.active {

        border: .3rem solid var(--primary);
      }
    }
  }

  @media (max-width: $mediaXS) {
    &-search {
      &-bank {
        // width: 10rem;
        // height: 10rem;
        // padding: 0;
        height: 17rem;
      }
    }
  }
}

.range {
  @apply  items-center w-full;
  position: relative;

  &-suffix {
    @apply flex items-center gap-1;

    & .input {
      @apply w-40;
      margin-bottom: 0rem;
    }

    & .input-field {
      padding: 1rem;
    }
  }
  &-control{

   
  }
  @media (max-width: $mediaXS) {
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: stretch;
    gap: 4rem;
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 4rem;
    &-control{

      order:3;
      grid-column:1/3;
    }
    &-suffix {
      // position: absolute;
      // top: 0;
      // left: 0;
      order:2;

      & .input {
        @apply w-60;
      }
    }
  }
}