$bannerHeight: 29rem;
$bannerMapHeight: 58rem;

$secondImageWidth: 70rem;
$secondImageHeight: 44rem;

$infoTextWidth: 78rem;


.banner {


  &-container {
    position: relative;
    height: $bannerHeight;
    overflow: hidden;

  }

  &-container-map {
    position: relative;
    height: $bannerMapHeight;
    overflow: hidden;

    &-list {
      height: 95%;
      width: 30%;

      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);


      overflow-y: auto;

      & .contact-list:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &-overlay {
    position: absolute;
    inset: 0;
    height: 100%;
    background: linear-gradient(to left, #090d0b 0%, rgba(26, 28, 27, 0.76) 51.45%, rgba(42, 42, 42, 0.13) 100%);
    z-index: 1;
  }

  &-bg {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &-info {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-img {
      position: relative;
      z-index: 1;
      overflow: hidden;
      height: $secondImageHeight;
      img {
        object-fit: cover;
        object-position: center;
      }
    }

 

    &-flex {
      margin-right: 9.5rem;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      &-subtitle {
        z-index: 1;
        margin-bottom: 4rem;
        & * {
          color: white;
        }

      }

      &-text {

        & * {
          color: white;
        }

        position: relative;
        z-index: 1;
        width: $infoTextWidth;

        h1 {
          font-weight: bold;
          margin-bottom: 2rem;

        }

        p {
          @apply text-line-clamp text-line-clamp-3;
        }

      }

    }


  }



  &-btn {
    @apply text-16 btn btn-primary py-4 px-20 mt-2;
    margin-top: 2rem;
    border-radius: 100rem !important;
    z-index: 1;
  }

  &-date {
    display: flex;
    gap: .5rem;

    & * {
      @apply text-24;
    }
  }

  //simple banner
  &-simple {
    @apply border-radius;
    position: relative;
    height: 24rem;
    display: flex;
    padding: 0rem 5rem;
    &-bg {
      @apply border-radius overflow-hidden;
      // position: relative;
    }

    .language-en  &-overlay  {
      position: absolute;
      inset: 0;
      height: 100%;
      background: linear-gradient(to right, #090d0b 0%, rgba(26, 28, 27, 0.76) 51.45%, rgb(0 137 41 / 49%) 100%);
      z-index: 1;
    }

    .language-ar  &-overlay  {
      position: absolute;
      inset: 0;
      height: 100%;
      background: linear-gradient(to left, #090d0b 0%, rgba(26, 28, 27, 0.76) 51.45%, rgb(0 137 41 / 49%) 100%);
      z-index: 1;
    }

    &-img {
      position: relative;
      height: 130%;
      max-width: 50rem;
      top: 50%;
      transform: translateY(-50%);
      right: 5rem;
      z-index: 1;
      width: 20%;
    }

    &-text {
      position: relative;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      // right: 45rem;
      width: 80%;
      padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

      & > * {
        color: white;

      }
    }
  }


  @media (max-width: $mediaXS) {
    &-container {
      // margin: 0;
      margin: 9rem 0 0 0 !important;
      margin-bottom: 5rem;
      border-radius: 0 !important;
      overflow: unset;

      &.is-offer {
        margin-bottom: 20rem;

        & .banner-info-flex-text p.desc {
          // opacity: 0;
          // height: 13rem;
        }

        & .banner-overlay {
          background: linear-gradient(to bottom, #090d0b 0%, rgba(26, 28, 27, 0.76) 90.45%);
        }
      }

    }

    &-info {
      &-flex {
        // margin-top: 10rem;
        margin-right: 1rem;

        &-subtitle {
          z-index: 1;
          margin-bottom: 1rem;
  
        }
      }

      &-img {
        position: absolute;
        top: 25rem;
        left: 50%;
        transform: translateX(-50%);
        height: unset;
        width:55%;

        & img {
          @apply border-radius overflow-hidden;
          object-fit: cover;
          max-height: 30rem;
        }
      }
    }
  }
}

.is-offer{
&.banner {
 
  box-shadow: -5px 2px 30px 0 rgba(0, 0, 0, 0.1);

&-container  {
  height: auto;
  padding: 0rem 0rem 0rem 0rem;
  max-width: -webkit-fill-available !important;
  @apply container ;

  .banner-overlay {
    position: absolute;
    inset: 0;
    height: 100%;
    background: transparent;
    z-index: 1;
    display: none;
  }

  .banner-bg{
    display: none;
  }

.banner-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    height:auto;
    position: relative;
    // max-height: 100vh;
    align-items: flex-start;
    color :black !important;

    @media (max-width: $mediaXS) {
      flex-direction: column;

    }
    .banner-info-site{
      width: 50%;
      height: auto;
     
      overflow-y: hidden;
      @media (max-width: $mediaXS) {
        position: unset;
        width: 100%;
        max-width: 100%;
        height: auto;
        transform: none;

      }

    }

    .banner-info-img{

      width: 100%;
      height: auto;
      max-height: 50vh;
      overflow-y: hidden;
      @media (max-width: $mediaXS) {
        position: unset;
        width: 100%;
        max-width: 100%;
        height: auto;
        transform: none;

      }
      & img {
           object-fit: cover;
        width: 100%;
        height:auto;
         max-height: 100%;
        -webkit-transition: 1s ease;
        -moz-transition: 1s ease;
        -o-transition: 1s ease;
        transition: 1s ease;
        &:hover {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
      }
      }
    }
    .banner-info-flex{
  
      padding: 1rem 2rem;
      padding-inline-end:10rem;
      height: auto;
      width:50% !important;
      max-height: 100vh;
    overflow-y: auto;
      margin: 0rem;
      @media (max-width: $mediaXS) {
        width: 100% !important;
        padding-inline-end: 2rem;
      }


     & .offer-button {
      @media (max-width: $mediaXS) {
        position: fixed;
        z-index: 100;
        bottom: 0rem;
        margin-right:-2rem;
        gap: 0;

      
      }
      & .nice-style-btn {
        @media (max-width: $mediaXS) {
        border-radius: 0rem;
      }
      }

      }
  
      &-text {
          width: 100% ;
        & * {
          color: black;
        }
      }
     &-subtitle{
      & * {
        color: black;
      }
      margin: 0;
      padding: 0;
      &:after {
        content: "";
        position: absolute;
        width: 50px;
        height: 3px;
        background: var(--primary);
        top: -21px;
        position: static;
        display: block;
        margin-bottom: 1rem;
    }

      }

      .banner-date *{

        color: var(--primary) !important;
      }



      &::-webkit-scrollbar {
        width: 0rem !important;
      }
      
      &::-webkit-scrollbar-track {
        box-shadow: none  !important;
        background-color: transparent !important;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: transparent !important;
        border-radius: 1rem  !important;
        outline: 0  !important;
      }


    }
  
}

}
}
}