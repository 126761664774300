$gap: 6rem;
$imageMaxWidth: 51.5rem;

.info {

  &-box {
    &-circle {
      @apply border-radius-full bg-primary;
      aspect-ratio: 1/1;
      padding: .8rem;
    }

    &-img {
      @apply border-radius-full;
      padding: 1.5rem;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: .5rem solid white;


      & img {
        filter: brightness(0) invert(1);
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      @apply text-center text-20 font-bold mt-8 ;
    }
  }

  @media (max-width: $mediaXS) {
    &-box {
      &-circle {
        &-title {
          font-size: 2.2rem;
        }
      }
    }
  }
}